import { Link } from "@mui/material";
import React from "react";

export default function Products() {
  return (
    <div className="spacer investor_wrapper">
      <div className="container">
        <div className="row">
          <div className="col-xxl-7 col-xl-7 col-lg-7 feature-text">
            <h3>
              Premium and exclusive
              <span className="highlighting"> products</span>
              <br /> for you.
            </h3>
          </div>
          <div className="col-xxl-4 col-xl-4 col-lg-5">
            <h4>Fresh new features, even more power.</h4>
            <p>
              BITHAVEN Exchange offers a range of premium products tailored to
              meet the needs of experienced traders and investors. These
              products may include advanced trading tools, exclusive market
              insights, priority customer support, and personalized investment
              strategies.
            </p>
          </div>
        </div>

        <div className="row mt-5">
          <div class="col-12 col-md-12 col-lg-3 mb-3">
            <div class="pro-col-info">
              <div class="pro_img">
                <img src="/img/robot.png" alt="" width="48" height="48" />
              </div>
              <h5 className="pro_heading">Robo Strategies</h5>
              <p>
                Subscribe to algo trading strategies and put your crypto to work
              </p>
              <a href="#" className="prod_link">
                Know More
              </a>
            </div>
          </div>
          <div class="col-12 col-md-12 col-lg-3 mb-3">
            <div class="pro-col-info">
              <div class="pro_img">
                <img src="/img/stake.png" alt="" width="48" height="48" />
              </div>
              <h5 className="pro_heading">Staking</h5>
              <p>Grow your Crypo. Earn upto 10% APY.</p>
              <Link to="/StakingPlans" className="prod_link">
                Know More
              </Link>
            </div>
          </div>
          <div class="col-12 col-md-12 col-lg-3 mb-3">
            <div class="pro-col-info">
              <div class="pro_img">
                <img src="/img/move.png" alt="" width="48" height="48" />
              </div>
              <h5 className="pro_heading">MOVE</h5>
              <p>
                Derivatives to speculate on volatility of BTC and top altcoins
              </p>
              <a href="/" className="prod_link">
                Know More
              </a>
            </div>
          </div>
          <div class="col-12 col-md-12 col-lg-3 mb-3">
            <div class="pro-col-info">
              <div class="pro_img">
                <img src="/img/blind-spot.png" alt="" width="48" height="48" />
              </div>
              <h5 className="pro_heading">Spot</h5>
              <p>Buy & sell top crypto assets at the best prices</p>
              <Link className="prod_link" to="/exchange/trx-inr"> 
                Know More
              </Link>
            </div>
          </div>
        </div>
        <div className="mx-auto justify-content-center mt-5">
          <div class="row justify-content-center">
            <div class="col-12 col-md-6 col-lg-3 mb-3">
              <div class="pro-col-info">
                <div class="pro_img">
                  <img src="/img/choices.png" alt="" width="48" height="48" />
                </div>
                <h5 className="pro_heading">Options</h5>
                <p>
                  Call & put options on BTC and leading alts like ETH, SOL and
                  BNB
                </p>
                <a href="#" className="prod_link">
                  Know More
                </a>
              </div>
            </div>
            <div class="col-12 col-md-12 col-lg-3 mb-3">
              <div class="pro-col-info">
                <div class="pro_img">
                  <img src="/img/commodity.png" alt="" width="48" height="48" />
                </div>
                <h5 className="pro_heading">Futures</h5>
                <p>
                  Fixed expiry and perpectual contracts on BTC & 50+ altcoins
                </p>
                <a href="#" className="prod_link">
                  Know More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
