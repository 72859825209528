import React from "react";
import { useSelector } from "react-redux";
import AOS from "aos";
import "aos/dist/aos.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Banner(props) {
  var settings = {
    dots: false,
    infinite: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    // fade: true,
    // lazyLoad: true,
    autoPlay: true,
    autoplaySpeed: 2000,
    cssEase: "linear"
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const { isLoggedIn } = useSelector((state) => state.AuthReducer);
  AOS.init({ duration: 1000 });
  const { coins } = useSelector((state) => state.coinDBReducer);
  let coins1 = Object.values(coins);

  const banner_child =
    coins1 &&
    coins1.map((item, index) => {
      let usdtprize = coins1 && coins1.find((item) => item.symbol == "USDT");
      return (
        <>
          {item.symbol == "BTC" ||
          item.symbol == "BNB" ||
          item.symbol == "ETH" ||
          item.symbol == "TRX" ? (
            <div className="col-lg-3" key={index}>
              <div className="card banner_child">
                <div className="card-body">
                  <p className="">
                    {item.symbol}/USDT&nbsp;&nbsp;
                    <span
                      className={
                        item.direction == "up" ? "text-green" : "text-red"
                      }
                    >
                      {item.price_change_percentage_1h}%
                    </span>
                  </p>
                  <h5>
                    $
                    {(
                      item.current_price /
                      usdtprize.current_price
                    ).toFixed(8)}
                  </h5>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      );
    });
  return (
    <>
      <div className="page-content">
        <div className="home_page_banner">
          <div className="container">
            <div className="row align-items-center mt-5 mt-5">
              <div className="col-12 col-lg-8 mb-3">
                <div className="banner_left_inner">
                  <h2 className="bannertxt">
                    Buy & sell Crypto on{" "}
                    <span className="text-pink">BITHAVEN</span>
                  </h2>
                  <h3 className="mb-4">
                    {" "}
                    The Highly Efficient exchange for stable and secure Trade.
                  </h3>
                  <p>
                    Trade Bitcoin, Ethereum, BAT and 150+ cryptos in seconds, on
                    our simple and ultra-secure crypto exchange
                  </p>

                  <div className="mt-4">
                    {" "}
                    {!isLoggedIn ? (
                      <>
                        <a
                          className="btn login_btn px-4 rounded-pill"
                          to="/create"
                        >
                          Sign Up Now
                        </a>
                      </>
                    ) : (
                      <a
                        className="btn btn login_btn px-4 rounded-pill"
                        href="/exchange/rbc-inr"
                      >
                        Trade Now
                      </a>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4" data-aos="fade-up">
                <img className="bannerImg" src="../img/banner.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="latest_news_wrapper">
          <div className="row">
            <div className="col-lg-2 p-0">
              <div className="latest_news">
                <h2 className="text-white"> Latest News </h2>
              </div>
            </div>
            <div className="col-lg-10 p-0 bg-light">
              <div className="left_description">
                <Slider {...settings}>
                  <div>
                    <h5>Create account at BITHAVEN</h5>
                    <p>
                      Top wallet & multi-wallet management, social key recovery,
                      and estate planning. No KYC.{" "}
                      <a href="anchor_link">Apply Now</a>
                    </p>
                  </div>
                  <div>
                    <h5>New Project launch</h5>
                    <p>
                      Things you changed that fix bugs. If a fixes a bug, but in
                      so doing adds a new requirement, removes code, or requires
                    </p>
                  </div>
                  <div>
                    <h5>Buy Crypto at BITHAVEN</h5>
                    <p>
                      Things you changed that fix bugs. If a fixes a bug, but in
                      so doing adds a new requirement, removes code, or requires
                    </p>
                  </div>
                  <div>
                    <h5>Sell Crypto at BITHAVEN</h5>
                    <p>
                      Things you changed that fix bugs. If a fixes a bug, but in
                      so doing adds a new requirement, removes code, or requires
                    </p>
                  </div>
                  <div>
                    <h5>Enjoy Trading at BITHAVEN</h5>
                    <p>
                      Things you changed that fix bugs. If a fixes a bug, but in
                      so doing adds a new requirement, removes code, or requires
                    </p>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="currency_section">
          <Carousel
            swipeable={false}
            draggable={false}
            showDots={false}
            responsive={responsive}
            pauseOnHover
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={true}
            // autoPlay={this.props.deviceType !== "mobile" ? true : false}
            autoPlaySpeed={3500}
            keyBoardControl={true}
            transitionDuration={500}
            containerClass="carousel-container"
            //removeArrowOnDeviceType={["tablet", "mobile"]}
            // deviceType={this.props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
            <div>
              <img width="100%" src="/img/portfolio-transactions1.png" />
            </div>
            <div>
              <img width="100%" src="/img/portfolio-transactions2.png" />
            </div>
            <div>
              <img width="100%" src="/img/portfolio-transactions1.png" />
            </div>
            <div>
              <img width="100%" src="/img/portfolio-transactions2.png" />
            </div>
            <div>
              <img width="100%" src="/img/portfolio-transactions1.png" />
            </div>
            <div>
              <img width="100%" src="/img/portfolio-transactions2.png" />
            </div>
            <div>
              <img width="100%" src="/img/portfolio-transactions1.png" />
            </div>
            <div>
              <img width="100%" src="/img/portfolio-transactions2.png" />
            </div>
          </Carousel>
        </div>
      </div>

      <div class="modal fade" tabindex="-1" id="paymentmodal">
        <div class="modal-dialog  modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Select Payment Method</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <p>Debit and Credit Cards</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
