import React, { useEffect, useState } from "react";
import Header from "./HomeComp/Header";
import ProfileSidebar from "./components/AssetSidebar";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import { TfiBrushAlt } from "react-icons/tfi";
import { IoSettingsOutline } from "react-icons/io5";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { TbAward, TbChecklist, TbListCheck } from "react-icons/tb";
import { HiBarsArrowDown } from "react-icons/hi2";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { MdBorderAll, MdOutlineMailOutline } from "react-icons/md";
import { AiOutlineLineChart } from "react-icons/ai";
import { PiNewspaperClippingLight } from "react-icons/pi";
import { BiSolidBadge } from "react-icons/bi";
import { FaLongArrowAltRight } from "react-icons/fa";
import { N_UserNotification } from "./redux/helpers/api_functions_new";
import { useSelector } from "react-redux";

export default function AllNotification() {

  const { user } = useSelector((state) => state.AuthReducer);
  const [all, setAll] = useState(null);
  const [system, setSystem] = useState(null);
  const [announcement, setAnnouncement] = useState(null);
  const [reward, setReward] = useState(null);
  const [news, setNews] = useState(null);
  const [activeTab, setActiveTab] = useState(1);
  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };
  useEffect(()=>{
    N_UserNotification(user?.params ? user.params.token : user.token)
    .then((response)=>{
      if(response.status == 200) {
        const result = response.data;

        // Filter notifications based on type and set state accordingly
        const sys = result.filter((item) => item.type === "system");
        const annou = result.filter((item) => item.type === "announcement");
        const rew = result.filter((item) => item.type === "reward");
        const nes = result.filter((item) => item.type === "news");

        // Update state with filtered notifications
        setAll(result);
        setSystem(sys);
        setAnnouncement(annou);
        setReward(rew);
        setNews(nes);
      }
    })
  }, []);

  return (
    <>
      <Header />

      <div className="s-layout">
        <div class="s-layout__sidebar">
          <a class="s-sidebar__trigger" href="#0">
            <span>
              <HiBarsArrowDown className="fs-2 text-pink" /> All Menu
            </span>
          </a>

          <nav class="s-sidebar__nav">
            <ul>
              <li>
                <a
                  className={`s-sidebar__nav-link ${
                    activeTab === 1 ? "active" : ""
                  }`}
                  onClick={() => handleTabClick(1)}
                >
                  <MdBorderAll className="fs-4" />
                  <span> All Notifications</span>
                </a>
              </li>

              <li className="border-bottom my-3"></li>

              <li>
                <a
                  className={`s-sidebar__nav-link ${
                    activeTab === 2 ? "active" : ""
                  }`}
                  onClick={() => handleTabClick(2)}
                >
                  <MdOutlineMailOutline className="fs-4" />
                  <span className=""> System Notification</span>
                </a>
              </li>

              <li>
                <a
                  className={`s-sidebar__nav-link ${
                    activeTab === 3 ? "active" : ""
                  }`}
                  onClick={() => handleTabClick(3)}
                >
                  <HiOutlineSpeakerphone className="fs-4" />
                  <span className=""> Announcement</span>
                </a>
              </li>

              <li>
                <a
                  className={`s-sidebar__nav-link ${
                    activeTab === 4 ? "active" : ""
                  }`}
                  onClick={() => handleTabClick(4)}
                >
                  <TbAward className="fs-4" />
                  <span className=""> Rewards</span>
                </a>
              </li>

              <li>
                <a
                  className={`s-sidebar__nav-link ${
                    activeTab === 5 ? "active" : ""
                  }`}
                  onClick={() => handleTabClick(5)}
                >
                  <AiOutlineLineChart className="fs-4" />
                  <span className=""> News</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>

        <main className="s-layout__content">
          <div className="dashboard_header">
            <h3 className="header_title"> Notifications Center</h3>
            {/* <div className="flex_row gap-3">
              <div>
                <Link
                  to="/"
                  className="d-flex gap-2 align-items-center text-lgray"
                >
                  <div>
                    <TbListCheck className="fs-5" />
                  </div>
                  <div> Mark All as Read</div>
                </Link>
              </div>

              <div>
                <Link
                  to="/"
                  className="d-flex gap-2 align-items-center text-lgray"
                >
                  <div>
                    <IoSettingsOutline className="fs-5" />
                  </div>
                  <div> Message Setting</div>
                </Link>
              </div>
            </div> */}
          </div>
          <div className="account_page container">
            <div className="col-lg-12 col-md-12 col-sm-12 mt-3 ">
              <div className="border-bottom-0">
                <div class="row">
                  <div class="notification-wrapper">
                    {activeTab === 1 && (
                      <div>
                       {all?.length > 0 ? (
                            all.map((item, index) => (
                              <div key={index} className="notice-items">
                                <div className="d-flex gap-2 align-items-center">
                                  <div className="circle_backdrop text-center">
                                    <HiOutlineSpeakerphone />
                                  </div>
                                  <div>
                                    <h5>{item?.title}</h5>
                                  </div>
                                </div>

                                <div className="flex_row">
                                  <div className="w-75">
                                    <p className="text-lgray my-4">{item?.message}</p>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className="notice-items">
                              <p>No data found.</p>
                            </div>
                          )}

                       

                        {/* <div class="notice-items">
                          <div class="d-flex gap-2 align-items-center">
                            <div class="circle_backdrop text-center">
                              <HiOutlineSpeakerphone />
                            </div>
                            <div>
                              <h5> BITHAVEN Exchange is now Live !</h5>
                            </div>
                          </div>

                          <div className="flex_row">
                            <div className="w-75">
                              <p className="text-lgray my-4">                               
                                You have completed the task -Welcome to bithaven
                                exchange Lorem ipsum dolor sit amet consectetur
                                adipisicing elit. Sed labore quae perspiciatis
                                nam error? Totam officia necessitatibus
                                doloremque, explicabo vitae ut veniam eum
                                consequuntur voluptatum laboriosam veritatis, ex
                                ab numquam.
                              </p>
                            </div>
                            <div>
                              <div class="notice-item-img">
                                <img
                                  src=""
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                          <div className="text-muted mt-4">03-15 12:41</div>
                        </div> */}
                      </div>
                    )}
                    {activeTab === 2 && (
                     <div>
                     {system?.length > 0 ? (
                          system.map((item, index) => (
                            <div key={index} className="notice-items">
                              <div className="d-flex gap-2 align-items-center">
                                <div className="circle_backdrop text-center">
                                  <HiOutlineSpeakerphone />
                                </div>
                                <div>
                                  <h5>{item?.title}</h5>
                                </div>
                              </div>

                              <div className="flex_row">
                                <div className="w-75">
                                  <p className="text-lgray my-4">{item?.message}</p>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="notice-items">
                            <p>No data found.</p>
                          </div>
                        )}

                     

                      {/* <div class="notice-items">
                        <div class="d-flex gap-2 align-items-center">
                          <div class="circle_backdrop text-center">
                            <HiOutlineSpeakerphone />
                          </div>
                          <div>
                            <h5> BITHAVEN Exchange is now Live !</h5>
                          </div>
                        </div>

                        <div className="flex_row">
                          <div className="w-75">
                            <p className="text-lgray my-4">                               
                              You have completed the task -Welcome to bithaven
                              exchange Lorem ipsum dolor sit amet consectetur
                              adipisicing elit. Sed labore quae perspiciatis
                              nam error? Totam officia necessitatibus
                              doloremque, explicabo vitae ut veniam eum
                              consequuntur voluptatum laboriosam veritatis, ex
                              ab numquam.
                            </p>
                          </div>
                          <div>
                            <div class="notice-item-img">
                              <img
                                src=""
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                        <div className="text-muted mt-4">03-15 12:41</div>
                      </div> */}
                    </div>
                    )}
                    {activeTab === 3 && (
                    <div>
                    {announcement?.length > 0 ? (
                         announcement.map((item, index) => (
                           <div key={index} className="notice-items">
                             <div className="d-flex gap-2 align-items-center">
                               <div className="circle_backdrop text-center">
                                 <HiOutlineSpeakerphone />
                               </div>
                               <div>
                                 <h5>{item?.title}</h5>
                               </div>
                             </div>

                             <div className="flex_row">
                               <div className="w-75">
                                 <p className="text-lgray my-4">{item?.message}</p>
                               </div>
                             </div>
                           </div>
                         ))
                       ) : (
                         <div className="notice-items">
                           <p>No data found.</p>
                         </div>
                       )}

                    

                     {/* <div class="notice-items">
                       <div class="d-flex gap-2 align-items-center">
                         <div class="circle_backdrop text-center">
                           <HiOutlineSpeakerphone />
                         </div>
                         <div>
                           <h5> BITHAVEN Exchange is now Live !</h5>
                         </div>
                       </div>

                       <div className="flex_row">
                         <div className="w-75">
                           <p className="text-lgray my-4">                               
                             You have completed the task -Welcome to bithaven
                             exchange Lorem ipsum dolor sit amet consectetur
                             adipisicing elit. Sed labore quae perspiciatis
                             nam error? Totam officia necessitatibus
                             doloremque, explicabo vitae ut veniam eum
                             consequuntur voluptatum laboriosam veritatis, ex
                             ab numquam.
                           </p>
                         </div>
                         <div>
                           <div class="notice-item-img">
                             <img
                               src=""
                               alt=""
                             />
                           </div>
                         </div>
                       </div>
                       <div className="text-muted mt-4">03-15 12:41</div>
                     </div> */}
                   </div>
                    )}
                    {activeTab === 4 && (
                       <div>
                       {reward?.length > 0 ? (
                            reward.map((item, index) => (
                              <div key={index} className="notice-items">
                                <div className="d-flex gap-2 align-items-center">
                                  <div className="circle_backdrop text-center">
                                    <HiOutlineSpeakerphone />
                                  </div>
                                  <div>
                                    <h5>{item?.title}</h5>
                                  </div>
                                </div>

                                <div className="flex_row">
                                  <div className="w-75">
                                    <p className="text-lgray my-4">{item?.message}</p>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className="notice-items">
                              <p>No data found.</p>
                            </div>
                          )}

                       

                        {/* <div class="notice-items">
                          <div class="d-flex gap-2 align-items-center">
                            <div class="circle_backdrop text-center">
                              <HiOutlineSpeakerphone />
                            </div>
                            <div>
                              <h5> BITHAVEN Exchange is now Live !</h5>
                            </div>
                          </div>

                          <div className="flex_row">
                            <div className="w-75">
                              <p className="text-lgray my-4">                               
                                You have completed the task -Welcome to bithaven
                                exchange Lorem ipsum dolor sit amet consectetur
                                adipisicing elit. Sed labore quae perspiciatis
                                nam error? Totam officia necessitatibus
                                doloremque, explicabo vitae ut veniam eum
                                consequuntur voluptatum laboriosam veritatis, ex
                                ab numquam.
                              </p>
                            </div>
                            <div>
                              <div class="notice-item-img">
                                <img
                                  src=""
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                          <div className="text-muted mt-4">03-15 12:41</div>
                        </div> */}
                      </div>
                    )}
                    {activeTab === 5 && (
                       <div>
                       {news?.length > 0 ? (
                            news.map((item, index) => (
                              <div key={index} className="notice-items">
                                <div className="d-flex gap-2 align-items-center">
                                  <div className="circle_backdrop text-center">
                                    <HiOutlineSpeakerphone />
                                  </div>
                                  <div>
                                    <h5>{item?.title}</h5>
                                  </div>
                                </div>

                                <div className="flex_row">
                                  <div className="w-75">
                                    <p className="text-lgray my-4">{item?.message}</p>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className="notice-items">
                              <p>No data found.</p>
                            </div>
                          )}

                       

                        {/* <div class="notice-items">
                          <div class="d-flex gap-2 align-items-center">
                            <div class="circle_backdrop text-center">
                              <HiOutlineSpeakerphone />
                            </div>
                            <div>
                              <h5> BITHAVEN Exchange is now Live !</h5>
                            </div>
                          </div>

                          <div className="flex_row">
                            <div className="w-75">
                              <p className="text-lgray my-4">                               
                                You have completed the task -Welcome to bithaven
                                exchange Lorem ipsum dolor sit amet consectetur
                                adipisicing elit. Sed labore quae perspiciatis
                                nam error? Totam officia necessitatibus
                                doloremque, explicabo vitae ut veniam eum
                                consequuntur voluptatum laboriosam veritatis, ex
                                ab numquam.
                              </p>
                            </div>
                            <div>
                              <div class="notice-item-img">
                                <img
                                  src=""
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                          <div className="text-muted mt-4">03-15 12:41</div>
                        </div> */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
