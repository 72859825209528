import React, { useEffect, useRef, useState } from "react";
import Header from "./HomeComp/Header";
import ProfileSidebar from "./components/ProfileSidebar";

import {
  N_changePassword,
  N_getProfile,
  N_updateName,
  N_verifyPassword,
} from "./redux/helpers/api_functions_new";
import {
  N_freezeAccount,
  N_getUserProfile,
  N_resetPassword,
  N_sendOTPMobile,
  N_sendUpdateEmail,
  N_setGoogleAuth,
  N_setGoogleAuthOTP,
  N_verifyOTPMobile,
  N_verifyUpdateEmail,
} from "./redux/helpers/api_functions_new";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./components/Loader";
import { Link } from "react-router-dom";
import {
  AiFillInfoCircle,
  AiOutlineEdit,
  AiOutlineUserDelete,
} from "react-icons/ai";
import {
  BsEye,
  BsEyeSlash,
  BsSend,
  BsSendArrowUp,
  BsShieldCheck,
} from "react-icons/bs";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";
import { FBTCins, FaSortDown, FaSortUp, FaKey } from "react-icons/fa";
import { Sparklines, SparklinesLine } from "react-sparklines";
import Footer from "./HomeComp/Footer";
import { NotificationManager } from "react-notifications";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { user_logout } from "./redux/actions/authActions";
import {
  MdDisabledVisible,
  MdOutlineMail,
  MdPhoneIphone,
} from "react-icons/md";
import { FcGoogle, FcIphone } from "react-icons/fc";
import { LuKey, LuSendHorizonal } from "react-icons/lu";
import { RiDeleteBinLine } from "react-icons/ri";
import { PiWarningBold } from "react-icons/pi";

import OTPInput from "react-otp-input";
import {
  checkEmail,
  checkName,
  checkPassword,
} from "./redux/helpers/helper_functions";
import { GoClock, GoCopy } from "react-icons/go";
import { BiCopy } from "react-icons/bi";
import {
  isEmail,
  isEmailInput,
  isPassu,
  isCpass,
  isPhone,
} from "./redux/helpers/form-validator.functions";
import { RxEyeNone, RxEyeOpen } from "react-icons/rx";
var QRCode = require("qrcode");
export default function AccountSecurity(props) {
  const [eye, seteye] = useState(false);
  const [eye2, seteye2] = useState(false);
  const hsPassword = (_id) => {
    var _idv = document.querySelector("#" + _id);
    if (_idv.getAttribute("type") == "text") {
      _idv.setAttribute("type", "password");
      seteye(false);
    } else {
      _idv.setAttribute("type", "text");
      seteye(true);
    }
  };
  const hsPassword1 = (_id) => {
    var _idv = document.querySelector("#" + _id);
    if (_idv.getAttribute("type") == "text") {
      _idv.setAttribute("type", "password");
      seteye2(false);
    } else {
      _idv.setAttribute("type", "text");
      seteye2(true);
    }
  };

  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [otp, setOtp] = useState();
  const [notp, setNOtp] = useState();
  const [isSendingOTP, setIsSendingOTP] = useState(false);
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [isNOTPSent, setIsNOTPSent] = useState(false);
  const [isMOTPSent, setIsMOTPSent] = useState(false);
  const [motp, setMOtp] = useState();
  const [url, setUrl] = useState();
  const [gotp, setGotp] = useState();
  const [userSecret, setUserSecret] = useState();
  const [ispasswordOtp, setIsPasswordOtp] = useState(false);
  const [passotp, setPassOtp] = useState();

  const [isOTPVerified, setIsOTPVerified] = useState(true);
  const [isGOTPverified, setIsGOTPVerified] = useState();
  const { user, profile } = useSelector((state) => state.AuthReducer);

  const [hide, setHide] = useState(false);
  const [nodisplay, setDisplay] = useState(false);
  const [name, setName] = useState("");
  const [total_inr, setTotalInr] = useState(0);
  const [total_usdt, setTotalUsdt] = useState(0);
  const inputRef = useRef(null);

  const [loading, setLoading] = React.useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [errorMessagephone, setErrorMessagephone] = useState("");
  const [successMessagephone, setSuccessMessagephone] = useState("");

  const [errorMessageprofile, seterrorMessageprofile] = useState("");
  const [successMessageprofile, setSuccessMessageprofile] = useState("");

  const [errorMessagepassword, setErrorMessagePassword] = useState("");
  const [successMessagepassword, setSuccessMessagePassword] = useState("");

  const [emailShow, setEmailshow] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(null);

  const toggleCollapse = (collapseId) => {
    setOpenCollapse(openCollapse === collapseId ? null : collapseId);
  };

  const [errorMessagegotp, setErrorMessagegotp] = useState("");
  const [successMessaeggotp, setSuccessMessagegotp] = useState("");

  const [phoneError, setPhoneError] = useState("");
  // Update the onChange handler for the phone input
  const handlePhoneChange = (e) => {
    const value = e.target.value;
    // Check if the entered value is numeric and has a length of 10 or less
    if (/^\d{0,10}$/.test(value)) {
      setPhone(value);
      setPhoneError(""); // Clear any previous error
    } else {
      setPhoneError("Phone number should be numeric and not exceed 10 digits");
    }
  };

  const handlePageClick = (e) => {
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      if (name !== profile?.name) {
        updateProfile();
      } else {
        setDisplay(false);
      }
    }
  };
  const updateProfile = (e) => {
    //   e.preventDefault();
    if (checkName(name)) {
      N_updateName(user?.params ? user.params.token : user.token, name).then(
        (res) => {
          if (res.status.matchedCount == 1) {
            setName("");
            setDisplay(!nodisplay);
            N_getProfile(user?.params ? user.params.token : user.token)
              .then((d) => {
                if (d.status === 200) {
                  dispatch({ type: "PROFILE", data: d?.params.profile_info });
                }
              })
              .catch((e) => {
                console.log(e);
              });
            // NotificationManager.success("Updated Successfully");
            setSuccessMessageprofile("Nick name is updated successfully!");
            setTimeout(() => {
              setSuccessMessageprofile("");
            }, 4000); // 2 seconds
          } else {
            setErrorMessage(res.message);
          }
        }
      );
    } else {
      // NotificationManager.error("Enter valid nick Name");
      seterrorMessageprofile(
        "Please enter a valid nickname. Special characters & numbers are not allowed."
      );
      setTimeout(() => {
        seterrorMessageprofile("");
      }, 4000); // 2 seconds
    }
  };

  // const [profile, setProfile] = React.useState({});
  // useEffect(() => {
  //   N_getUserProfile(user?.params ? user.params.token : user.token)
  //     .then((d) => {
  //       // console.log("userProfile",d?.params.profile_info);
  //       if (d.status === 200) {
  //         setProfile(d?.params.profile_info);
  //       }
  //       setLoading(false);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // }, []);
  return (
    <>
      <Header {...props} />

      <div className="s-layout">
        <div class="s-layout__sidebar">
          <ProfileSidebar />
        </div>

        <main className="s-layout__content">
          <div className="dashboard_header">
            <h3 className="header_title">Security Settings</h3>
            {/* <div>
              <span className="custom_badge bg-soft-success rounded-pill">
                <BsShieldCheck className="me-1" />
                Security:{" "}
                {profile?.authenticator == 2
                  ? "High"
                  : profile?.authenticator == 1
                  ? "Medium"
                  : "Low"}
              </span>
            </div> */}
          </div>
          <div className="container-fluid">
            <div className="account_page" id="account_security">
              <div className="jambotron-outline">
                <div className="horizontal_display_with_justify">
                  <div className="flex_row">
                    <div className="name_circle">
                      {nodisplay?.name
                        ? profile?.name
                            ?.split("")
                            .slice(0, 2)
                            .join("")
                            .toUpperCase()
                        : profile?.email
                            ?.split("")
                            .slice(0, 2)
                            .join("")
                            .toUpperCase()}
                    </div>{" "}
                    {nodisplay ? (
                      <>
                        <div>
                          <input
                            ref={inputRef}
                            className="editable-input-box form-control"
                            type="text"
                            value={name}
                            onChange={(e) => {
                              setName(e.target.value.replace("/a-zA-z*/i", ""));
                            }}
                            onBlur={() => {
                              if (name !== profile?.name) {
                                updateProfile();
                              } else {
                                setDisplay(false);
                              }
                            }}
                            autoFocus
                          />
                        </div>
                      </>
                    ) : (
                      <div className="big_heading me-2 text-truncate mob_mw_150">
                        {hide
                          ? "**********"
                          : profile?.name
                          ? profile.name
                          : profile?.email}{" "}
                      </div>
                    )}
                    <div>
                      <span
                        className="cursor_pointer"
                        onClick={() => {
                          setDisplay(!nodisplay);
                          setName(profile?.name);
                        }}
                      >
                        {!nodisplay ? (
                          <>
                            <AiOutlineEdit className="fs-3 ms-2" />
                          </>
                        ) : null}
                      </span>
                    </div>
                    <div className="mx-3">
                      {errorMessageprofile && (
                        <p className="mb-0 fs-14 text-danger" id="msg">
                          {errorMessageprofile}
                        </p>
                      )}
                      {successMessageprofile && (
                        <p className="mb-0 fs-14 text-success" id="msg">
                          {successMessageprofile}
                        </p>
                      )}
                    </div>
                  </div>

                  <div>
                    <a
                      className="btn btn_light rounded-pill"
                      onClick={() => {
                        setHide(!hide);
                      }}
                    >
                      {!hide ? (
                        <>
                          <BsEyeSlash className="fs-5 me-2" />
                        </>
                      ) : (
                        <>
                          <BsEye className="fs-5 me-2" />
                        </>
                      )}
                    </a>
                  </div>
                </div>

                <div className="row align-items-center">
                  <div className="col-lg-9 col-xl-7">
                    <div className="row mt-5">
                      {/* <div className="col-lg-3">
                        <small className="text-lgray text-nowrap">UID</small>
                        <p>
                          {hide
                            ? "***"
                            : user?.params
                            ? user.params.user_id
                            : user.user_id}{" "}
                          <i className="fa fa-copy" ms-2></i>
                        </p>
                      </div> */}

                      {/* <div className="col-lg-3">
                        <div className="top-title text-nowrap">
                          Identity Verification
                        </div>
                        <h4>
                          <span className="sub_heading">
                            {hide ? "***" : "LV1"}
                          </span>
                        </h4>
                      </div> */}

                      <div className="col-lg-4">
                        <small className="top-title  text-nowrap">UID:</small>
                        <div className="d-flex align-items-center gap-2">
                          <p className="hero_heading text-truncate">
                            XXXXXXXXXXXXXXX{" "}
                          </p>
                          <p>
                            {" "}
                            <GoCopy className="text-lgray fs-14 ms-1" />
                          </p>
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <small className="top-title text-nowrap">
                          {" "}
                          Last Login Time:
                        </small>
                        <p className="hero_heading">
                          {hide
                            ? "***"
                            : new Date(profile.last_login).toString()}
                          <GoClock className="text-lgray fs-14 ms-2" />
                        </p>
                      </div>

                      <div className="col-lg-4">
                        <small className="top-title text-nowrap">
                          Security Level:
                        </small>
                        <p className="hero_heading">
                          {/* <BsShieldCheck className="text-primary me-1" /> */}
                          {hide ? (
                            "***"
                          ) : profile.authenticator == 2 ? (
                            <span className="text-success">High</span>
                          ) : profile.authenticator == 1 ? (
                            <span className="text-warning">Medium</span>
                          ) : (
                            <span className="text-red">Low</span>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  {profile.authenticator == 0? (
                    <div className="col-xl-5 col-lg-3">
                      <div className="red-jambotron mb-4">
                        <div className="d-flex justify-content-between">
                          <div>
                            <h5 className="fs-16">
                              Your account security level is low.
                            </h5>
                            <div className="text-lgray">
                              Please set up the following as soon as possible.
                            </div>
                            <div className="mt-3 fs-14">
                              <a
                                href="#scroll_to_google"
                                className="text-danger"
                              >
                                <AiFillInfoCircle className="fs-5" /> Google 2FA
                                Authentication
                                <i className="fa-solid fa-arrow-right-long ms-1"></i>
                              </a>
                            </div>
                          </div>
                          <div>
                            <img
                              className="img-fluid"
                              src="/img/authentication.png"
                              width="80"
                              alt="Google 2FA Authentication"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              {/*----------Two-Factor Authentication (2FA)  column---------- */}
              <div className="col-lg-12 mb-5">
                <div className="account_page_subheading mb-2">
                  Two-Factor Authentication (2FA)
                </div>
                <div className="text-lgray fs-12">
                  Choose [Google Verification + Email ] or [Mobile Phone ] to
                  ensure the safety of your assets.
                </div>
                <div className="row mt-4">
                  {/* -----Change Email id section starts -----------*/}
                  <section>
                    <div className="tablelike_box">
                      <div className="d-flex gap-1">
                        <div className="circle_backdrop">
                          <MdOutlineMail className="fs-4 text-pink" />
                        </div>
                        <div>
                          <h5 className="mb-0 fs-16">Email</h5>
                          <span className="text-lgray">
                            Used to verify withdrawals, API creation, etc.
                          </span>
                        </div>
                      </div>

                      <div className="call_action">
                        {profile?.email ? (
                          <div className="call_action_left">
                            <span>
                              <i class="fa-solid fa-circle-check text-green me-1"></i>
                            </span>

                            {!emailShow ? (
                              <>
                                <span>
                                  {profile?.email
                                    ?.split("")
                                    .slice(0, 2)
                                    .join("") +
                                    "***@***" +
                                    profile?.email?.substr(
                                      profile?.email.indexOf(".")
                                    )}
                                </span>
                              </>
                            ) : (
                              <>
                                <span>{profile?.email}</span>
                              </>
                            )}

                            <a
                              className="btn"
                              onClick={() => {
                                setEmailshow(!emailShow);
                              }}
                            >
                              {!emailShow ? (
                                <>
                                  <BsEyeSlash className="fs-14 me-2" />
                                </>
                              ) : (
                                <>
                                  <BsEye className="fs-14 me-2" />
                                </>
                              )}
                            </a>
                          </div>
                        ) : (
                          <div className="call_action_right">
                            <span>
                              <i class="fa-solid fa-circle-exclamation me-1 text-warning"></i>
                            </span>
                          </div>
                        )}

                        <div>
                          <a
                            className="btn btn-outline-primary"
                            data-bs-toggle="collapse"
                            href="#email_change"
                            onClick={() => toggleCollapse("email_change")}
                          >
                            {profile?.email ? "Update" : "Add"}
                          </a>
                        </div>
                      </div>
                    </div>

                    <div
                      className={`collapse ${
                        openCollapse === "email_change" ? "show" : ""
                      }`}
                      id="email_change"
                    >
                      <div className="container">
                        <div className="row my-4">
                          <div className="col-lg-5 m-auto">
                            <div className="jambotron-- shadow-sm--">
                              <h4 className="sub_heading">
                                {" "}
                                {profile?.email
                                  ? "Update / Change"
                                  : "Add"}{" "}
                                Email
                              </h4>
                              <div className="error_div">
                                {errorMessage && (
                                  <h6 className="mb-0 fs-14 text-danger">
                                    {errorMessage}
                                  </h6>
                                )}

                                {/* Add a condition to check if the entered email is the same as the current email */}
                                {email === profile?.email && (
                                  <p className="text-danger fs-12">
                                    This email is already in use. Please enter a
                                    different email.
                                  </p>
                                )}

                                {successMessage && (
                                  <h6 className="fs-14 text-success">
                                    {successMessage}
                                  </h6>
                                )}
                              </div>

                              <div class="cstm_form">
                                {isOTPVerified ? (
                                  <>
                                    <div class="form-group mb-4">
                                      <label
                                        for="email id"
                                        class="text-muted fs-12"
                                      >
                                        New Email ID
                                      </label>
                                      <div class="input-group">
                                        <input
                                          type="email"
                                          class="form-control"
                                          value={email}
                                          id="user_email"
                                          name="user_email"
                                          disabled={isNOTPSent}
                                          onChange={(e) => {
                                            setEmail(
                                              isEmailInput(e.target.value)
                                            );
                                          }}
                                        />
                                        <span class="input-group-text">
                                          <div
                                            className="cursor_pointer"
                                            onClick={() => {
                                              if (isEmailInput(email)) {
                                                if (checkEmail(email)) {
                                                  N_sendUpdateEmail(
                                                    user?.params
                                                      ? user.params.token
                                                      : user.token,
                                                    email,
                                                    1
                                                  ).then((res) => {
                                                    if (res.status == 200) {
                                                      setIsNOTPSent(true);
                                                      setSuccessMessage(
                                                        res.message
                                                      );
                                                      setTimeout(() => {
                                                        setSuccessMessage("");
                                                      }, 2000); // 2 seconds
                                                      // NotificationManager.success(
                                                      //   res.message
                                                      // );
                                                    }
                                                  });
                                                } else {
                                                  setErrorMessage(
                                                    "Please enter new Email ID"
                                                  );
                                                  setTimeout(() => {
                                                    setErrorMessage("");
                                                  }, 2000); // 2 seconds
                                                  // NotificationManager.error(
                                                  //   "Enter new Email"
                                                  // );
                                                }
                                              }
                                            }}
                                          >
                                            {isNOTPSent ? (
                                              <>Resend Code</>
                                            ) : (
                                              <>
                                                <LuSendHorizonal className="fs-4 text-lgray" />
                                              </>
                                            )}
                                          </div>
                                        </span>
                                      
                                      </div>
                                      <small
                                          className="text-danger"
                                          id="passerr"
                                        ></small>
                                    </div>

                                    <div>
                                      <div>
                                        {isNOTPSent ? (
                                          <>
                                            <h5 className="hero_heading mb-4 text-center">
                                              Enter the 6- digit code we sent to{" "}
                                              <div className="text-lgray mt-1">
                                                {email}
                                              </div>{" "}
                                            </h5>

                                            <div className="d-flex justify-content-center">
                                              <OTPInput
                                                value={notp}
                                                onChange={(otp) => {
                                                  if (
                                                    /^\d+$/.test(otp) ||
                                                    otp === ""
                                                  ) {
                                                    setNOtp(otp);
                                                  }
                                                }}
                                                type="tel"
                                                pattern="[0-9]*"
                                                numInputs={6}
                                                renderSeparator={
                                                  <span
                                                    style={{
                                                      fontSize: "7px",
                                                      marginLeft: "8px",
                                                      marginRight: "8px",
                                                    }}
                                                  ></span>
                                                }
                                                isDisabled={loading}
                                                renderInput={(
                                                  inputProps,
                                                  index
                                                ) => (
                                                  <input
                                                    {...inputProps}
                                                    key={index}
                                                    style={{
                                                      width: "40px",
                                                      textAlign: "center",
                                                      marginBottom: "10px",
                                                      height: "40px",
                                                      borderStyle: "solid",
                                                      borderWidth: "1px",
                                                      borderColor: "#ddd",
                                                      color: "#000",
                                                      fontWeight: 600,
                                                      borderRadius: "5px",
                                                      backgroundColor: "#fff",
                                                      outline: "none",
                                                    }}
                                                  />
                                                )}
                                              />
                                            </div>
                                          </>
                                        ) : null}
                                      </div>
                                    </div>
                                  </>
                                ) : null}

                                {/* {isOTPVerified ? ( */}
                                {isNOTPSent ? (
                                  <div className="text-center mt-2">
                                    <button
                                      className="btn btn-primary"
                                      onClick={() => {
                                        if (email && notp) {
                                          N_verifyUpdateEmail(
                                            user?.params
                                              ? user.params.token
                                              : user.token,
                                            email,
                                            notp,
                                            1
                                          ).then((res) => {
                                            if (res.status == 200) {
                                              setSuccessMessage(res.message);
                                              // NotificationManager.success(
                                              //   res.message
                                              // );
                                              setTimeout(() => {
                                                document.location.reload();
                                              }, 4000);
                                            }
                                          });
                                        } else {
                                          setErrorMessage(
                                            "Please ensure all required fields are filled out"
                                          );
                                          // NotificationManager.error(
                                          //   "fill all data"
                                          // );
                                        }
                                      }}
                                    >
                                      Update
                                    </button>
                                  </div>
                                ) : null}
                              </div>
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  {/* -----Change Email id section ends -----------*/}

                  {/* -----Change Phone Number section starts -----------*/}

                  <section>
                    <div className="tablelike_box">
                      <div className="d-flex gap-1">
                        <div className="circle_backdrop">
                          <MdPhoneIphone className="fs-4 text-pink" />
                        </div>
                        <div>
                          <h5 className="mb-0 fs-16">Phone</h5>
                          <span className="text-lgray">
                            Used to verify account logins, withdrawals, API
                            creation, etc. Can be switched with Google
                            Verification (and vice versa). Click here to view
                            the list of supported countries/regions.
                          </span>
                        </div>
                      </div>

                      <div className="call_action">
                        {profile?.mobile_number ? (
                          <div className="call_action_left">
                            <span>
                              <i class="fa-solid fa-circle-check text-green me-1"></i>
                            </span>
                            <span className="">
                              **{profile?.mobile_number?.toString().slice(-4)}
                            </span>
                          </div>
                        ) : (
                          <div className="call_action_left">
                            <span>
                              <i class="fa-solid fa-circle-exclamation me-1 text-warning"></i>
                            </span>
                          </div>
                        )}

                        <div className="call_action_right">
                          <a
                            data-bs-toggle="collapse"
                            href="#phone_change"
                            className="btn btn-outline-primary"
                            onClick={() => toggleCollapse("phone_change")}
                          >
                            {profile?.mobile_number ? "Update" : "Add"}
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="container">
                      <div
                        className={`collapse ${
                          openCollapse === "phone_change" ? "show" : ""
                        }`}
                        id="phone_change"
                      >
                        <div className="row my-4">
                          <div className="col-lg-5 m-auto">
                            <div className="cstm_form">
                              <h4 className="sub_heading mb-3">
                                {profile?.mobile_number ? "Change" : "Add"}{" "}
                                Phone Number
                              </h4>
                              <div class="">
                                {!isOTPVerified ? (
                                  <div class="mb-4">
                                    <label class="small text-muted fs-12 fs-12">
                                      Email Verification Code
                                    </label>
                                    <div class="input-group">
                                      <input
                                        type="number"
                                        class="form-control"
                                        value={otp}
                                        onChange={(e) => {
                                          setOtp(e.target.value);
                                        }}
                                      />
                                      <span class="input-group-text p-0 bg-transparent border-0">
                                        <a
                                          className=" btn btn-pink border-0"
                                        
                                          onClick={() => {
                                            N_sendUpdateEmail(
                                              user?.params
                                                ? user.params.token
                                                : user.token,
                                              email,
                                              0
                                            ).then((res) => {
                                              if (res.status == 200) {
                                                setIsOTPSent(true);
                                                setSuccessMessagephone(
                                                  res.message
                                                );
                                              }
                                            });
                                          }}
                                        >
                                          {isOTPSent
                                            ? "Resend Code"
                                            : "Send Code"}
                                        </a>
                                        {isOTPSent ? (
                                          <a
                                            className="ms-1  btn btn-secondary border-0"
                                            onClick={() => {
                                              N_verifyUpdateEmail(
                                                user?.params
                                                  ? user.params.token
                                                  : user.token,
                                                email,
                                                otp,
                                                0
                                              ).then((res) => {
                                                if (res.status == 200) {
                                                  setIsOTPVerified(true);
                                                  NotificationManager.success(
                                                    res.message
                                                  );
                                                }
                                              });
                                            }}
                                          >
                                            Verify code
                                          </a>
                                        ) : null}
                                      </span>
                                    </div>
                                  </div>
                                ) : null}
                                {isOTPVerified ? (
                                  <div class="mb-4">
                                    <div className="error_div">
                                      {errorMessagephone && (
                                        <h6 className="mb-0 fs-14 text-danger">
                                          {errorMessagephone}
                                        </h6>
                                      )}
                                      {successMessagephone && (
                                        <h6 className="mb-0 fs-14 text-success">
                                          {successMessagephone}
                                        </h6>
                                      )}
                                      {phoneError && (
                                        <p className="text-danger">
                                          {phoneError}
                                        </p>
                                      )}
                                    </div>

                                    <div className="form-group">
                                      <label
                                        for="Fiat"
                                        class="text-muted fs-12"
                                      >
                                        Phone Number
                                      </label>
                                      <div class="input-group">
                                        <input
                                          type="texct"
                                          class="form-control"
                                          value={phone}
                                          id="user_phone"
                                          name="user_phone"
                                          // onChange={handlePhoneChange}
                                          onChange={(e) => {
                                            setPhone(isPhone(e.target.value));
                                          }}
                                          // onWheel={handleWheel}
                                        />

                                        <span class="input-group-text cursor-pointer">
                                          <div
                                            className="text-lgray"
                                            onClick={() => {
                                              if (profile?.mobile_number) {
                                                if (
                                                  phone ==
                                                  profile?.mobile_number
                                                ) {
                                                  setErrorMessagephone(
                                                    "Already Exists"
                                                  );
                                                } else if (!phone) {
                                                  setErrorMessagephone(
                                                    "Enter Valid Phone Number"
                                                  );
                                                } else {
                                                  N_sendOTPMobile(
                                                    user?.params
                                                      ? user.params.token
                                                      : user.token,
                                                    phone
                                                  ).then((res) => {
                                                    if (res.status == 200) {
                                                      setIsMOTPSent(true);
                                                      setSuccessMessagephone(
                                                        res.message
                                                      );
                                                      setTimeout(() => {
                                                        setSuccessMessagephone(
                                                          ""
                                                        );
                                                      }, 2000);
                                                    }
                                                  });
                                                }
                                              } else if (phone) {
                                                N_sendOTPMobile(
                                                  user?.params
                                                    ? user.params.token
                                                    : user.token,
                                                  phone
                                                ).then((res) => {
                                                  if (res.status == 200) {
                                                    setIsMOTPSent(true);
                                                    setSuccessMessagephone(
                                                      res.message
                                                    );
                                                    setTimeout(() => {
                                                      setSuccessMessagephone(
                                                        ""
                                                      );
                                                    }, 2000);
                                                  }
                                                });
                                              } else {
                                                setErrorMessagephone(
                                                  "Enter new phone number"
                                                );
                                                setTimeout(() => {
                                                  setErrorMessagephone("");
                                                }, 4000);
                                              }
                                            }}
                                          >
                                            {isMOTPSent ? (
                                              <>Resend Code</>
                                            ) : (
                                              <>
                                                <LuSendHorizonal className="fs-4" />
                                              </>
                                            )}
                                          </div>
                                        </span>
                                      </div>
                                      <small
                                        className="text-danger"
                                        id="phone_err"
                                      ></small>
                                    </div>

                                    {isMOTPSent ? (
                                      <>
                                        <h5 className="hero_heading mb-4 text-center">
                                          Enter the 6- digit code we sent to{" "}
                                          <span className="text-lgray">
                                            {phone}
                                          </span>
                                        </h5>
                                        <div className="d-flex justify-content-center mb-3">
                                          <OTPInput
                                            className="justify-content-center"
                                            value={motp}
                                            onChange={(otp) => {
                                              if (
                                                /^\d+$/.test(otp) ||
                                                otp === ""
                                              ) {
                                                setMOtp(otp);
                                              }
                                            }}
                                            type="tel"
                                            pattern="[0-9]*"
                                            numInputs={6}
                                            renderSeparator={
                                              <span
                                                style={{
                                                  fontSize: "7px",
                                                  marginLeft: "8px",
                                                  marginRight: "8px",
                                                }}
                                              ></span>
                                            }
                                            isDisabled={loading}
                                            renderInput={(
                                              inputProps,
                                              index
                                            ) => (
                                              <input
                                                {...inputProps}
                                                key={index}
                                                style={{
                                                  width: "40px",
                                                  textAlign: "center",
                                                  marginBottom: "10px",
                                                  height: "40px",
                                                  borderStyle: "solid",
                                                  borderWidth: "1px",
                                                  borderColor: "#ddd",
                                                  color: "#000",
                                                  fontWeight: 600,
                                                  borderRadius: "5px",
                                                  backgroundColor: "#fff",
                                                  outline: "none",
                                                }}
                                              />
                                            )}
                                          />
                                        </div>
                                      </>
                                    ) : null}
                                    {/* <input
                                            type="number"
                                            class="form-control"
                                            value={motp}
                                            onChange={(e) => {
                                              setMOtp(e.target.value);
                                            }}
                                          /> */}
                                  </div>
                                ) : null}
                                {isMOTPSent ? (
                                  <div className="text-center mt-2">
                                    <button
                                      className="btn btn-primary"
                                      onClick={() => {
                                        if (phone && motp) {
                                          N_verifyOTPMobile(
                                            user?.params
                                              ? user.params.token
                                              : user.token,
                                            motp
                                          ).then((res) => {
                                            if (res.status == 200) {
                                              setSuccessMessagephone(res.message);
                                              // NotificationManager.success(
                                              //   res.message
                                              // );
                                              setTimeout(() => {
                                                document.location.reload();
                                              }, 4000);
                                            }
                                          });
                                        } else {
                                          setErrorMessagephone(
                                            "Please ensure all required fields are filled out"
                                          );
                                          // NotificationManager.error(
                                          //   "fill all data"
                                          // );
                                        }
                                      }}
                                    >
                                      Update Phone Number
                                    </button>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  {/* -----Change Phone Number section ends -----------*/}
                </div>
              </div>

              {/* ---------Advanced Settings Column ----------*/}

              <div className="col-lg-12">
                <div className="account_page_subheading mb-2">
                  Advanced Settings
                </div>
                <div>
                  <section>
                    <div className="row mt-4">
                      <div>
                        <div className="tablelike_box" id="scroll_to_google">
                          <div className="d-flex gap-1  ">
                            <div className="circle_backdrop">
                              <FcGoogle className="fs-4" />
                            </div>

                            <div>
                              <h5 className="mb-0 fs-16">
                                Google Two Factor Authentication
                              </h5>
                              <span className="text-lgray">
                                Used to verify account logins, withdrawals, API
                                creation, etc. Can be switched with SMS
                                verification (and vice versa). Instructions
                              </span>
                            </div>
                          </div>

                          <div className="call_action">
                            {profile.authenticator == 2 ? (
                              <div className="call_action_left">
                                <i class="fa-solid fa-circle-check text-green me-1"></i>
                                <span>Enable</span>
                              </div>
                            ) : (
                              <div className="call_action_left">
                                <i class="fa-solid fa-circle-exclamation me-1 text-warning"></i>
                                <span className="text-muted fs-12">
                                  Not Enabled
                                </span>
                              </div>
                            )}

                            <div className="call_action_right">
                              <a
                                className="btn btn-outline-primary"
                                data-bs-toggle="collapse"
                                href="#google_change"
                                onClick={() => toggleCollapse("google_change")}
                              >
                                {profile.authenticator == 2
                                  ? "Disable"
                                  : "Enable"}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container">
                      <div
                        className={`collapse ${
                          openCollapse === "google_change" ? "show" : ""
                        }`}
                        id="google_change"
                      >
                        <div className="row my-4">
                          <div className="col-lg-5 m-auto">
                            <h4 className="sub_heading">
                              Set Up Google Two-Factor Authentication
                            </h4>

                            <div className="mt-4">
                              <div class="cstm_form">
                                {!isGOTPverified ? (
                                  <>
                                    <p className="fs-12 text-lgray">
                                      Tap the{" "}
                                      <LuSendHorizonal className="text-lgray mx-1" />
                                      button, An OTP (One Time Verification)
                                      code will be sent to your registered email
                                      id
                                    </p>

                                    <div class="mb-4">
                                      <div class="input-group">
                                        <input
                                          type="email"
                                          class="form-control text-muted"
                                          value={profile.email}
                                          readOnly
                                          disabled={isNOTPSent}
                                        />
                                        <span class="input-group-text">
                                          <a
                                            className="cursor_pointer"
                                            onClick={() => {
                                              if (profile?.email) {
                                                N_sendUpdateEmail(
                                                  user?.params
                                                    ? user.params.token
                                                    : user.token,
                                                  profile?.email,
                                                  0
                                                ).then((res) => {
                                                  if (res.status == 200) {
                                                    setIsNOTPSent(true);
                                                    setSuccessMessage(
                                                      res.message
                                                    );
                                                    setTimeout(() => {
                                                      setSuccessMessage("");
                                                    }, 4000);
                                                  }
                                                });
                                              } else {
                                                setErrorMessage(
                                                  "Please enter new Email ID"
                                                );
                                              }
                                            }}
                                          >
                                            {isNOTPSent ? (
                                              <>Resend Code</>
                                            ) : (
                                              <>
                                                <LuSendHorizonal className="fs-4 text-lgray" />
                                              </>
                                            )}
                                          </a>
                                        </span>
                                      </div>
                                      <div>
                                        {isNOTPSent ? (
                                          <>
                                            <h5 className="hero_heading mb-2 mt-4 text-center">
                                              Enter the 6- digit code we sent to{" "}
                                              <span className="text-lgray">
                                                {profile?.email}
                                              </span>
                                            </h5>

                                            <div className="d-flex justify-content-center">
                                              <OTPInput
                                                value={notp}
                                                onChange={(otp) => {
                                                  if (
                                                    /^\d+$/.test(otp) ||
                                                    otp === ""
                                                  ) {
                                                    setNOtp(otp);
                                                  }
                                                }}
                                                type="tel"
                                                pattern="[0-9]*"
                                                numInputs={6}
                                                renderSeparator={
                                                  <span
                                                    style={{
                                                      fontSize: "7px",
                                                      marginLeft: "8px",
                                                      marginRight: "8px",
                                                    }}
                                                  ></span>
                                                }
                                                isDisabled={loading}
                                                renderInput={(
                                                  inputProps,
                                                  index
                                                ) => (
                                                  <input
                                                    {...inputProps}
                                                    key={index}
                                                    style={{
                                                      width: "40px",
                                                      textAlign: "center",
                                                      marginBottom: "10px",
                                                      height: "40px",
                                                      borderStyle: "solid",
                                                      borderWidth: "1px",
                                                      borderColor: "#ddd",
                                                      color: "#000",
                                                      fontWeight: 600,
                                                      borderRadius: "5px",
                                                      backgroundColor: "#fff",
                                                      outline: "none",
                                                    }}
                                                  />
                                                )}
                                              />
                                            </div>
                                          </>
                                        ) : null}
                                        {isNOTPSent ? (
                                          <div className="text-center mt-3">
                                            <button
                                              className="btn btn-primary"
                                              onClick={() => {
                                                if (profile?.email && notp) {
                                                  N_verifyUpdateEmail(
                                                    user?.params
                                                      ? user.params.token
                                                      : user.token,
                                                    profile?.email,
                                                    notp,
                                                    1
                                                  ).then((res) => {
                                                    if (res.status == 200) {
                                                      setIsGOTPVerified(true);
                                                      setSuccessMessage(
                                                        res.message
                                                      );
                                                      // NotificationManager.success(
                                                      //   res.message
                                                      // );
                                                      N_setGoogleAuth(
                                                        user?.params
                                                          ? user.params.token
                                                          : user.token
                                                      ).then((d) => {
                                                        if (d.status == 200) {
                                                          QRCode.toDataURL(
                                                            d.secret
                                                              .otpauth_url,
                                                            function (
                                                              err,
                                                              data_url
                                                            ) {
                                                              setUrl(data_url);
                                                              setUserSecret(
                                                                d.secret.base32
                                                              );
                                                            }
                                                          );
                                                        }
                                                      });
                                                    }
                                                  });
                                                } else {
                                                  setErrorMessage(
                                                    "Please ensure all required fields are filled out"
                                                  );
                                                  // NotificationManager.error(
                                                  //   "fill all data"
                                                  // );
                                                }
                                              }}
                                            >
                                              Verify
                                            </button>
                                          </div>
                                        ) : null}

                                        {/* <input
                                      type="number"
                                      class="form-control"
                                      value={notp}
                                      onChange={(e) => {
                                        setNOtp(e.target.value);
                                      }}
                                    /> */}
                                      </div>
                                    </div>
                                  </>
                                ) : null}
                                {isGOTPverified ? (
                                  <div class="mb-4">
                                    {profile?.authenticator !== 2 ? (
                                      <>
                                        <ul className="fs-12 text-lgray">
                                          <li className="mb-2">
                                            <strong className="me-1">
                                              {" "}
                                              1.{" "}
                                            </strong>{" "}
                                            Use an authentication app such as
                                            Google Authenticator (can be
                                            downloaded from the App store or
                                            Google Play) on your mobile device
                                            to scan this QR Code
                                          </li>
                                          <li className="mb-2">
                                            <strong className="me-1">
                                              {" "}
                                              2.{" "}
                                            </strong>
                                            Get verification code after scanning
                                            with google authenticator
                                          </li>
                                          <li className="mb-2">
                                            <strong className="me-1">
                                              {" "}
                                              3.{" "}
                                            </strong>
                                            Enter the verification code given by
                                            the app on your mobile device and
                                            proceed
                                          </li>
                                        </ul>

                                        <div className="form-group">
                                          <img
                                            src={url}
                                            className="shadow-sm rounded"
                                          />
                                        </div>

                                        <div className="input-group">
                                          <input
                                            type="text"
                                            class="form-control"
                                            value={userSecret}
                                          />
                                          <span class="input-group-text">
                                            <a href="" className="text-lgray">
                                              {" "}
                                              <BiCopy />
                                            </a>
                                          </span>
                                        </div>

                                        {/* <label
                                          for="google otp"
                                          class="text-muted fs-12"
                                        >
                                          OTP
                                        </label> */}

                                        <h5 className="hero_heading">
                                          Enter the Google Authenticator code
                                        </h5>

                                        <div className="error_div">
                                          {errorMessagegotp && (
                                            <h6 className="mb-0 fs-14 text-danger">
                                              {errorMessagegotp}
                                            </h6>
                                          )}

                                          {successMessaeggotp && (
                                            <h6 className="fs-14 text-success">
                                              {successMessaeggotp}
                                            </h6>
                                          )}
                                        </div>

                                        <OTPInput
                                          value={gotp}
                                          onChange={(otp) => {
                                            if (
                                              /^\d+$/.test(otp) ||
                                              otp === ""
                                            ) {
                                              setGotp(otp);
                                            }
                                          }}
                                          type="tel"
                                          pattern="[0-9]*"
                                          numInputs={6}
                                          renderSeparator={
                                            <span
                                              style={{
                                                fontSize: "7px",
                                                marginLeft: "8px",
                                                marginRight: "8px",
                                              }}
                                            ></span>
                                          }
                                          isDisabled={loading}
                                          renderInput={(inputProps, index) => (
                                            <input
                                              {...inputProps}
                                              key={index}
                                              style={{
                                                width: "40px",
                                                textAlign: "center",
                                                marginBottom: "10px",
                                                height: "40px",
                                                borderStyle: "solid",
                                                borderWidth: "1px",
                                                borderColor: "#ddd",
                                                color: "#000",
                                                fontWeight: 600,
                                                borderRadius: "5px",
                                                backgroundColor: "#fff",
                                                outline: "none",
                                              }}
                                            />
                                          )}
                                        />

                                        {/* <input
                                          type="number"
                                          class="form-control"
                                          value={gotp}
                                          onChange={(e) => {
                                            setGotp(e.target.value);
                                          }}
                                        /> */}
                                      </>
                                    ) : null}
                                  </div>
                                ) : null}
                                {isGOTPverified ? (
                                  <div className="">
                                    {profile?.authenticator !== 2 ? (
                                      <button
                                        className="btn btn-primary"
                                        onClick={() => {
                                          if (gotp) {
                                            N_setGoogleAuthOTP(
                                              user?.params
                                                ? user.params.token
                                                : user.token,
                                              2,
                                              gotp,
                                              "g"
                                            ).then((data) => {
                                              if (data.status === 200) {
                                                setErrorMessagegotp(
                                                  data.message
                                                );
                                                window.location.reload();
                                              } else {
                                                setErrorMessagegotp(
                                                  data.message
                                                );
                                              }
                                            });
                                          } else {
                                            setErrorMessagegotp(
                                              "OPT can not be blank"
                                            );
                                          }
                                        }}
                                      >
                                        Update
                                      </button>
                                    ) : (
                                      <button
                                        className="btn btn-primary"
                                        onClick={() => {
                                          N_setGoogleAuthOTP(
                                            user?.params
                                              ? user.params.token
                                              : user.token,
                                            0
                                          ).then((data) => {
                                            if (data.status === 200) {
                                              NotificationManager.success(
                                                data.message
                                              );
                                              window.location.reload();
                                            } else {
                                              NotificationManager.error(
                                                data.message
                                              );
                                            }
                                          });
                                        }}
                                      >
                                        Disable
                                      </button>
                                    )}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div>
                        <div className="tablelike_box_simple mb-3">
                          <div className="row">
                            <div className="col-lg-8">
                              <div className="d-flex gap-3">
                                <div className="circle_backdrop">
                                  <LuKey className="fs-4 text-pink" />
                                </div>
                                <div>
                                  <h5 className="mb-0 fs-16">Login Password</h5>
                                  <span className="text-lgray">
                                    You can set password
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-4 text-end">
                              <a
                                className="btn btn-outline-primary"
                                data-bs-toggle="collapse"
                                href="#password_change"
                                onClick={() =>
                                  toggleCollapse("password_change")
                                }
                              >
                                Change
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="container">
                          <div
                            className={`collapse ${
                              openCollapse === "password_change" ? "show" : ""
                            }`}
                            id="password_change"
                          >
                            <div className="row my-4">
                              <div className="col-lg-5 m-auto">
                                <h4 className="sub_heading">Change Password</h4>
                                <div className="error_div">
                                  {errorMessagepassword && (
                                    <h6 className="mb-0 fs-14 text-danger">
                                      {errorMessagepassword}
                                    </h6>
                                  )}

                                  {successMessagepassword && (
                                    <h6 className="fs-14 text-success">
                                      {successMessagepassword}
                                    </h6>
                                  )}
                                </div>
                                <div className="">
                                  <div class="cstm_form">
                                    <div class="alert alert-warning fs-12">
                                      <div className="d-flex gap-3">
                                        <div>
                                          <i
                                            class="fa fa-info-circle fs-5 text-warning"
                                            aria-hidden="true"
                                          ></i>
                                        </div>

                                        <div className="">
                                          Withdrawal will be restricted for 24
                                          hours after changing your password.
                                        </div>
                                      </div>
                                    </div>
                                    {!ispasswordOtp ? (
                                      <>
                                        <div className={`form-group mb-4`}>
                                          <label htmlFor="user_password">
                                            New Password
                                          </label>
                                          <div className="input-group">
                                            <input
                                              type="password"
                                              className="form-control"
                                              name="user_password"
                                              required=""
                                              value={password}
                                              onChange={(e) =>
                                                setPassword(
                                                  isPassu(e.target.value)
                                                )
                                              }
                                              id="pass"
                                              // placeholder="password"
                                            />
                                            <span className="input-group-text">
                                              <a
                                                href="#view_qr"
                                                className=""
                                                onClick={(e) => {
                                                  hsPassword("pass");
                                                }}
                                              >
                                                {eye ? (
                                                  <RxEyeOpen />
                                                ) : (
                                                  <RxEyeNone />
                                                )}
                                              </a>
                                            </span>
                                          </div>
                                          <div>
                                            {" "}
                                            <small
                                              className="text-danger"
                                              id="passerr1"
                                            ></small>
                                          </div>
                                        </div>
                                        <div className={`form-group mb-4`}>
                                          <label htmlFor="user_cpassword">
                                            Confirm Password
                                          </label>
                                          <div className="input-group">
                                            <input
                                              type="password"
                                              name="user_cpassword"
                                              className="form-control"
                                              id="cpass"
                                              required=""
                                              value={confPassword}
                                              onChange={(e) =>
                                                setConfPassword(
                                                  isCpass(e.target.value)
                                                )
                                              }
                                            />
                                            <span className="input-group-text">
                                              <a
                                                href="#view_qr"
                                                className=""
                                                onClick={(e) => {
                                                  hsPassword1("cpass");
                                                }}
                                              >
                                                {eye2 ? (
                                                  <RxEyeOpen />
                                                ) : (
                                                  <RxEyeNone />
                                                )}
                                              </a>
                                            </span>
                                          </div>
                                          <small
                                            className="text-danger"
                                            id="cpasserr"
                                          ></small>
                                        </div>
                                        <div className="text-center mt-3">
                                          <button
                                            className="btn btn-primary"
                                            onClick={() => {
                                              if (
                                                isPassu(password) &&
                                                isCpass(confPassword)
                                              ) {
                                                if (checkPassword(password)) {
                                                  if (
                                                    checkPassword(confPassword)
                                                  ) {
                                                    N_changePassword(
                                                      user?.params
                                                        ? user.params.token
                                                        : user.token,
                                                      password,
                                                      confPassword
                                                    ).then((data) => {
                                                      if (data.status === 200) {
                                                        setIsPasswordOtp(true);
                                                        setSuccessMessagePassword(
                                                          data.message
                                                        );
                                                        setTimeout(() => {
                                                          setSuccessMessagePassword(
                                                            ""
                                                          );
                                                        }, 4000);
                                                      } else {
                                                        setErrorMessagePassword(
                                                          data.message
                                                        );
                                                        setTimeout(() => {
                                                          setErrorMessagePassword(
                                                            ""
                                                          );
                                                        }, 4000);
                                                      }
                                                    });
                                                  } else {
                                                    document
                                                      .getElementById("cpass")
                                                      .focus();
                                                  }
                                                } else {
                                                  document
                                                    .getElementById("pass")
                                                    .focus();
                                                }
                                              }
                                            }}
                                          >
                                            Update
                                          </button>
                                        </div>
                                      </>
                                    ) : null}
                                    {ispasswordOtp ? (
                                      <div class="mb-4">
                                        <h5 className="hero_heading mb-2 text-center">
                                          Enter the 6- digit code we sent to{" "}
                                          <span className="text-lgray">
                                            {profile?.email}
                                          </span>
                                        </h5>
                                        <div className="d-flex justify-content-center">
                                          <OTPInput
                                            value={passotp}
                                            onChange={(otp) => {
                                              if (
                                                /^\d+$/.test(otp) ||
                                                otp === ""
                                              ) {
                                                setPassOtp(otp);
                                              }
                                            }}
                                            type="tel"
                                            pattern="[0-9]*"
                                            numInputs={6}
                                            renderSeparator={
                                              <span
                                                style={{
                                                  fontSize: "7px",
                                                  marginLeft: "8px",
                                                  marginRight: "8px",
                                                }}
                                              ></span>
                                            }
                                            isDisabled={loading}
                                            renderInput={(
                                              inputProps,
                                              index
                                            ) => (
                                              <input
                                                {...inputProps}
                                                key={index}
                                                style={{
                                                  width: "40px",
                                                  textAlign: "center",
                                                  marginBottom: "10px",
                                                  height: "40px",
                                                  borderStyle: "solid",
                                                  borderWidth: "1px",
                                                  borderColor: "#ddd",
                                                  color: "#000",
                                                  fontWeight: 600,
                                                  borderRadius: "5px",
                                                  backgroundColor: "#fff",
                                                  outline: "none",
                                                }}
                                              />
                                            )}
                                          />
                                        </div>
                                        <div className="text-center mt-2">
                                          <button
                                            className="btn btn-primary"
                                            onClick={() => {
                                              if (passotp) {
                                                N_verifyPassword(
                                                  user?.params
                                                    ? user.params.token
                                                    : user.token,
                                                  passotp
                                                ).then((res) => {
                                                  if (res.status == 200) {
                                                    setSuccessMessagePassword(
                                                      res.message
                                                    );
                                                    dispatch(
                                                      user_logout(() => {
                                                        props.history?.replace(
                                                          "/login"
                                                        );
                                                      })
                                                    );
                                                  } else {
                                                    setErrorMessagePassword(
                                                      res.message
                                                    );
                                                    setTimeout(() => {
                                                      setErrorMessagePassword(
                                                        ""
                                                      );
                                                    }, 4000);
                                                  }
                                                });
                                              } else {
                                                setErrorMessage(
                                                  "Please ensure all required fields are filled out"
                                                );
                                                setTimeout(() => {
                                                  setErrorMessagePassword("");
                                                }, 4000);
                                              }
                                            }}
                                          >
                                            Update
                                          </button>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  {/* ---------Advanced Settings Column ----------*/}
                  <div className="col-lg-12 mt-4">
                    <div className="account_page_subheading mb-2">Account</div>
                    <section>
                      <div className="tablelike_box_simple mb-3">
                        <div className="row">
                          <div className="col-lg-8">
                            <div className="d-flex gap-3">
                              <div className="circle_backdrop">
                                <MdDisabledVisible className="fs-4 text-pink" />
                              </div>
                              <div>
                                <h5 className="mb-0 fs-16">Freeze Account</h5>
                                <span className="text-lgray">
                                  If you freeze your account, trading and
                                  withdrawals will be disabled. You can request
                                  to unfreeze your account the next time you log
                                  in.
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-4 text-end">
                            <div
                              className="btn btn-outline-primary"
                              data-bs-toggle="modal"
                              data-bs-target="#freez_confirmation"
                            >
                              Freeze
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section>
                      <div className="tablelike_box_simple mb-3">
                        <div className="row">
                          <div className="col-lg-8">
                            <div className="d-flex gap-3">
                              <div className="circle_backdrop">
                                <AiOutlineUserDelete className="fs-4 text-pink" />
                              </div>
                              <div>
                                <h5 className="mb-0 fs-16">Delete Account</h5>
                                <span className="text-lgray">
                                  Warning: Once you delete your account, it
                                  cannot be recovered and you will be unable to
                                  use any of its associated services.
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-4 text-end">
                            <a
                              className="btn btn-outline-primary"
                              data-bs-toggle="modal"
                              data-bs-target="#delete_confirmation"
                            >
                              Delete
                            </a>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      <div id="freez_confirmation" class="modal fade" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-sm">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title ">Freeze Account</h4>
              <button
                type="button"
                class="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="text-center">
                <div className="fs-16 mb-3">
                  Are you sure you want to freeze?
                </div>
              </div>{" "}
              <div className="d-flex gap-2 my-2">
                <div className="d-grid w-50">
                  {" "}
                  <a className="btn btn-light" data-bs-dismiss="modal">
                    Cancel
                  </a>
                </div>
                <div className="d-grid w-50">
                  <a
                    className="btn btn-outline-primary ms-2"
                    onClick={() => {
                      N_freezeAccount(
                        user?.params ? user.params.token : user.token,
                        "freeze"
                      ).then((data) => {
                        if (data.status == 200) {
                          NotificationManager.success(data.message);
                          setTimeout(() => {
                            dispatch(
                              user_logout(() => {
                                props.history?.replace("/login");
                              })
                            );
                            document.location.reload();
                          }, 3000);
                        }
                      });
                    }}
                  >
                    Freeze
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="delete_confirmation" class="modal fade" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-sm">
          <div class="modal-content text-center">
            <div class="modal-header">
              <h4 class="modal-title text-center">Delete Account</h4>
              <button
                type="button"
                class="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body text-center">
              <div className="fs-16 mb-3">
                {" "}
                Are you sure to Delete your account ?
              </div>
              <div class="my-2 d-flex gap-2">
                <div className="d-grid w-50 ">
                  <button
                    className="btn btn-light"
                    onClick={() => {
                      N_freezeAccount(
                        user?.params ? user.params.token : user.token,
                        "freeze"
                      ).then((data) => {
                        if (data.status == 200) {
                          NotificationManager.success(data.message);
                          setTimeout(() => {
                            dispatch(
                              user_logout(() => {
                                props.history?.replace("/");
                              })
                            );
                            document.location.reload();
                          }, 3000);
                        }
                      });
                    }}
                  >
                    Yes
                  </button>
                </div>
                <div className="d-grid w-50">
                  <a
                    className="btn btn-outline-primary"
                    data-bs-dismiss="modal"
                  >
                    No
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
