import React, { useState, useEffect } from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Header from "./HomeComp/Header";
import "./sidebar.css";
import QRCode from "qrcode.react"; // Import the qrcode.react library
import { BiError, BiSolidCheckShield } from "react-icons/bi";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";

import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  hexToRgb,
} from "@mui/material";
import { FaCheckCircle, FaDotCircle, FaInfoCircle } from "react-icons/fa";
import {
  AiFillSafetyCertificate,
  AiOutlineQuestionCircle,
  AiOutlineSafety,
  AiOutlineSwap,
  AiOutlineSwapRight,
} from "react-icons/ai";
import { CiCircleQuestion, CiSearch, CiSignpostDuo1 } from "react-icons/ci";
import { FcInfo } from "react-icons/fc";
import {
  BsCopy,
  BsShieldExclamation,
  BsSignpost,
  BsSignpost2,
} from "react-icons/bs";
import { IoChatboxEllipsesOutline, IoReloadSharp } from "react-icons/io5";
import { PiSignpostDuotone } from "react-icons/pi";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import {
  N_createp2pSellOrder,
  N_getChat,
  N_getPaymentMethodUser,
  N_get_walletp2p,
  N_getp2pOrder,
  N_p2pCancelIntiateOrder,
  N_p2pCancelOrder,
  N_p2pConfirmOrder,
  N_p2pInitiateOrder,
  N_p2pMyOrder,
  N_p2pOrderAction,
  N_p2pOrderAppeal,
  N_p2pSellOrder,
  N_paymentMethod,
  N_setChat,
} from "./redux/helpers/api_functions_new";
import { FiSmartphone } from "react-icons/fi";
import { getOrderTypeFromOrderId, getSecond } from "./redux/helpers/helper_functions";
import { HiMiniClock } from "react-icons/hi2";
import { MdOutlineAttachment, MdSend } from "react-icons/md";
import { TbSpeakerphone } from "react-icons/tb";
import { GoPlusCircle } from "react-icons/go";
export default function P2pOrder(props) {
  const { user, profile, isLoggedIn } = useSelector((state) => state.AuthReducer);
  const { coins } = useSelector((state) => state.coinDBReducer);
  const [wallets, setWallets] = useState([]);
  const [buyorder, setBuyOrder] = useState([]);
  const [sellorder, setSellOrder] = useState([]);
  const [data, setdata] = useState(null);
  const [searchAmount, SetSearchAmount] = useState(null);
  const [amountinr, setAmonutINR] = useState(null);
  const [amountusdt, setAmountUSDT] = useState(null);
  const [buyamountinr, setBuyAmountINR] = useState(null);
  const [buyamountusdt, setBuyAmountUsdt] = useState(null);
  const [sellamountinr, setSellAmountINR] = useState(null);
  const [sellamountusdt, setSellAmountUsdt] = useState(null);
  const [price, setPrice] = useState(null);
  const [payment_method, setPaymentMenthod] = useState(null);
  const [payment_method_data, setPaymentMenthodData] = useState([]);
  const [buyData, setBuyData] = useState(null);
  const [sellData, setSellData] = useState(null);
  const [order_deatils, setOrderDetails] = useState(null);
  const [maindata, setMainData] = useState(null);
  const [bankData, setUserBank] = useState(null);
  const [myorder, setMyOrder] = useState([]);
  const [messages, setMessages] = useState([]);
  const [from_name, setFromName] = useState(null);
  const [inputText, setInputText] = useState("");
  const [order_id, setOrderId] = useState(null);
  const [order_data, setOrderData] = useState(null);
  const [fromData, setFromData] = useState(null);
  const [sell_, setSell_message] = useState(null);
  const [buy_, setBuy_message] = useState(null);
  const [post_, setPost_message] = useState(null);

  const handleSendMessage = () => {
    if (inputText.trim() !== "") {
      N_setChat(user?.params ? user.params.token : user.token, order_id, inputText)
      .then((res)=>{
        if(res.status==200) {
          getChats(order_id);
        }
      })
     
      setInputText("");
    }
  };

  const getChats = (order_id) =>{
    N_getChat(user?.params ? user.params.token : user.token, order_id)
    .then((res)=>{
      setMessages(res.result);
      setFromName(res.name);
      setFromData(res._from_data);
    })
  }

  const getOrders = (history_id) =>{
    N_getp2pOrder(user?.params ? user.params.token : user.token, history_id, "history")
    .then((res)=>{
      if(res.status==200) {
        setMainData(res.data);
      }
      
    })
  }
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");

  const handlePaymentMethodChange = (event) => {
    const method = event.target.value;
    setSelectedPaymentMethod(method);
  };

  // for tab
  const [activeTab, setActiveTab] = useState("buy");
  const [activeorder, setActiveOrder] = useState(0);
  const [timer, setTimer] = useState(null); // 30 minutes in seconds

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const [activeTabBuySell, setActiveTabBuySell] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTabBuySell(tabNumber);
  };

  // for filter option
  const [selectedOption, setSelectedOption] = useState("USDT");
  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const [selectedOption2, setSelectedOption2] = useState("option2");

  const handleChange2 = (event) => {
    setSelectedOption2(event.target.value);
  };

  const [selectedOptionrefresh, setSelectedOptionrefresh] = useState("not");

  const handleRefresh = (event) => {
    setSelectedOptionrefresh(event.target.value);
  };

  //for order filter

  const [selectedCoin, setSelectedCoin] = useState("all"); // Set the default value here

  const handleCoin = (event) => {
    setSelectedCoin(event.target.value);
  };

  const [selectedStatus, setSelectedStatus] = useState("all"); // Set the default value here
  const [start_date, setStartDate] = useState("");
  const [end_date, setEndDate] = useState("");
  const handleStatus = (event) => {
    setSelectedStatus(event.target.value);
  };

  const [selectedType, setSelectedType] = useState("all"); // Set the default value here

  const handleType = (event) => {
    setSelectedType(event.target.value);
  };

  //for bank payment
  const [selectedOptionBank, setSelectedOptionBank] = useState("all");

  const handleBank = (event) => {
    setSelectedOptionBank(event.target.value);
  };

  // for multiple option selection dropdown

  const [selectedMultioptions, setSelectedMultioptions] = useState(["option1"]);
  const options = [
    { value: "option1", label: "All Payment Method" },
    { value: "option2", label: "UPI" },
    { value: "option3", label: "Google Pay" },
    { value: "option4", label: "PhonePe" },
    { value: "option5", label: "Bank Transfer" },
    { value: "option6", label: "PayTm" },
    { value: "option7", label: "Airtel Money" },
    { value: "option8", label: "State Bank" },
    { value: "option9", label: "PNB Bank" },
  ];

  const handleMultiOptions = (event) => {
    setSelectedMultioptions(event.target.value);
  };

  const handleChipDelete = (chipToDelete) => () => {
    setSelectedMultioptions((chips) =>
      chips.filter((chip) => chip !== chipToDelete)
    );
  };

  //another multioption

  const [selectedBankoptions, setSelectedBankoptions] = useState([]); // Store selected options in an array
  const payoptions = [
    { value: "option1", label: "Not now" },
    { value: "option2", label: "5s to refresh" },
    { value: "option3", label: "10s to refresh" },
    { value: "option4", label: "20s to refresh" },
  ];

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedBankoptions((prevSelected) =>
      prevSelected.includes(value)
        ? prevSelected.filter((option) => option !== value)
        : [...prevSelected, value]
    );
  };

  //for popover
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // for tooltip
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
      "&::before": {
        backgroundColor: theme.palette.common.black,
        border: "1px solid #f5f5f5",
      },
    },

    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#000",

      maxWidth: 200,
    },
  }));

  useEffect(() => {
    N_get_walletp2p(user?.params ? user.params.token : user.token).then(
      (res) => {
        if (res.status == 200) {
          setWallets(Object.values(res.params.wallets));
        }
      }
    );
  }, []);

  useEffect(() => {
    N_p2pSellOrder(
      user?.params ? user.params.token : user.token,
      activeTab,
      selectedOption,
      searchAmount,
      selectedOptionBank
    ).then((res) => {
      if (res.status == 200) {
        if (activeTab == "buy") setBuyOrder(res.p2porder);
        if (activeTab == "sell") setSellOrder(res.p2porder);
      }
    });
  }, [activeTab, selectedOption, searchAmount, selectedOptionBank, selectedOptionrefresh]);

  useEffect(() => {
    if(activeorder==2) {
      N_p2pMyOrder(
        user?.params ? user.params.token : user.token,
        selectedType,
        selectedCoin,
        selectedStatus,
        start_date, 
        end_date
      ).then((res) => {
        if (res.status == 200) {
          setMyOrder(res.p2porder);
        }
      });
    }
  
  }, [activeorder, selectedType, selectedCoin, selectedStatus, start_date, end_date]);

  useEffect(() => {
    if (!selectedOption || !coins || !wallets) return;

    const coinData = Object.values(coins).find(
      (item) => item.symbol === selectedOption
    );
    const walletData = wallets.find((item) => item.symbol === selectedOption);

    if (coinData && walletData) {
      const combinedData = {
        ...walletData,
        current_price: coinData.current_price,
        spread_inr: coinData.spread_inr,
        spread_usdt: coinData.spread_usdt,
      };
      setdata(combinedData);
      setPrice(
        parseFloat(combinedData.current_price.toFixed(combinedData.spread_inr))
      );
    }
  }, [coins, wallets, selectedOption]);

  useEffect(() => {
    N_paymentMethod(user?.params ? user.params.token : user.token).then(
      (res) => {
        if (res.status == 200) {
          setPaymentMenthodData(res.data);
        }
      }
    );
  }, []);

  useEffect(() => {
    let order_id = getOrderTypeFromOrderId(order_deatils?.order_id)=="Sell"?order_deatils?.order_id:order_deatils?.requested_orderby;
    N_getPaymentMethodUser(
      user?.params ? user.params.token : user.token,
      order_id,
      order_deatils?.payment_method
    ).then((res) => {
      if (res.status == 200) {
        setUserBank(res.bankData);
      }
    });
  }, [order_deatils]);

  useEffect(() => {
    if (timer !== null) {
      const countdown = setInterval(() => {
        if (timer > 0) {
          setTimer(timer - 1);
        } else {
          N_p2pCancelIntiateOrder(
            user?.params ? user.params.token : user.token,
            order_deatils?.order_id
          );
          clearInterval(countdown);
          setTimer(null);
        }
      }, 1000);

      // Cleanup function
      return () => clearInterval(countdown);
    }
  }, [timer]);

  const formatTimer = () => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return (
      <div className="timer_wrapper">
        {/* Display minutes */}
        <div className="by-space-item">
          <div className="time-string">
            {minutes.toString().padStart(2, "0")}
          </div>
        </div>
        {/* Display separator */}
        <div className="by-space-item">
          <div className="time-separate">:</div>
        </div>
        {/* Display seconds */}
        <div className="by-space-item">
          <div className="time-string">
            {seconds.toString().padStart(2, "0")}
          </div>
        </div>
      </div>
    );
  };

  let p2pcoin =
    coins &&
    Object.values(coins).map((item, index) => {
      if(item?.isp2p) {
        return (
          <MenuItem key={index} className="select-option" value={item.symbol}>
            <img
              src={item.icon}
              alt={`option ${index}`}
              style={{
                marginRight: "8px",
                width: "14px",
                height: "14px",
              }}
            />
            {item.symbol}
          </MenuItem>
        );
      }
      
    });
  const PaymentForm = () => {
    return (
      <div>
        {bankData?.length > 0 &&
          bankData?.map((method, index) => (
            <div key={index} className="form-check mb-2">
              <input
                className="form-check-input"
                type="radio"
                name="paymentMethod"
                value={method.type}
                checked={selectedPaymentMethod === method.type}
                onChange={handlePaymentMethodChange}
              />
              <label className="form-check-label" htmlFor={method.type}>
                {method.type.toUpperCase()}
              </label>

              {/* Additional info for selected payment method */}
              {selectedPaymentMethod === method.type && (
                <div className="p-3">
                  {selectedPaymentMethod == "upi" ? (
                    <div className="additional-info">
                      <div className="info-row">
                        <div className="info-label">UPI ID</div>
                        <div className="info-value">{method.upi_id}</div>
                      </div>
                      <div className="info-row">
                        <div className="info-label">QR Code</div>
                        <div className="info-value">
                          <QRCode value={method.upi_id} size={150} />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="additional-info">
                      <div className="info-row">
                        <div className="info-label">Account Name</div>
                        <div className="info-value">{method.name}</div>
                      </div>
                      <div className="info-row">
                        <div className="info-label">Account Number</div>
                        <div className="info-value">
                          {method.account_number}
                        </div>
                      </div>
                      <div className="info-row">
                        <div className="info-label">IFSC CODE</div>
                        <div className="info-value">{method.ifsc}</div>
                      </div>
                      <div className="info-row">
                        <div className="info-label">Bank Name</div>
                        <div className="info-value">{method.bank_name}</div>
                      </div>
                      <div className="info-row">
                        <div className="info-label">Account Type</div>
                        <div className="info-value">{method.account_type}</div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
      </div>
    );
  };

  return (
    <>
      <Header />
      <div className="page-content">
        <div class="tab_area">
          <div class="container custom_pills">
            <div className="row">
              <div className="col-lg-9">
                <div class="nav nav-pills">
                  {/* <Link class="nav-item nav-link " to="/FastTrade">
                    Fast Trade
                  </Link> */}
                  <Link
                    class="nav-item nav-link active"
                    to="/p2pOrder"
                    onClick={() => {
                      setActiveOrder(0);
                    }}
                  >
                    P2P
                  </Link>
                  {/* <a class="nav-item nav-link" href="/ThirdpartyOrder">
                Third Party
              </a> */}
                </div>
              </div>
              <div className="col-lg-3">
                <ul class="nav nav-pills justify-content-end">
                  <li class="nav-item dropdown">
                    <a
                      class="nav-link dropdown-toggle"
                      type="button"
                      id="accountsmenu"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Ads
                    </a>
                    <ul
                      class="shadow dropdown-menu border-0"
                      id="dwn"
                      aria-labelledby="accountsmenu"
                    >
                      <li>
                        {isLoggedIn?
                        <a
                          data-bs-target="#ads_post_modal"
                          data-bs-toggle="modal"
                          class="dropdown-item cursor_pointer"
                        >
                          Post Ads
                        </a>:
                        <Link to="/login" className="dropdown-item cursor_pointer">
                          Post Ads
                        </Link>
                        }
                      </li>
                      {/* {isLoggedIn?
                      <li>
                        <a class="dropdown-item cursor_pointer">My Ads</a>
                      </li>:null} */}
                    </ul>
                  </li>
                  {isLoggedIn?
                  <li class="nav-item">
                    <div
                      class={`nav-link${activeorder == 2 ? " active" : ""}`}
                      href="#"
                      onClick={() => {
                        setActiveOrder(2);
                      }}
                    >
                      Orders
                    </div>
                  </li>:null}
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* for Order Content */}
        {activeorder == 2 && isLoggedIn ? (
          <main className="container mt-5">
            <div className="row">
              <div className="col-lg-12 mb">
                <h5 className="mb-3 d-none d-lg-block">Orders</h5>
                <div className="p2p_filter justify-content-between">
                  <div className="col-lg-3">
                    <FormControl>
                      <label className="fs-14 text-lgray"> Coin</label>
                      <Select
                        labelId="dropdown-label"
                        id="dropdown"
                        value={selectedCoin}
                        onChange={handleCoin}
                        className="p2p_select_box order_width"
                        style={{ padding: "8px" }}
                      >
                         <MenuItem key="sd" className="select-option" value="all">
                          ALL
                        </MenuItem>
                        {p2pcoin}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-lg-3">
                    <FormControl>
                      <label className="fs-14 text-lgray"> Type</label>
                      <Select
                        labelId="dropdown-label"
                        id="dropdown"
                        value={selectedType}
                        onChange={handleType}
                        className="p2p_select_box order_width fs-12"
                        MenuProps={{
                          PaperProps: {
                            className: "custom-menu-paper",
                          },
                        }}
                      >
                        <MenuItem value="all">ALL</MenuItem>
                        <MenuItem value="buy">Buy</MenuItem>
                        <MenuItem value="sell">Sell</MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  <div className="col-lg-3">
                    <FormControl>
                      <label className="fs-14 text-lgray"> Status</label>
                      <Select
                        labelId="dropdown-label"
                        id="dropdown"
                        value={selectedStatus}
                        onChange={handleStatus}
                        className="p2p_select_box order_width"
                      >
                         <MenuItem className="select-option" value="all">
                          ALL
                        </MenuItem>
                        <MenuItem className="select-option" value="1">
                          Pending Payment
                        </MenuItem>
                        <MenuItem
                          className="select-option"
                          value="-1"
                        >
                          Pending Payment
                        </MenuItem>
                        <MenuItem
                          className="select-option"
                          value="-2"
                        >
                          In Progress
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  <div className="col-lg-3 cstm_form ">
                    <lable>Date</lable>
                    <div className="d-flex align-items-center date_bg">
                      <input type="Date" class="form-control" onChange={(e)=>{
                        setStartDate(e.target.value);
                      }} />
                      <span>
                        <AiOutlineSwapRight />
                      </span>
                      <input type="Date" class="form-control" onChange={(e)=>{
                        setEndDate(e.target.value);
                      }}/>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-12 mb-3">
                <div class="tab-content">
                  <div class="tab-pane active" id="buy_tab">
                    <div className="col-lg-12 col-md-12 col-sm-12 mt-3 table-responsive">
                      <table className="table global_table">
                        <thead>
                          <tr>
                            <th>Order No.</th>
                            <th>Fiat Amount</th>
                            <th>Price</th>
                            <th>Coin Amount</th>
                            <th>Executed</th>
                            <th>Transaction Fees</th>
                            <th>Status</th>
                            <th>Date</th>
                            <th></th>
                          </tr>
                        </thead>

                        <tbody>
                          {myorder.length>0 && myorder.map((item)=>{
                            let type = getOrderTypeFromOrderId(item?.order_id)
                            return (
                            <tr onClick={()=>{
                              if(item?.order_status==-1 || item?.order_status==-2 || item?.order_status==4 || item?.order_status==-4 ) {
                                setOrderId(item?.order_id);
                                getChats(item?.order_id);
                                getOrders(item?.requested_historyby)
                                setOrderDetails(item);
                                setTimer(getSecond(item?.initiate_time));
                                setActiveOrder(1)
                              }
                              
                            }}>
                            <td>
                              <span className="text-green h6">{type.toUpperCase()} {item?.currency_type?.toUpperCase()}</span>
                              <div className="d-flex gap-2 text-muted fs-12">
                              </div>
                            </td>
                            <td>
                              <span className="fw-semibold">{parseFloat(item?.volume)*parseFloat(item?.raw_price)} {item?.compare_currency?.toUpperCase()}</span>
                            </td>
                            <td>{parseFloat(item?.raw_price)} {item?.compare_currency?.toUpperCase()}</td>
                            <td>{parseFloat(item?.volume)} {item?.currency_type?.toUpperCase()}</td>
                            <td>{item?.total_executed?parseFloat(item?.total_executed):0}</td>
                            <td>{item?.fees} {item?.currency_type?.toUpperCase()}</td>
                            <td>{item?.order_status==0?<button
                              className="action-btn outline"
                              onClick={() => {
                                  N_p2pCancelOrder(
                                    user?.params ? user.params.token : user.token,
                                    item?.order_id
                                  ).then(()=>{
                                    N_p2pMyOrder(
                                      user?.params ? user.params.token : user.token,
                                      selectedType,
                                      selectedCoin,
                                      selectedStatus,
                                      start_date, 
                                      end_date
                                    ).then((res) => {
                                      if (res.status == 200) {
                                        setMyOrder(res.p2porder);
                                      }
                                    });
                                  })
                                
                              }}
                            >
                              Cancel Order
                            </button>:item?.order_status==1?"Success":item?.order_status==-1?"Pending":item?.order_status==2?"Cancel":item?.order_status==-2 && getOrderTypeFromOrderId(item?.order_id)=="Sell"?"Your order is confirm please release the fund":item?.order_status==-4 && getOrderTypeFromOrderId(item?.order_id)=="Buy"?"Please make your Payment":item?.order_status==-4 && getOrderTypeFromOrderId(item?.order_id)=="Sell"?"Order Initiated":item?.order_status==-3?"In Appeal":"In Progress"}</td>
                            <td>{new Date(item?.updatedAt).toLocaleString()}</td>
                            <td>
                                <IoChatboxEllipsesOutline />
                            </td>
                            </tr>)
                          })}
                         
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div class="tab-pane" id="sell_tab">
                    <h4 className="mb-4">
                      Payment Settings
                      <span className="text-lgray fs-12 ms-2">
                        (Maximum: 10)
                      </span>
                    </h4>

                    <div className="alert alert-warning">
                      <p className="text-dark mb-0">
                        Select atleast one payement method
                      </p>
                    </div>

                    <div className="text-center border-bottom-0">
                      <img src="/img/no-data.png" className="no-data-found" />
                      <p> Payement Method Not Found</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        ) : activeorder == 1 && isLoggedIn ? (

          // payment  and chat
          <main className="container mt-5">
            <div class="page_main_heading">
              {getOrderTypeFromOrderId(order_deatils?.order_id)} {order_deatils?.currency_type?.toUpperCase()}
            </div>
            {getOrderTypeFromOrderId(order_deatils?.order_id)=="Sell"?
            <div className="row spacer-sm">
              <div className="col-lg-8">
                <div className="jambotron-outline">
                  <div className="flex_row align-items-start">
                    <div className="d-flex">
                      <div>
                        <FaDotCircle className="text-theme-color me-2" />
                      </div>
                      <div>
                        <h5> Complete Your Payment Within:</h5>
                        <ul class="fs-12">
                          <li>
                            Please complete payment within the allowed
                            timeframe.
                          </li>
                          <li>
                            After making the payment using an payment method
                            outside of BITHAVEN, please click on the "I have
                            completed the payment" button below.
                          </li>
                          <li>
                            Note: The order will be automatically canceled if
                            the button is not clicked by the deadline.
                          </li>
                        </ul>
                      </div>
                    </div>
                    {formatTimer()}
                  </div>

                  <div class="order-detail-step mt-4">
                    <div class="d-flex gap-2 align-items-center active">
                      <div class="ant-space-item">
                        <div class="step-value">1</div>
                      </div>
                      <div class="ant-space-item">
                        <div class="step-label">Complete Your Payment</div>
                      </div>
                      <div class="ant-space-item">
                        <div class="step-dash-line"></div>
                      </div>
                    </div>
                    <div class="d-flex gap-2 align-items-center inactive">
                      <div class="ant-space-item">
                        <div class="step-value">2</div>
                      </div>
                      <div class="ant-space-item">
                        <div class="step-label">Coin Release in Progress</div>
                      </div>
                      <div class="ant-space-item">
                        <div class="step-dash-line"></div>
                      </div>
                    </div>
                    <div class="d-flex gap-2 align-items-center inactive">
                      <div class="ant-space-item">
                        <div class="step-value">3</div>
                      </div>
                      <div class="ant-space-item">
                        <div class="step-label">Transaction Completed</div>
                      </div>
                    </div>
                  </div>

                  <h5 className="hero_heading mt-4">
                    <FaDotCircle className="text-theme-color me-2" />
                    Order Info
                  </h5>
                  <div class="order-detail-step justify-content-between mt-2">
                    <div className="col-lg-4">
                      <h4 className="h6 text-lgray">Pay</h4>
                      <div className="text-green">
                        {parseFloat(maindata?.volume) *
                          parseFloat(maindata?.raw_price)}{" "}
                        {maindata?.compare_currency?.toUpperCase()}
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <h4 className="h6 text-lgray">Price</h4>
                      <div>
                        {maindata?.raw_price}{" "}
                        {maindata?.compare_currency?.toUpperCase()}
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <h4 className="h6 text-lgray">Total Quanity</h4>
                      <div>
                        {maindata?.volume}{" "}
                        {maindata?.currency_type?.toUpperCase()}
                      </div>
                    </div>
                  </div>

                  <h5 className="hero_heading mt-4">
                    <FaDotCircle className="text-theme-color me-2" /> Payment
                    Methods Accepted by the Seller
                  </h5>

                  <div>
                    <div class="alert alert-warning">
                      {/* <div>
                        1.
                        <span>
                          Please use{" "}
                          <span className="text-warning mx-1">
                            AMAN KUMAR PANDEY
                          </span>
                          when making payment.
                        </span>
                      </div> */}

                      <div class="by-space-item">
                        <div>
                          Make sure not to remark sensitive words such as
                          "BTC/USDT purchase" when transferring fiat, otherwise
                          the transfer may fail.
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div>{PaymentForm()}</div> */}


                  {order_deatils?.order_status==-2?
                   <div className="pay_footer">
                   <button
                     className="action-btn"
                     onClick={() => {
                      N_p2pOrderAction(
                         user?.params ? user.params.token : user.token,
                         order_deatils?.order_id,
                         "approve"
                       ).then(()=>{
                        N_getp2pOrder(user?.params ? user.params.token : user.token, order_deatils?.order_id, "order")
                        .then((res)=>{
                          if(res.status==200) {
                            setOrderDetails(res?.data);
                          }
                        })
                       })
                     }}
                   >
                     Release Fund
                   </button>
                   <button
                     className="action-btn outline"
                     onClick={() => {
                      N_p2pOrderAppeal(
                         user?.params ? user.params.token : user.token,
                         order_deatils?.order_id
                       ).then(()=>{
                        N_getp2pOrder(user?.params ? user.params.token : user.token, order_deatils?.order_id, "order")
                        .then((res)=>{
                          if(res.status==200) {
                            setOrderDetails(res?.data);
                            setActiveOrder(2)
                          }
                        })
                       })
                     }}
                   >
                     Appeal
                   </button>
                 </div>:null}
                 {order_deatils?.order_status==4?
                  <div className="pay_footer">
                    <button
                      className="action-btn"
                      onClick={() => {
                        N_p2pConfirmOrder(
                          user?.params ? user.params.token : user.token,
                          order_deatils?.requested_orderby,
                          "approve",
                          order_deatils?.order_id
                        ).then(()=>{
                          N_getp2pOrder(user?.params ? user.params.token : user.token, order_deatils?.order_id, "order")
                        .then((res)=>{
                          if(res.status==200) {
                            setOrderDetails(res?.data);
                          }
                        })
                        })
                      }}
                    >
                      initiate Order
                    </button>
                    <button
                      className="action-btn outline"
                      onClick={() => {
                        N_p2pCancelIntiateOrder(
                          user?.params ? user.params.token : user.token,
                          order_deatils?.order_id
                        ).then((res)=>{
                         setActiveOrder(2);
                        })
                      }}
                    >
                      Cancel Order
                    </button>
                  </div>:null}
                </div>

                <div className="jambotron-outline ">
                  <h2 class="para_heading mb-0"> FAQs </h2>
                  <div className="col-lg-12 mt-2">
                    <div
                      class="accordion accordion-flush"
                      id="accordionFlushExample"
                    >
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingOne">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne"
                            aria-expanded="false"
                            aria-controls="flush-collapseOne"
                          >
                            How should I make my payment?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseOne"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-headingOne"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body">
                            You'll need to manually transfer funds to the seller
                            via online banking or other payment methods as
                            specified by the seller. Please make sure to verify
                            the account information provided by the seller.
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingTwo">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseTwo"
                            aria-expanded="false"
                            aria-controls="flush-collapseTwo"
                          >
                            What potential risks and rejection reasons exist for
                            off-platform payments?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseTwo"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-headingTwo"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body">
                            Off-platform payment risks include potential
                            freezing of your bank card or digital wallet, and
                            transaction rejection by the counterparty if: 1. The
                            account holder's name for the payment method differs
                            from the name registered in BITHAVEN Identity
                            Verification. 2. Crypto-related sensitive words are
                            used in transfer remarks. 3. Payments made during
                            holidays or non-working hours cause delays.
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingThree">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseThree"
                            aria-expanded="false"
                            aria-controls="flush-collapseThree"
                          >
                            What should I do after completing the payment?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseThree"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-headingThree"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body">
                            Please make sure to complete the payment within 15
                            minutes, and click on the "Payment Completed" button
                            to confirm your payment. If you fail to click on the
                            button within the timeout period, your order will be
                            automatically canceled and your payment may not be
                            retrievable. Penalties may be imposed on users who
                            click on the confirmation button without making any
                            payment. We reserve the right to permanently ban
                            these users from the P2P Trading Platform on BITHAVEN.
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingFour">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-headingFour"
                            aria-expanded="false"
                            aria-controls="flush-headingFour"
                          >
                            What should I do if my payment fails via the method
                            provided by the seller?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseFive"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-collapseFive"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body">
                            Please contact the seller to check if any other
                            payment methods are available.
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingSix">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-headingSix"
                            aria-expanded="false"
                            aria-controls="flush-headingSix"
                          >
                            What should I do if my seller asks for additional
                            identity verification information or charges
                            additional fees?
                          </button>
                        </h2>
                        <div
                          id="flush-headingSix"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-headingThree"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body">
                            Please reach out to the seller for more information.
                            If you don't want to provide identity information or
                            pay additional fees, you may cancel the order.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* FOR CHAT */}
              <div className="col-lg-4">
                <div class="chat-wrapper">
                  <div class="d-flex align-items-center">
                    <div class="circle_backdrop_outline">{order_data?.user_id?.name?order_data?.user_id?.name?.charAt(0)?.toUpperCase():from_name?.charAt(0)?.toUpperCase()}</div>
                    <div class="product_name">
                      <div class="advertiser-name">
                        {order_data?.user_id?.name?order_data?.user_id?.name?.toUpperCase():from_name?.toUpperCase()}
                        <span className="ms-2"></span>
                      </div>

                      {/* <div class="text-muted fs-12">
                        <AiFillSafetyCertificate className="text-primary" />
                        Verified: NITISH R
                      </div> */}
                    </div>
                  </div>
                  <hr className="border-bottom" />
                  <div className="chat-screen">
                    <div className="messages-container custom_scrollbar">
                      <div className="alert-message">
                        <div className="message-date">{new Date().toLocaleString()}</div>
                        <div className="alert alert-secondary">
                          <div className="d-flex gap-2">
                            <div>
                              <TbSpeakerphone className="fs-5" />
                            </div>
                            <div>
                              You've successfully submitted an order. Please
                              proceed to complete your payment.
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="incoming-message">
                        <div className="message-date">{new Date().toLocaleString()}</div>
                        <div className="message incoming">
                          <div className="message-content">
                            # Strictly Third party payment not accepted. # Must
                            share ur mobile number and pay. #After payment send
                            screenshot. # If someone is guiding you to buy usdt
                            .Don't trade with me #Thank you. # Karnataka users
                            can contact me if u need more.
                          </div>
                        </div>
                      </div>

                      <div className="alert-message">
                        <div className="message-date">{new Date().toLocaleString()}</div>
                        <div className="alert alert-danger">
                          <div className="d-flex gap-2">
                            <div>
                              <BiError className="fs-5" />
                            </div>
                            <div>
                              Be careful not to be fooled by fake Customer
                              Support agents. Real Customer Support agents have
                              a blue checkmark next to their profile picture.
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="outgoing-message">
                        <div className="message-date">{new Date().toLocaleString()}</div>
                        <span className="message-sender">
                          {profile?.name} 
                        </span>
                        <div className="message outgoing">
                          <div className="message-content">
                            Hi, I have a query regarding P2P Buy/Sell
                          </div>
                        </div>
                      </div> */}
                      {messages?.length>0 && messages?.map((item)=>{
                        return (
                          <>
                          <div className={`${order_deatils?.order_id==item?.order_id?"outgoing-message":"incoming-message"}`}>
                        <div className="message-date">{new Date(item?.createdAt)?.toLocaleString()}</div>
                        <span className="message-sender">{order_deatils?.order_id==item?.order_id?profile?.name:from_name}</span>
                        <div className={`${order_deatils?.order_id==item?.order_id?"message outgoing":"message incoming"}`}>
                          <div className="message-content">
                            {item?.message}
                          </div>
                        </div>
                      </div>
                          </>
                        )
                      })}
                      
                    </div>
                    <div className="input-container">
                      <div className="input-group cstm_form mt-3">
                        <input
                          type="text"
                          value={inputText}
                          onChange={(e) => setInputText(e.target.value)}
                          placeholder="Type your message..."
                        />
                        <span className="input-group-text">
                          <div onClick={handleSendMessage}>
                            <MdSend className="fs-4" />
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>:<div className="row spacer-sm">
              <div className="col-lg-8">
                <div className="jambotron-outline">
                  <div className="flex_row align-items-start">
                    <div className="d-flex">
                      <div>
                        <FaDotCircle className="text-theme-color me-2" />
                      </div>
                      <div>
                        <h5> Complete Your Payment Within:</h5>
                        <ul class="fs-12">
                          <li>
                            Please complete payment within the allowed
                            timeframe.
                          </li>
                          <li>
                            After making the payment using an payment method
                            outside of BITHAVEN, please click on the "I have
                            completed the payment" button below.
                          </li>
                          <li>
                            Note: The order will be automatically canceled if
                            the button is not clicked by the deadline.
                          </li>
                        </ul>
                      </div>
                    </div>
                    {formatTimer()}
                  </div>

                  <div class="order-detail-step mt-4">
                    <div class="d-flex gap-2 align-items-center active">
                      <div class="ant-space-item">
                        <div class="step-value">1</div>
                      </div>
                      <div class="ant-space-item">
                        <div class="step-label">Complete Your Payment</div>
                      </div>
                      <div class="ant-space-item">
                        <div class="step-dash-line"></div>
                      </div>
                    </div>
                    <div class="d-flex gap-2 align-items-center inactive">
                      <div class="ant-space-item">
                        <div class="step-value">2</div>
                      </div>
                      <div class="ant-space-item">
                        <div class="step-label">Coin Release in Progress</div>
                      </div>
                      <div class="ant-space-item">
                        <div class="step-dash-line"></div>
                      </div>
                    </div>
                    <div class="d-flex gap-2 align-items-center inactive">
                      <div class="ant-space-item">
                        <div class="step-value">3</div>
                      </div>
                      <div class="ant-space-item">
                        <div class="step-label">Transaction Completed</div>
                      </div>
                    </div>
                  </div>

                  <h5 className="hero_heading mt-4">
                    <FaDotCircle className="text-theme-color me-2" />
                    Order Info
                  </h5>
                  <div class="order-detail-step justify-content-between mt-2">
                    <div className="col-lg-4">
                      <h4 className="h6 text-lgray">Pay</h4>
                      <div className="text-green">
                        {parseFloat(maindata?.volume) *
                          parseFloat(maindata?.raw_price)}{" "}
                        {maindata?.compare_currency?.toUpperCase()}
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <h4 className="h6 text-lgray">Price</h4>
                      <div>
                        {maindata?.raw_price}{" "}
                        {maindata?.compare_currency?.toUpperCase()}
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <h4 className="h6 text-lgray">Total Quanity</h4>
                      <div>
                        {maindata?.volume}{" "}
                        {maindata?.currency_type?.toUpperCase()}
                      </div>
                    </div>
                  </div>

                  <h5 className="hero_heading mt-4">
                    <FaDotCircle className="text-theme-color me-2" /> Payment
                    Methods Accepted by the Seller
                  </h5>

                  <div>
                    <div class="alert alert-warning">
                      {/* <div>
                        1.
                        <span>
                          Please use{" "}
                          <span className="text-warning mx-1">
                            AMAN KUMAR PANDEY
                          </span>
                          when making payment.
                        </span>
                      </div> */}

                      <div class="by-space-item">
                        <div>
                          Make sure not to remark sensitive words such as
                          "BTC/USDT purchase" when transferring fiat, otherwise
                          the transfer may fail.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>{PaymentForm()}</div>


                  {order_deatils?.order_status==-2?
                   <div className="pay_footer">
                    <button
                     className="action-btn outline"
                     onClick={() => {
                      N_p2pOrderAppeal(
                         user?.params ? user.params.token : user.token,
                         order_deatils?.order_id
                       ).then(()=>{
                        N_getp2pOrder(user?.params ? user.params.token : user.token, order_deatils?.order_id, "order")
                        .then((res)=>{
                          if(res.status==200) {
                            setOrderDetails(res?.data);
                            setActiveOrder(2)
                          }
                        })
                       })
                     }}
                   >
                     Appeal
                   </button>
                 </div>:null}
                 {(order_deatils?.order_status==-4 && getOrderTypeFromOrderId(order_deatils?.order_id)=="Buy")?
                   <div className="pay_footer">
                   <button
                     className="action-btn"
                     onClick={() => {
                      N_p2pOrderAction(
                         user?.params ? user.params.token : user.token,
                         order_deatils?.order_id,
                         "approve"
                       ).then(()=>{
                        N_getp2pOrder(user?.params ? user.params.token : user.token, order_deatils?.order_id, "order")
                        .then((res)=>{
                          if(res.status==200) {
                            setOrderDetails(res?.data);
                          }
                        })
                       })
                     }}
                   >
                     payment
                   </button>
                 </div>:null}
                 {order_deatils?.order_status==4?
                  <div className="pay_footer">
                    <button
                      className="action-btn"
                      onClick={() => {
                        N_p2pConfirmOrder(
                          user?.params ? user.params.token : user.token,
                          order_deatils?.requested_orderby,
                          "approve",
                          order_deatils?.order_id
                        ).then(()=>{
                          N_getp2pOrder(user?.params ? user.params.token : user.token, order_deatils?.order_id, "order")
                          .then((res)=>{
                            if(res.status==200) {
                              setOrderDetails(res?.data);
                            }
                          })
                        })
                      }}
                    >
                      Payment Completed
                    </button>
                    <button
                      className="action-btn outline"
                      onClick={() => {
                        N_p2pCancelIntiateOrder(
                          user?.params ? user.params.token : user.token,
                          order_deatils?.order_id
                        ).then((res)=>{
                          setActiveOrder(2)
                        })
                      }}
                    >
                      Cancel Order
                    </button>
                  </div>:null}
                </div>

                <div className="jambotron-outline ">
                  <h2 class="para_heading mb-0"> FAQs </h2>
                  <div className="col-lg-12 mt-2">
                    <div
                      class="accordion accordion-flush"
                      id="accordionFlushExample"
                    >
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingOne">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne"
                            aria-expanded="false"
                            aria-controls="flush-collapseOne"
                          >
                            How should I make my payment?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseOne"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-headingOne"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body">
                            You'll need to manually transfer funds to the seller
                            via online banking or other payment methods as
                            specified by the seller. Please make sure to verify
                            the account information provided by the seller.
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingTwo">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseTwo"
                            aria-expanded="false"
                            aria-controls="flush-collapseTwo"
                          >
                            What potential risks and rejection reasons exist for
                            off-platform payments?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseTwo"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-headingTwo"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body">
                            Off-platform payment risks include potential
                            freezing of your bank card or digital wallet, and
                            transaction rejection by the counterparty if: 1. The
                            account holder's name for the payment method differs
                            from the name registered in BITHAVEN Identity
                            Verification. 2. Crypto-related sensitive words are
                            used in transfer remarks. 3. Payments made during
                            holidays or non-working hours cause delays.
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingThree">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseThree"
                            aria-expanded="false"
                            aria-controls="flush-collapseThree"
                          >
                            What should I do after completing the payment?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseThree"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-headingThree"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body">
                            Please make sure to complete the payment within 15
                            minutes, and click on the "Payment Completed" button
                            to confirm your payment. If you fail to click on the
                            button within the timeout period, your order will be
                            automatically canceled and your payment may not be
                            retrievable. Penalties may be imposed on users who
                            click on the confirmation button without making any
                            payment. We reserve the right to permanently ban
                            these users from the P2P Trading Platform on BITHAVEN.
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingFour">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-headingFour"
                            aria-expanded="false"
                            aria-controls="flush-headingFour"
                          >
                            What should I do if my payment fails via the method
                            provided by the seller?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseFive"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-collapseFive"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body">
                            Please contact the seller to check if any other
                            payment methods are available.
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingSix">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-headingSix"
                            aria-expanded="false"
                            aria-controls="flush-headingSix"
                          >
                            What should I do if my seller asks for additional
                            identity verification information or charges
                            additional fees?
                          </button>
                        </h2>
                        <div
                          id="flush-headingSix"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-headingThree"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body">
                            Please reach out to the seller for more information.
                            If you don't want to provide identity information or
                            pay additional fees, you may cancel the order.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* FOR CHAT */}
              <div className="col-lg-4">
                <div class="chat-wrapper">
                  <div class="d-flex align-items-center">
                    <div class="circle_backdrop_outline">{order_data?.user_id?.name?order_data?.user_id?.name?.charAt(0)?.toUpperCase():from_name?.charAt(0)?.toUpperCase()}</div>
                    <div class="product_name">
                      <div class="advertiser-name">
                        {order_data?.user_id?.name?order_data?.user_id?.name?.toUpperCase():from_name?.toUpperCase()}
                        <span className="ms-2"></span>
                      </div>

                      {/* <div class="text-muted fs-12">
                        <AiFillSafetyCertificate className="text-primary" />
                        Verified: NITISH R
                      </div> */}
                    </div>
                  </div>
                  <hr className="border-bottom" />
                  <div className="chat-screen">
                    <div className="messages-container custom_scrollbar">
                      <div className="alert-message">
                        <div className="message-date">{new Date().toLocaleString()}</div>
                        <div className="alert alert-secondary">
                          <div className="d-flex gap-2">
                            <div>
                              <TbSpeakerphone className="fs-5" />
                            </div>
                            <div>
                              You've successfully submitted an order. Please
                              proceed to complete your payment.
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="incoming-message">
                        <div className="message-date">{new Date().toLocaleString()}</div>
                        <div className="message incoming">
                          <div className="message-content">
                            # Strictly Third party payment not accepted. # Must
                            share ur mobile number and pay. #After payment send
                            screenshot. # If someone is guiding you to buy usdt
                            .Don't trade with me #Thank you. # Karnataka users
                            can contact me if u need more.
                          </div>
                        </div>
                      </div>

                      <div className="alert-message">
                        <div className="message-date">{new Date().toLocaleString()}</div>
                        <div className="alert alert-danger">
                          <div className="d-flex gap-2">
                            <div>
                              <BiError className="fs-5" />
                            </div>
                            <div>
                              Be careful not to be fooled by fake Customer
                              Support agents. Real Customer Support agents have
                              a blue checkmark next to their profile picture.
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="outgoing-message">
                        <div className="message-date">{new Date().toLocaleString()}</div>
                        <span className="message-sender">
                          {profile?.name} 
                        </span>
                        <div className="message outgoing">
                          <div className="message-content">
                            Hi, I have a query regarding P2P Buy/Sell
                          </div>
                        </div>
                      </div> */}
                      {messages?.length>0 && messages?.map((item)=>{
                        return (
                          <>
                          <div className={`${order_id==item?.order_id?"outgoing-message":"incoming-message"}`}>
                        <div className="message-date">{new Date(item?.createdAt)?.toLocaleString()}</div>
                        <span className="message-sender">{order_id==item?.order_id?profile?.name:from_name}</span>
                        <div className={`${order_id==item?.order_id?"message outgoing":"message incoming"}`}>
                          <div className="message-content">
                            {item?.message}
                          </div>
                        </div>
                      </div>
                          </>
                        )
                      })}
                      
                    </div>
                    <div className="input-container">
                      <div className="input-group cstm_form mt-3">
                        <input
                          type="text"
                          value={inputText}
                          onChange={(e) => setInputText(e.target.value)}
                          placeholder="Type your message..."
                        />
                        <span className="input-group-text">
                          <div onClick={handleSendMessage}>
                            <MdSend className="fs-4" />
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>}
          </main>
        ) : (
          <main className="container mt-5">
            <div className="row">
              <div className="col-lg-12 mb-3 ">
                <h5 className="mb-3 d-none d-lg-block">P2P</h5>
                <div className="p2p_filter">
                  <div className="">
                    <div className="p2p-btn-box">
                      <div
                        className={`btn-switch-item ${
                          activeTab === "buy" ? "buyactive" : ""
                        }`}
                        onClick={() => handleTabChange("buy")}
                      >
                        Buy
                      </div>
                      <div
                        className={`btn-switch-item ${
                          activeTab === "sell" ? "sellactive" : ""
                        }`}
                        onClick={() => handleTabChange("sell")}
                      >
                        Sell
                      </div>
                    </div>
                  </div>

                  <div>
                    {/* <FormControl>
          <Select
            labelId="dropdown-label"
            id="dropdown"
            multiple
            value={selectedBankoptions}
            onChange={handleSelectChange}
            className="p2p_select_box large_width"
            renderValue={(selected) => (
              <div className="chips-container">
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={options.find((option) => option.value === value).label}
                    onDelete={() => setSelectedBankoptions((prevSelected) => prevSelected.filter((option) => option !== value))}
                  />
                ))}
              </div>
            )}
          >
           
            {payoptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <Checkbox checked={selectedBankoptions.includes(option.value)} />
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
                  </div>
                  {/* <div>
                <FormControl>
          <Select
            labelId="dropdown-label"
            id="dropdown"
            multiple
            value={selectedMultioptions}
            onChange={handleMultiOptions}
            className="p2p_select_box vlarge_width"
            renderValue={(selected) => (
              <div className="chips-container">
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={options.find((option) => option.value === value).label}
                    onDelete={handleChipDelete(value)}
                  />
                ))}
              </div>
            )}
          >
         
            {options.map((option) => (
              <MenuItem className="select-option" key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
                </div> */}

                  <div>
                    <FormControl>
                      <Select
                        labelId="dropdown-label"
                        id="dropdown"
                        value={selectedOption}
                        onChange={handleChange}
                        className="p2p_select_box"
                        style={{ padding: "8px" }} // Customize padding here
                      >
                        {p2pcoin}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="">
                    <div class="input-group filter_input_grp">
                      <input
                        type="text"
                        class="form-control py-4 fs-12"
                        placeholder="Enter Amount"
                        onChange={(e)=>{
                          SetSearchAmount(
                            e.target.value
                              .replace(/[^0-9.]/g, "")
                              .replace(/(\..*?)\..*/g, "$1")
                          )
                        }}
                      />
                    </div>
                  </div>

                  <div>
                    <div className="d-flex filter_form_bg align-items-center">
                      <div className="ps-2">
                        <IoReloadSharp />
                      </div>
                      <FormControl>
                        <Select
                          labelId="dropdown-label"
                          id="dropdown"
                          value={selectedOptionrefresh}
                          onChange={handleRefresh}
                          className="p2p_select_box large_width"
                        >
                          {/* <MenuItem className="select-option" value="">--Select Option--</MenuItem> */}
                          <MenuItem className="select-option" value="not">
                            Not now
                          </MenuItem>
                          <MenuItem className="select-option" value="5">
                            5s to refresh
                          </MenuItem>
                          <MenuItem className="select-option" value="10">
                            10s to refresh
                          </MenuItem>
                          <MenuItem className="select-option" value="20">
                            20s to refresh
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div>
                    <FormControl>
                      <Select
                        labelId="dropdown-label"
                        id="dropdown"
                        value={selectedOptionBank}
                        onChange={handleBank}
                        className="p2p_select_box vlarge_width"
                        MenuProps={{
                          PaperProps: {
                            className: "custom-menu-paper", // Apply custom class for menu paper
                          },
                        }}
                      >
                        <MenuItem className="select-option" value="all">
                          All Payment Method
                        </MenuItem>
                        <MenuItem className="select-option" value="upi">
                          UPI
                        </MenuItem>
                        <MenuItem className="select-option" value="phonepe">
                          PhonePe
                        </MenuItem>
                        <MenuItem className="select-option" value="bank">
                          Bank Transfer
                        </MenuItem>
                        <MenuItem className="select-option" value="paytm">
                          PayTm
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  <div className="">
                    <div>
                      <Button
                        aria-describedby={id}
                        variant="contained"
                        onClick={handleClick}
                        className="filter_popover" // Specify the background color here
                      >
                        Filter
                      </Button>
                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                      >
                        <div style={{ padding: "20px" }}>
                          <div class="mb-3">
                            <div class="form-check mb-2">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                id=""
                              />
                              <label class="form-check-label">
                                Show only Verified Advertisers
                              </label>
                            </div>
                            <div class="form-check mb-2">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                id=""
                              />
                              <label class="form-check-label">
                                Show only Eligible Ads
                              </label>
                            </div>
                          </div>
                          <section class="d-flex gap-3">
                            <div class="d-grid w-50">
                              <button class="btn btn-primary" type="button">
                                <span>Confirm</span>
                              </button>
                            </div>
                            <div class="d-grid w-50">
                              <button
                                className="btn btn-light"
                                type="button"
                                onClick={handleClose}
                              >
                                <span>Cancel</span>
                              </button>
                            </div>
                          </section>
                        </div>
                      </Popover>
                    </div>
                  </div>

                  {/* <div className="px-3">
                  <div
                    className="cursor_pointer"
                    data-bs-target="#ads_post_modal"
                    data-bs-toggle="modal"
                  >
                    
                    <BsSignpost2 />
                    <span className="fs-13"> Post New Ads</span>
                  </div>
                </div> */}
                </div>

                {activeTab === "buy" && (
                  <div>
                    <div className="col-lg-12 mb-3">
                      <div class="tab-content">
                        <div class="tab-pane active" id="buy_tab">
                          <div className="col-lg-12 col-md-12 col-sm-12 mt-3 table-responsive">
                            <table className="table global_table">
                              <thead>
                                <tr>
                                  <th>Advertiser</th>
                                  <th>Price</th>
                                  {/* <th>Available/Limits</th> */}
                                  <th>Available</th>
                                  <th>Payment Mathod</th>
                                  <th className="text-end">
                                    <span className="custom_badge bg-soft-success fs-12">
                                      Taker 0 Transaction Fee(s)
                                    </span>
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                {buyorder.length > 0 ? (
                                  buyorder.map((item, index) => (
                                    <tr key={index}>
                                      <td>
                                        <div className="d-flex align-items-center">
                                          <div className="circle_backdrop_outline">
                                            {item?.user_id?.name
                                              ?.charAt(0)
                                              ?.toUpperCase()}
                                          </div>
                                          <div className="product_name">
                                            <div className="advertiser-name">
                                              {item?.user_id?.name?.toUpperCase()}
                                              <span>
                                                <BiSolidCheckShield className="text-green" />
                                              </span>
                                            </div>
                                            {/* <div className="text-muted fs-12">252 Order(s)</div>
                                          <div className="text-muted fs-12">94.45%</div>
                                          <div className="text-muted fs-12">3 minutes ago</div> */}
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div>
                                          <span className="desc_card">
                                            {item?.raw_price}
                                          </span>
                                          <span className="text-muted ms-1">
                                            {item?.compare_currency.toUpperCase()}
                                          </span>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="d-flex gap-3">
                                          <div className="text-muted h6 width80">
                                            Available
                                          </div>
                                          <div className="h6">
                                            {parseFloat(item?.total_executed) >
                                            0
                                              ? parseFloat(item?.volume) -
                                                parseFloat(item?.total_executed)
                                              : parseFloat(item?.volume)}
                                            {item?.currency_type?.toUpperCase()}
                                          </div>
                                        </div>
                                        {/* <div className="d-flex gap-3">
                                        <div className="text-muted h6 width80">Limit</div>
                                        <div className="h6">1 ~ 2 INR</div>
                                      </div> */}
                                      </td>
                                      <td>
                                        <div className="badge gray-badge">
                                          {item?.payment_method?.toUpperCase()}
                                        </div>
                                      </td>
                                      <td className="text-end">
                                        <div>
                                          {isLoggedIn?
                                          <button
                                            data-bs-toggle="modal"
                                            data-bs-target="#buyModalbox"
                                            className="btn btn-success"
                                            onClick={() => {
                                              setBuyData(item);
                                            }}
                                          >
                                            Buy
                                            {item?.currency_type?.toUpperCase()}
                                          </button>:
                                          <button
                                          className="btn btn-success"
                                          onClick={() => {
                                            props.history?.replace("/login");
                                          }}
                                        >
                                          Buy
                                          {item?.currency_type?.toUpperCase()}
                                        </button>
                                          }
                                        </div>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan="5" className="text-center">
                                      No records found
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div class="tab-pane" id="sell_tab">
                          <h4 className="mb-4">
                            Payment Settings
                            <span className="text-lgray fs-12 ms-2">
                              (Maximum: 10)
                            </span>
                          </h4>
                          <div className="alert alert-warning">
                            <p className="text-dark mb-0">
                              Select atleast one payement method
                            </p>
                          </div>

                          <div className="text-center border-bottom-0">
                            <img
                              src="/img/no-data.png"
                              className="no-data-found"
                            />
                            <p> Payement Method Not Found</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {activeTab === "sell" && (
                  <div>
                    <div className="col-lg-12 mb-3">
                      <div class="tab-content">
                        <div class="tab-pane active" id="buy_tab">
                          <div className="col-lg-12 col-md-12 col-sm-12 mt-3 table-responsive">
                            <table className="table global_table">
                              <thead>
                                <tr>
                                  <th>Advertiser</th>
                                  <th>Price</th>
                                  <th>Available</th>
                                  <th>Payment Mathod</th>
                                  <th className="text-end">
                                    <span className="custom_badge bg-soft-success fs-12">
                                      Taker 0 Transaction Fee(s)
                                    </span>
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                {sellorder.length > 0 ? (
                                  sellorder.map((item, index) => (
                                    <tr key={index}>
                                      <td>
                                        <div className="d-flex align-items-center">
                                          <div className="circle_backdrop_outline">
                                            {item?.user_id?.name
                                              ?.charAt(0)
                                              ?.toUpperCase()}
                                          </div>
                                          <div className="product_name">
                                            <div className="advertiser-name">
                                              {item?.user_id?.name?.toUpperCase()}
                                              <span>
                                                <BiSolidCheckShield className="text-green" />
                                              </span>
                                            </div>
                                            {/* <div className="text-muted fs-12">252 Order(s)</div>
                                          <div className="text-muted fs-12">94.45%</div>
                                          <div className="text-muted fs-12">3 minutes ago</div> */}
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div>
                                          <span className="desc_card">
                                            {item?.raw_price}
                                          </span>
                                          <span className="text-muted ms-1">
                                            {item?.compare_currency.toUpperCase()}
                                          </span>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="d-flex gap-3">
                                          <div className="text-muted h6 width80">
                                            Available
                                          </div>
                                          <div className="h6">
                                            {parseFloat(item?.total_executed) >
                                            0
                                              ? parseFloat(item?.volume) -
                                                parseFloat(item?.total_executed)
                                              : parseFloat(item?.volume)}
                                            {item?.currency_type?.toUpperCase()}
                                          </div>
                                        </div>
                                        {/* <div className="d-flex gap-3">
                                        <div className="text-muted h6 width80">Limit</div>
                                        <div className="h6">1 ~ 2 INR</div>
                                      </div> */}
                                      </td>
                                      <td>
                                        <div className="badge gray-badge">
                                          {item?.payment_method?.toUpperCase()}
                                        </div>
                                      </td>
                                      <td className="text-end">
                                        <div>
                                          {isLoggedIn?
                                          <button
                                            data-bs-toggle="modal"
                                            data-bs-target="#sellModalbox"
                                            className="btn btn-danger"
                                            onClick={() => {
                                              setSellData(item);
                                            }}
                                          >
                                            Sell
                                            {item?.currency_type?.toUpperCase()}
                                          </button>:
                                          <button
                                          className="btn btn-danger"
                                          onClick={() => {
                                            props.history?.replace("/login")
                                          }}
                                        >
                                          Sell
                                          {item?.currency_type?.toUpperCase()}
                                        </button>}
                                        </div>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan="5" className="text-center">
                                      No records found
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div class="tab-pane" id="sell_tab">
                          <h4 className="mb-4">
                            Payment Settings
                            <span className="text-lgray fs-12 ms-2">
                              (Maximum: 10)
                            </span>
                          </h4>
                          <div className="alert alert-warning">
                            <p className="text-dark mb-0">
                              Select atleast one payement method
                            </p>
                          </div>

                          <div className="text-center border-bottom-0">
                            <img
                              src="/img/no-data.png"
                              className="no-data-found"
                            />
                            <p> Payement Method Not Found</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </main>
        )}
      </div>

      <div
        class="modal fade"
        id="buyModalbox"
        tabindex="-1"
        aria-labelledby="buyModalboxLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body p-0" style={{ minHeight: "250px" }}>
              <div className="flex-container">
                <div className="p-5 modal-left">
                  <div class="d-flex align-items-center">
                    <div class="circle_backdrop_outline">
                      {buyData?.user_id?.name?.charAt(0)?.toUpperCase()}
                    </div>
                    <div class="product_name">
                      <div class=" advertiser-name">
                        {buyData?.user_id?.name?.toUpperCase()}
                        <span className="ms-2">
                          <AiFillSafetyCertificate className="text-primary" />
                        </span>
                      </div>
                      {/* <div class="text-muted fs-12">
                        252 Order(s)<div class="verticle_line"></div>94.45%
                      </div>
                      <div class="text-muted fs-12">3 minutes ago</div> */}
                    </div>
                  </div>
                  <div class="some-info-tag">
                    <span>
                      <FaCheckCircle /> Email
                    </span>
                    <span>
                      <FaCheckCircle /> SMS
                    </span>
                    <span>
                      <FaCheckCircle /> Identity Verification
                    </span>
                  </div>
                  <div class="advertiser-crypto-info">
                    <div class="crypto-info-item">
                      <span class="crypto-info-item-title">Price</span>
                      <span class="crypto-info-item-value sell">
                        {buyData?.raw_price}
                        {buyData?.compare_currency?.toUpperCase()}
                      </span>
                    </div>
                    <div class="crypto-info-item">
                      <span class="crypto-info-item-title">Available</span>
                      <span class="crypto-info-item-value">
                        {parseFloat(buyData?.total_executed) > 0
                          ? parseFloat(buyData?.volume) -
                            parseFloat(buyData?.total_executed)
                          : parseFloat(buyData?.volume)}
                        {buyData?.currency_type?.toUpperCase()}
                      </span>
                    </div>
                    {/* <div class="crypto-info-item">
                      <span class="crypto-info-item-title">Limits</span>
                      <span class="crypto-info-item-value">
                        5,000.00 ~ 57,142.00 INR
                      </span>
                    </div> */}
                    <div class="crypto-info-item">
                      <span class="crypto-info-item-title">
                        Payment Duration
                      </span>
                      <span class="crypto-info-item-value">30 Minute(s)</span>
                    </div>
                    {/* <div className="fs-12 mt-4">Notes:</div>

                    <div className="notes-wrapper custom_scrollbar">
                      <small className="fs-12 text-lgray">
                        Contact me for selling usd at T..Ê..L̥..É..Ġ..Á..Ṁ.
                        @hinoki76 Lorem ipsum dolor, sit amet consectetur
                        adipisicing elit. Nesciunt beatae pariatur, a, quam ea
                        cum repellat, labore culpa laudantium in commodi velit
                      </small>
                    </div> */}
                  </div>
                </div>
                <div className="p-5 modal-right">

                  <div className="amount-input-wrapper">
                    <label for="user_email" class="text-lgray fs-12">
                      I will recieve
                    </label>
                    <div class="input-group mb-3 p2p_input_grp">
                      <span class="input-group-text bg-transparent p-0">
                        <img src={data?.icon} style={{ width: "20px" }} />
                      </span>

                      <input
                        type="text"
                        class="form-control"
                        placeholder="0"
                        value={buyamountusdt}
                        onChange={(e) => {
                          setBuyAmountUsdt(
                            e.target.value
                              .replace(/[^0-9.]/g, "")
                              .replace(/(\..*?)\..*/g, "$1")
                          );
                          setBuyAmountINR(
                            e.target.value
                              .replace(/[^0-9.]/g, "")
                              .replace(/(\..*?)\..*/g, "$1") *
                              parseFloat(buyData?.raw_price)
                          );
                        }}
                      />
                      <span class="input-group-text bg-transparent">
                        {data?.symbol}
                      </span>
                    </div>
                  </div>
                  <small className="text-muted">
                    Available : {data?.balance - data?.locked} {data?.symbol}
                  </small>

                  <div className="amount-input-wrapper mt-4">
                    <label for="user_email" class="text-lgray fs-12">
                      I will sell
                    </label>
                    <div class="input-group mb-3 p2p_input_grp">
                      <span class="input-group-text bg-transparent p-0">
                        <img
                          src="https://bithaven.exchange/rupee.png"
                          style={{ width: "20px" }}
                        />
                      </span>

                      <input
                        type="text"
                        class="form-control"
                        placeholder="0"
                        value={buyamountinr}
                        onChange={(e) => {
                          setBuyAmountINR(
                            e.target.value
                              .replace(/[^0-9.]/g, "")
                              .replace(/(\..*?)\..*/g, "$1")
                          );
                          setBuyAmountUsdt(
                            e.target.value
                              .replace(/[^0-9.]/g, "")
                              .replace(/(\..*?)\..*/g, "$1") /
                              parseFloat(buyData?.raw_price)
                          );
                        }}
                      />
                      <span class="input-group-text bg-transparent">INR</span>
                    </div>
                  </div>
                  {/* <small className="text-muted">
                    Available for Sale: 0.0000 USDT
                  </small> */}

                  {/* <div>
                    <div class="mt-4">
                      <div class="h6">Payment Method</div>

                      <div class="d-grid">
                        <button class="btn btn-light" type="button">
                          <span>Add A Payment Method</span>
                        </button>
                      </div>
                    </div>
                  </div> */}
                      <f2 className="text-danger">
                        {buy_}
                      </f2>
                  <div class="d-flex gap-3 mt-4">
                    <div className="d-grid w-50">
                    <div id="buy_" data-bs-dismiss="modal"></div>
                      <button
                        type="button"
                        class="btn btn-success"
                        disabled=""
                        onClick={() => {
                          if (buyamountusdt > 0) {
                            N_p2pInitiateOrder(
                              user?.params ? user?.params?.token : user?.token,
                              buyData?.order_id,
                              buyamountusdt
                            ).then((res) => {
                              if (res?.status == 200) {
                                getChats(res?.data?.order_id);
                                setOrderId(res?.data?.order_id);
                                setOrderData(buyData);
                                setMainData(res?.data);
                                setOrderDetails(res?.data);
                                setTimer(getSecond(res?.data?.initiate_time));
                                setActiveOrder(1);
                                document.getElementById("buy_").click();
                              } else {
                                setBuy_message(res?.message)
                                setTimeout(()=>{
                                  setBuy_message(null)
                                }, 1000)

                              }
                            });
                          }
                        }}
                      >
                        <span>Buy</span>
                      </button>
                    </div>
                    <div className="d-grid w-50">
                      <button
                        type="button"
                        class="btn btn-outline-secondary"
                        data-bs-dismiss="modal"
                        onClick={()=>{
                          setBuyAmountUsdt(0)
                          setBuyAmountINR(0)
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" class="btn btn-primary">
                Understood
              </button>
            </div> */}
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="sellModalbox"
        tabindex="-1"
        aria-labelledby="sellModalboxLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body p-0" style={{ minHeight: "250px" }}>
              <div className="flex-container">
                <div className="p-5 modal-left">
                  <div class="d-flex align-items-center">
                    <div class="circle_backdrop_outline">
                      {sellData?.user_id?.name?.charAt(0)?.toUpperCase()}
                    </div>
                    <div class="product_name">
                      <div class=" advertiser-name">
                        {sellData?.user_id?.name?.toUpperCase()}
                        <span className="ms-2">
                          <AiFillSafetyCertificate className="text-primary" />
                        </span>
                      </div>
                      {/* <div class="text-muted fs-12">
                        252 Order(s)<div class="verticle_line"></div>94.45%
                      </div>
                      <div class="text-muted fs-12">3 minutes ago</div> */}
                    </div>
                  </div>
                  <div class="some-info-tag">
                    <span>
                      <FaCheckCircle /> Email
                    </span>
                    <span>
                      <FaCheckCircle /> SMS
                    </span>
                    <span>
                      <FaCheckCircle /> Identity Verification
                    </span>
                  </div>
                  <div class="advertiser-crypto-info">
                    <div class="crypto-info-item">
                      <span class="crypto-info-item-title">Price</span>
                      <span class="crypto-info-item-value sell">
                        {sellData?.raw_price}
                        {sellData?.compare_currency?.toUpperCase()}
                      </span>
                    </div>
                    <div class="crypto-info-item">
                      <span class="crypto-info-item-title">Available</span>
                      <span class="crypto-info-item-value">
                        {parseFloat(sellData?.total_executed) > 0
                          ? parseFloat(sellData?.volume) -
                            parseFloat(sellData?.total_executed)
                          : parseFloat(sellData?.volume)}
                        {sellData?.currency_type?.toUpperCase()}
                      </span>
                    </div>
                    {/* <div class="crypto-info-item">
                      <span class="crypto-info-item-title">Limits</span>
                      <span class="crypto-info-item-value">
                        5,000.00 ~ 57,142.00 INR
                      </span>
                    </div> */}
                    <div class="crypto-info-item">
                      <span class="crypto-info-item-title">
                        Payment Duration
                      </span>
                      <span class="crypto-info-item-value">30 Minute(s)</span>
                    </div>

                    {/* <div className="notes-wrapper custom_scrollbar">
                      <div className="fs-12 mt-4">Notes:</div>
                      <small className="fs-12 text-lgray">
                        Contact me for selling usd at T..Ê..L̥..É..Ġ..Á..Ṁ.
                        @hinoki76
                      </small>
                    </div> */}
                  </div>
                </div>
                <div className="p-5 modal-right">
                  <div className="amount-input-wrapper">
                    <label for="user_email" class="text-lgray fs-12">
                      I will sell
                    </label>
                    <div class="input-group mb-3 p2p_input_grp">
                      <span class="input-group-text bg-transparent p-0">
                        <img src={data?.icon} style={{ width: "20px" }} />
                      </span>

                      <input
                        type="text"
                        class="form-control"
                        placeholder="0"
                        value={sellamountusdt}
                        onChange={(e) => {
                          setSellAmountUsdt(
                            e.target.value
                              .replace(/[^0-9.]/g, "")
                              .replace(/(\..*?)\..*/g, "$1")
                          );
                          setSellAmountINR(
                            e.target.value
                              .replace(/[^0-9.]/g, "")
                              .replace(/(\..*?)\..*/g, "$1") *
                              parseFloat(sellData?.raw_price)
                          );
                        }}
                      />
                      <span class="input-group-text bg-transparent">
                        {data?.symbol}
                      </span>
                    </div>
                  </div>
                  <small className="text-muted">
                    Available : {data?.balance - data?.locked} {data?.symbol}
                  </small>

                  <div className="amount-input-wrapper mt-4">
                    <label for="user_email" class="text-lgray fs-12">
                      I will recieve
                    </label>
                    <div class="input-group mb-3 p2p_input_grp">
                      <span class="input-group-text bg-transparent p-0">
                        <img
                          src="https://bithaven.exchange/rupee.png"
                          style={{ width: "20px" }}
                        />
                      </span>

                      <input
                        type="text"
                        class="form-control"
                        placeholder="0"
                        value={sellamountinr}
                        onChange={(e) => {
                          setSellAmountINR(
                            e.target.value
                              .replace(/[^0-9.]/g, "")
                              .replace(/(\..*?)\..*/g, "$1")
                          );
                          setSellAmountUsdt(
                            e.target.value
                              .replace(/[^0-9.]/g, "")
                              .replace(/(\..*?)\..*/g, "$1") /
                              parseFloat(sellData?.raw_price)
                          );
                        }}
                      />
                      <span class="input-group-text bg-transparent">INR</span>
                    </div>
                  </div>
                  {/* <small className="text-muted">
                    Available for Sale: 0.0000 USDT
                  </small> */}

                  <div className="d-flex gap-4 cstm_form">
                    <div className="flex-grow-1 mt-4">
                      <div class="h6">Payment Method</div>

                      <div>
                        <select
                          class="form-select"
                          onChange={(e) => {
                            setPaymentMenthod(e.target.value);
                          }}
                        >
                          <option selected disabled>
                            Select any
                          </option>
                          {payment_method_data.length > 0 &&
                            payment_method_data?.map((item) => (
                              <option value={item?.type}>
                                {item?.type?.toUpperCase()}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <f2 className="text-danger">
                        {sell_}
                      </f2>
                  <div class="d-flex gap-3 mt-4">
                    <div className="d-grid w-50">
                      <div id="sell_" data-bs-dismiss="modal"></div>
                      <button
                        type="button"
                        class="btn btn-danger"
                        disabled=""
                        onClick={() => {
                          if (sellamountusdt > 0) {
                            N_p2pInitiateOrder(
                              user?.params ? user?.params?.token : user?.token,
                              sellData?.order_id,
                              sellamountusdt
                            ).then((res) => {
                              if (res?.status == 200) {
                                getChats(res?.data?.order_id);
                                setOrderId(res?.data?.order_id);
                                setOrderData(sellData);
                                setMainData(res?.data);
                                setOrderDetails(res?.data);
                                setTimer(getSecond(res?.data?.initiate_time));
                                setActiveOrder(1);
                                document.getElementById("sell_").click();
                              } else {
                                setSell_message(res?.message)
                                setTimeout(()=>{
                                  setSell_message(null)
                                }, 1000)
                              }
                            });
                          }
                        }}
                      >
                        <span>Sell</span>
                      </button>
                    </div>
                    <div className="d-grid w-50">
                      <button
                        type="button"
                        class="btn btn-outline-secondary"
                        data-bs-dismiss="modal"
                        onClick={()=>{
                          setSellAmountUsdt(0)
                          setSellAmountINR(0)
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" class="btn btn-primary">
                Understood
              </button>
            </div> */}
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="ineligibleModal"
        tabindex="-1"
        aria-labelledby="ineligibleModal"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header border-bottom-0">
              <h5 class="modal-title" id="ineligibleModal"></h5>
              <button
                type="button"
                class="btn-close fs-12"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body p-5 text-center mb-3">
              <BsShieldExclamation className="h1" />
              <p>
                Your 30-day completed orders are less than 40, please take a
                look at other Ads.
              </p>
            </div>
            <div className="d-grid px-5">
              <button type="button" class="btn btn-light py-2 rounded-pill">
                Select another Ad
              </button>
            </div>
            <div class="modal-footer-- mx-3 mb-3  border-top-0"></div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="ads_post_modal"
        tabindex="-1"
        aria-labelledby="ads_post_modal"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body p-0" style={{ minHeight: "250px" }}>
              <div className="flex-container">
                <div className="p-5 modal-right">
                  <div className="tab-container">
                    <div className="ads-btn-box">
                      <button
                        className={`actionbtn-item ${
                          activeTabBuySell === 1 ? "buyactive" : ""
                        }`}
                        onClick={() => handleTabClick(1)}
                      >
                        Buy
                      </button>
                      <button
                        className={`actionbtn-item ${
                          activeTabBuySell === 2 ? "sellactive" : ""
                        }`}
                        onClick={() => handleTabClick(2)}
                      >
                        Sell
                      </button>
                    </div>
                  </div>
                  <div className="tab-content">
                    <>
                      <div className="amount-input-wrapper">
                        <label for="user_email" class="text-lgray fs-12">
                          I will {activeTabBuySell == 2 ? "sell" : "recive"}
                        </label>
                        <div class="input-group p2p_input_grp">
                          <span class="input-group-text bg-transparent p-0">
                            <img src={data?.icon} style={{ width: "20px" }} />
                          </span>

                          <input
                            type="text"
                            class="form-control"
                            placeholder="0"
                            value={amountusdt}
                            onChange={(e) => {
                              setAmountUSDT(
                                e.target.value
                                  .replace(/[^0-9.]/g, "")
                                  .replace(/(\..*?)\..*/g, "$1")
                              );
                              setAmonutINR(
                                e.target.value
                                  .replace(/[^0-9.]/g, "")
                                  .replace(/(\..*?)\..*/g, "$1") * price
                              );
                            }}
                          />
                          <span class="input-group-text bg-transparent">
                            {data?.symbol}
                          </span>
                        </div>
                      </div>
                      <div className="text-muted d-flex gap-2">
                        <div className="fs-12">
                          Available: {data?.balance - data?.locked}
                          {data?.symbol}
                        </div>
                        <div>
                          <HtmlTooltip title="The maximum quantity of cryptocurrency available for sale.">
                            <div className="tooltip_content mb-2">
                              <AiOutlineQuestionCircle />
                            </div>
                          </HtmlTooltip>
                        </div>
                      </div>
                      <div className="amount-input-wrapper mt-4">
                        <label for="user_email" class="text-lgray fs-12">
                          I will {activeTabBuySell == 2 ? "recive" : "sell"}
                        </label>
                        <div class="input-group p2p_input_grp">
                          <span class="input-group-text bg-transparent p-0">
                            <img
                              src="https://bithaven.exchange/rupee.png"
                              style={{ width: "20px" }}
                            />
                          </span>

                          <input
                            type="text"
                            class="form-control"
                            placeholder="0"
                            value={amountinr}
                            onChange={(e) => {
                              setAmonutINR(
                                e.target.value
                                  .replace(/[^0-9.]/g, "")
                                  .replace(/(\..*?)\..*/g, "$1")
                              );
                              setAmountUSDT(
                                e.target.value
                                  .replace(/[^0-9.]/g, "")
                                  .replace(/(\..*?)\..*/g, "$1") / price
                              );
                            }}
                          />
                          <span class="input-group-text bg-transparent">
                            INR
                          </span>
                        </div>
                      </div>
                      <div className="amount-input-wrapper mt-4">
                        <label for="user_email" class="text-lgray fs-12">
                          price
                        </label>
                        <div class="input-group p2p_input_grp">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="0"
                            value={price}
                            onChange={(e) => {
                              setPrice(
                                e.target.value
                                  .replace(/[^0-9.]/g, "")
                                  .replace(/(\..*?)\..*/g, "$1")
                              );
                              setAmonutINR(
                                e.target.value
                                  .replace(/[^0-9.]/g, "")
                                  .replace(/(\..*?)\..*/g, "$1")
                              );
                              setAmountUSDT(
                                e.target.value
                                  .replace(/[^0-9.]/g, "")
                                  .replace(/(\..*?)\..*/g, "$1") /
                                  parseFloat(price)
                              );
                            }}
                          />
                          <span class="input-group-text bg-transparent">
                            INR
                          </span>
                        </div>
                      </div>
                      <small className="text-muted">price: {price} INR</small>

                      <div className="d-flex gap-4">
                        <div className="flex-grow-1 mt-4">
                          <div class="h6">Payment Method</div>

                          <div>
                            <select
                              class="form-select"
                              onChange={(e) => {
                                setPaymentMenthod(e.target.value);
                              }}
                            >
                              <option selected disabled>
                                Select any
                              </option>
                              {payment_method_data.length > 0 &&
                                payment_method_data?.map((item) => (
                                  <option value={item?.type}>
                                    {item?.type?.toUpperCase()}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </>

                    {/* {activeTabBuySell === 2 && (
                      <>
                        <div className="amount-input-wrapper">
                          <label for="user_email" class="text-lgray fs-12">
                            I will sell
                          </label>
                          <div class="input-group p2p_input_grp">
                            <span class="input-group-text bg-transparent p-0">
                              <img
                                src="https://www.bybit.com/bycsi-root/assets/image/coins/light/usdt.svg"
                                style={{ width: "20px" }}
                              />
                            </span>

                            <input
                              type="text"
                              class="form-control"
                              placeholder="0"
                            />
                            <span class="input-group-text bg-transparent">
                              USDT
                            </span>
                          </div>
                        </div>
                        <div className="text-muted d-flex gap-2">
                          <div className="fs-12">
                            
                            Available for Sale: 0.0000 USDT
                          </div>
                          <div>
                            <HtmlTooltip title="The maximum quantity of cryptocurrency available for sale.">
                              <div className="tooltip_content mb-2">
                                
                                <AiOutlineQuestionCircle />
                              </div>
                            </HtmlTooltip>
                          </div>
                        </div>
                        <div className="amount-input-wrapper mt-4">
                          <label for="user_email" class="text-lgray fs-12">
                            I will recieve
                          </label>
                          <div class="input-group p2p_input_grp">
                            <span class="input-group-text bg-transparent p-0">
                              <img
                                src="https://dtbx.exchange/rupee.png"
                                style={{ width: "20px" }}
                              />
                            </span>

                            <input
                              type="text"
                              class="form-control"
                              placeholder="0"
                            />
                            <span class="input-group-text bg-transparent">
                              INR
                            </span>
                          </div>
                        </div>
                        <small className="text-muted">
                          Available for Sale: 0.0000 USDT
                        </small>

                        <div className="d-flex gap-4">
                          <div className="flex-grow-1 mt-4">
                            <div class="h6">Payment Method</div>

                            <div>
                              <select class="form-select">
                                <option selected>Select any</option>
                                <option value="1">UPI</option>
                                <option value="2">Google Pay</option>
                                <option value="3">PhonePe</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </>
                    )} */}
                  </div>
                </div>
                <div className="p-5 modal-left">
                  <div className="d-flex gap-2 justify-content-between">
                    <h5 className="mb-3"> Ad to Be posted</h5>
                    <div>
                      <HtmlTooltip title="This is Preview">
                        <div className="tooltip_content mb-2">
                          <FaInfoCircle className="text-theme-color fs-3" />
                        </div>
                      </HtmlTooltip>
                    </div>
                  </div>

                  <h5 className="hero_heading text-success">
                    Buy {data?.symbol}
                  </h5>

                  <div class="advertiser-crypto-info">
                    <div class="crypto-info-item">
                      <span class="crypto-info-item-title">Price</span>
                      <span class="crypto-info-item-value sell">
                        {price} INR
                      </span>
                    </div>
                    <div class="crypto-info-item">
                      <span class="crypto-info-item-title">Total Quantity</span>
                      <span class="crypto-info-item-value">
                        {amountusdt} {data?.symbol}
                      </span>
                    </div>
                    {/* <div class="crypto-info-item">
                      <span class="crypto-info-item-title">Limits</span>
                      <span class="crypto-info-item-value">
                        5,000.00 ~ 57,142.00 INR
                      </span>
                    </div> */}
                    <div class="crypto-info-item">
                      <span class="crypto-info-item-title">Payment Method</span>
                      <span class="crypto-info-item-value">
                        {payment_method ? payment_method?.toUpperCase() : "NA"}
                      </span>
                    </div>
                    {/* <div class="crypto-info-item">
                      <span class="crypto-info-item-title">Duration</span>
                      <span class="crypto-info-item-value">5 Mins</span>
                    </div> */}
                    <f2 className="text-danger">
                        {post_}
                      </f2>
                    <div className="d-grid mt-4">
                      <div id="post_" data-bs-dismiss="modal"></div>
                      <button
                        type="button"
                        class="btn btn-primary"
                        disabled=""
                        
                        onClick={() => {
                          let type = activeTabBuySell == 1 ? "buy" : "sell";
                          if (price > 0 && amountusdt > 0 && payment_method) {
                            N_createp2pSellOrder(
                              user?.params ? user.params.token : user.token,
                              price,
                              amountusdt,
                              data?.symbol,
                              "INR",
                              type,
                              payment_method
                            ).then((res) => {
                              if (res.status == 200) {
                               setActiveOrder(2);
                               document.getElementById("post_").click()
                              } else {
                                setPost_message(res.message);
                                setTimeout(()=>{
                                  setPost_message(null)
                                }, 1000)
                              }
                            });
                          }
                        }}
                      >
                        <span>Post Ad</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" class="btn btn-primary">
                Understood
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
