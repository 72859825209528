import React from "react";

export default function FaqPage() {
  return (
    <div className="spacer">
      <div className="container">
        <div className="row">
          <div className="col-lg order-1 order-lg-1 d-flex flex-column align-items-lg-center justify-content-lg-center">
            <div className="container">
              <h2 className="fw-bold">Frequently Asked Questions</h2>
              <h3 className="text-left fs-5">Welcome to the Bithevan Exchange Support FAQ! Below, you’ll find answers to the most frequently asked questions. If you need further assistance, feel free to contact our support team.</h3>

              <div className="mx-auto col-xl-12 col-lg-12 my-5">
                <div class="nav nav-tabs my-3" id="nav-tab" role="tablist">
                  <button
                    class="nav-link active"
                    id="nav-tab1-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-tab1"
                    type="button"
                    role="tab"
                    aria-controls="#nav-tab1"
                    aria-selected="true"
                  >
                    Account Setup & Login
                  </button>
                  <button
                    class="nav-link"
                    id="nav-tab2-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-tab2"
                    type="button"
                    role="tab"
                    aria-controls="#nav-tab2"
                    aria-selected="false"
                  >
                    Security
                  </button>
                  <button
                    class="nav-link"
                    id="nav-tab3-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-tab3"
                    type="button"
                    role="tab"
                    aria-controls="#nav-tab3"
                    aria-selected="false"
                  >
                    Deposits & Withdrawals
                  </button>
                  <button
                    class="nav-link"
                    id="nav-tab4-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-tab4"
                    type="button"
                    role="tab"
                    aria-controls="#nav-tab4"
                    aria-selected="false"
                  >
                    Trading
                  </button> 
                  <button
                    class="nav-link"
                    id="nav-tab5-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-tab5"
                    type="button"
                    role="tab"
                    aria-controls="#nav-tab5"
                    aria-selected="false"
                  >
                     KYC (Know Your Customer)
                  </button>
                  <button
                    class="nav-link"
                    id="nav-tab6-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-tab6"
                    type="button"
                    role="tab"
                    aria-controls="#nav-tab6"
                    aria-selected="false"
                  >
                    Fees
                  </button>
                  <button
                    class="nav-link"
                    id="nav-tab7-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-tab7"
                    type="button"
                    role="tab"
                    aria-controls="#nav-tab7"
                    aria-selected="false"
                  >
                    Other Questions
                  </button>
                </div>
              </div>

              <div class="tab-content" id="nav-tabContent">
                <div
                  class="tab-pane fade active show"
                  id="nav-tab1"
                  role="tabpanel"
                  aria-labelledby="nav-tab1-tab"
                >
                  <div className="row mt-5">
                    <div class="accordion accordion-flush" id="accordionExample">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            How do I create an account on Bithevan Exchange?
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                         <div class="accordion-body">
                        <p>A: To create an account, follow these steps:</p>
                        <ul>
                          <li>Visit <a href="https://www.bithevan.exchange">www.bithevan.exchange</a> and click the <em>Sign Up</em> button.</li>
                          <li>Fill in your email, create a password, and agree to our Terms of Service.</li>
                          <li>Verify your email by clicking the link sent to your inbox.</li>
                          <li>Once verified, log in and complete your profile by setting up two-factor authentication (2FA) for added security.</li>
                        </ul>
                      </div>

                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                           What if I don’t receive the verification email?
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                           <p>A: Check your spam or junk folder. If the email is still not there, try resending it from the registration page or contact support.</p>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThree">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                           I forgot my password, how can I reset it?
                          </button>
                        </h2>
                        <div
                          id="collapseThree"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            <p>A: On the login page, click <strong>Forgot Password</strong>. Enter your registered email, and we will send you a password reset link.</p>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h2 class="accordion-header" id="">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse4"
                            aria-expanded="false"
                            aria-controls="collapse4"
                          >
                            How do I enable two-factor authentication (2FA)?
                          </button>
                        </h2>
                        <div
                          id="collapse4"
                          class="accordion-collapse collapse"
                          aria-labelledby="heading5"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                              <p>A: After logging in:</p>
                              <ul>
                                <li>Go to <em>Account Settings</em>.</li>
                                <li>Select <em>Security</em>.</li>
                                <li>Choose <em>Enable 2FA</em>, then scan the QR code with a 2FA app (Google Authenticator, Authy, etc.).</li>
                                <li>Enter the generated code to complete the process.</li>
                              </ul>
                            </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="nav-tab2"
                  role="tabpanel"
                  aria-labelledby="nav-tab2-tab"
                >
                  <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse21"
                          aria-expanded="false"
                          aria-controls="collapse21"
                        >
                          How does Bithevan Exchange secure my funds and data?
                        </button>
                      </h2>
                      <div
                        id="collapse21"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                       <div class="accordion-body">
                        <p>A: We take security seriously. Bithevan Exchange uses:</p>
                        <ul>
                          <li>Two-factor authentication (2FA) for all accounts.</li>
                          <li>Cold storage for the majority of user funds.</li>
                          <li>SSL encryption to protect data.</li>
                          <li>Routine security audits and monitoring to detect unusual activity.</li>
                        </ul>
                      </div>

                      </div>
                    </div>

                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse23"
                          aria-expanded="false"
                          aria-controls="collapse23"
                        >
                          What can I do to protect my account?
                        </button>
                      </h2>
                      <div
                        id="collapse23"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <p>A: Follow these steps to secure your account:</p>
                          <ul>
                            <li>Enable two-factor authentication (2FA).</li>
                            <li>Use a strong and unique password for your account.</li>
                            <li>Be cautious of phishing attempts—always check the website URL and avoid clicking suspicious links.</li>
                            <li>Never share your password or 2FA codes.</li>
                          </ul>
                        </div>

                      </div>
                    </div>

                   
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="nav-tab3"
                  role="tabpanel"
                  aria-labelledby="nav-tab3-tab"
                >
                  <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse24"
                          aria-expanded="false"
                          aria-controls="collapse24"
                        >
                          How do I deposit funds into my account?
                        </button>
                      </h2>
                      <div
                        id="collapse24"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                       <div class="accordion-body">
                        <p>A: To deposit funds:</p>
                        <ul>
                          <li>Log in to your Bithevan account.</li>
                          <li>Go to the <strong>Wallet</strong> section.</li>
                          <li>Select the cryptocurrency you wish to deposit and click <strong>Deposit</strong>.</li>
                          <li>Copy the deposit address and transfer your funds from your external wallet to this address.</li>
                        </ul>
                      </div>

                      </div>
                    </div>

                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse25"
                          aria-expanded="false"
                          aria-controls="collapse25"
                        >
                          How long does a deposit take?
                        </button>
                      </h2>
                      <div
                        id="collapse25"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <p>A: Deposit times vary depending on the cryptocurrency and network congestion. Most deposits are confirmed within minutes to an hour. You can track the progress on the blockchain.</p>
                        </div>

                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse26"
                          aria-expanded="false"
                          aria-controls="collapse26"
                        >
                          How do I withdraw funds from my account?
                        </button>
                      </h2>
                      <div
                        id="collapse26"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <p>A: To withdraw funds:</p>
                          <ul>
                            <li>Go to the <em>Wallet</em> section.</li>
                            <li>Select the cryptocurrency you wish to withdraw and click <em>Withdraw</em>.</li>
                            <li>Enter the recipient’s address and the amount.</li>
                            <li>Confirm the withdrawal using 2FA.</li>
                          </ul>
                        </div>


                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse27"
                          aria-expanded="false"
                          aria-controls="collapse27"
                        >
                          Are there any fees for withdrawals?
                        </button>
                      </h2>
                      <div
                        id="collapse27"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <p>A: Yes, Bithevan Exchange charges a small fee for withdrawals, which varies depending on the cryptocurrency. You’ll see the fee before confirming your withdrawal.</p>
                        
                        </div>


                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse28"
                          aria-expanded="false"
                          aria-controls="collapse28"
                        >
                          My deposit/withdrawal is taking longer than expected, what should I do?
                        </button>
                      </h2>
                      <div
                        id="collapse28"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <p>A: If your transaction is delayed, it could be due to network congestion or additional security checks. You can track the transaction status via the blockchain explorer or contact our support team if it’s significantly delayed.
                          </p>
                        
                        </div>


                      </div>
                    </div>

                   
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="nav-tab4"
                  role="tabpanel"
                  aria-labelledby="nav-tab4-tab"
                >
                  <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse29"
                          aria-expanded="false"
                          aria-controls="collapse29"
                        >
                          How do I place a trade?
                        </button>
                      </h2>
                      <div
                        id="collapse29"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                       <div class="accordion-body">
                        <p>A: To place a trade:</p>
                        <ul>
                          <li>Go to the <strong>Trading</strong> section.</li>
                          <li>Choose the trading pair you wish to trade (e.g., BTC/USDT).</li>
                          <li>Select whether you want to place a <strong>Market</strong> or <strong>Limit</strong> order.</li>
                          <li>Enter the amount and price (for limit orders) and click <strong>Buy</strong> or <strong>Sell</strong></li>
                          <li>Confirm the trade</li>
                        </ul>
                      </div>

                      </div>
                    </div>

                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse30"
                          aria-expanded="false"
                          aria-controls="collapse30"
                        >
                          What is the difference between a Market and Limit order?
                        </button>
                      </h2>
                      <div
                        id="collapse30"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <ul>
                            <li><em><strong>Market Order</strong></em>: Executes immediately at the best available price.</li>
                            <li><em><strong>Limit Order</strong></em>: Executes only when the price reaches your specified limit.</li>
                          </ul>
                        </div>


                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse31"
                          aria-expanded="false"
                          aria-controls="collapse31"
                        >
                          What are the trading fees?
                        </button>
                      </h2>
                      <div
                        id="collapse31"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <p>A: Bithevan charges a small fee for each trade, which can vary depending on your trading volume and whether you are a market maker or taker. You can check the fee schedule on our <strong>Fees</strong> page.</p>
                   </div>


                      </div>
                    </div>

                   
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="nav-tab5"
                  role="tabpanel"
                  aria-labelledby="nav-tab5-tab"
                >
                  <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse32"
                          aria-expanded="false"
                          aria-controls="collapse32"
                        >
                          Do I need to complete KYC to use Bithevan Exchange?
                        </button>
                      </h2>
                      <div
                        id="collapse32"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                       <div class="accordion-body">
                        <p>A: Yes, Bithevan requires all users to complete KYC verification to comply with regulations and ensure the security of the platform.</p>
 
                      </div>

                      </div>
                    </div>

                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse33"
                          aria-expanded="false"
                          aria-controls="collapse33"
                        >
                          How do I complete KYC verification?
                        </button>
                      </h2>
                      <div
                        id="collapse33"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <ul>
                            <li>Go to <strong>Account Settings</strong> and click on <strong>KYC Verification</strong>.</li>
                            <li> Provide the required documents, such as a government-issued ID and proof of address.</li>
                            <li> Follow the prompts to complete the verification process.</li>
                          </ul>
                        </div>


                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse34"
                          aria-expanded="false"
                          aria-controls="collapse34"
                        >
                          How long does KYC verification take?
                        </button>
                      </h2>
                      <div
                        id="collapse34"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <p> Verification usually takes 24-48 hours. If you experience delays, please contact our support team.</p>
                   </div>


                      </div>
                    </div>

                   
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="nav-tab6"
                  role="tabpanel"
                  aria-labelledby="nav-tab6-tab"
                >
                  <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse35"
                          aria-expanded="false"
                          aria-controls="collapse35"
                        >
                          What are the trading fees on Bithevan Exchange?
                        </button>
                      </h2>
                      <div
                        id="collapse35"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                       <div class="accordion-body">
                        <p>Trading fees depend on whether you are a maker (providing liquidity) or a taker (removing liquidity). Fees typically range between 0.1% and 0.25%, but discounts may apply based on trading volume or if you hold the Bithevan native token (BHE).
                        </p>
 
                      </div>

                      </div>
                    </div>

                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse36"
                          aria-expanded="false"
                          aria-controls="collapse36"
                        >
                          Are there any deposit fees?
                        </button>
                      </h2>
                      <div
                        id="collapse36"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                        <p> Bithevan does not charge any fees for cryptocurrency deposits. However, network fees may apply depending on the blockchain network you are using.</p>
                        </div>


                      </div>
                    </div>

                   
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="nav-tab7"
                  role="tabpanel"
                  aria-labelledby="nav-tab7-tab"
                >
                  <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse37"
                          aria-expanded="false"
                          aria-controls="collapse37"
                        >
                          How can I contact support?
                        </button>
                      </h2>
                      <div
                        id="collapse37"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                       <div class="accordion-body">
                        <p>A: You can reach our support team through the <strong>Support</strong> section on the website or by sending an email to <a href="https://www.support.bithevan.exchange" target="_blank">support@bithevan.exchange</a>. We are available 24/7 to assist you.</p>
 
                      </div>

                      </div>
                    </div>

                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse38"
                          aria-expanded="false"
                          aria-controls="collapse38"
                        >
                          Can I use Bithevan Exchange on mobile?
                        </button>
                      </h2>
                      <div
                        id="collapse38"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                        <p> A: Yes, Bithevan Exchange offers a mobile-friendly website and mobile apps for both Android and iOS. You can download the app from the <strong>App Store</strong> or <strong>Google Play</strong>.</p>
                        </div>


                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse39"
                          aria-expanded="false"
                          aria-controls="collapse39"
                        >
                          Does Bithevan Exchange offer staking or yield services?
                        </button>
                      </h2>
                      <div
                        id="collapse39"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                        <p> A: Yes, Bithevan offers staking services for select cryptocurrencies. You can access these services via the <strong>Earn</strong> section on our platform. Staking rewards and terms vary by asset.</p>
                        </div>


                      </div>
                    </div>

                   
                  </div>
                </div>
              </div>
              <div className="spacer-sm"></div>

              <div className="my-5 text-center">
                <h5>
                  This FAQ will be updated regularly to address new questions and features. For further assistance, please visit our <strong>Help Center</strong> or contact our support team.
                  Thank you for choosing Bithevan Exchange!
                </h5>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
