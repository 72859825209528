import React, { useState, useEffect } from "react";
import { isNum, isOtp } from "./redux/helpers/form-validator.functions";
import QRCode from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import Header from "./HomeComp/Header";
import AssetSidebar from "./components/AssetSidebar";
import FullLoader from "./components/FullLoader";
import { NotificationManager } from "react-notifications";
import $, { data } from "jquery";
import "./sidebar.css";
import { BsInfoCircleFill } from "react-icons/bs";
import { MdOutlineInfo } from "react-icons/md";
import {
  N_FiatHistory,
  N_depositHistory,
  N_withdrawHistory,
} from "./redux/helpers/api_functions_new";

export default function AssetsHistory(props) {
  const { coins } = useSelector((state) => state.coinDBReducer);
  const [deposithistory, setDepositHistory] = useState();
  const [fiatDeposithistory, setFiatDepositHistory] = useState();
  const [withdrawhistory, setWithdrawHistory] = useState();
  const [fiatwithdrawhistory, setFiatWithdrawHistory] = useState();

  const [activeTab, setActiveTab] = useState("deposit_history");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    // You can add additional logic here if needed
    setStartDate("");
    setEndDate("");
    setSymbol("");
  };

  const [symbol, setSymbol] = useState("");
  const [start_date, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const { user } = useSelector((state) => state.AuthReducer);
  useEffect(() => {
    const token = user?.params ? user.params.token : user.token;
    let data = {
      start_date,
      endDate,
      symbol,
    };
    if (activeTab == "deposit_history") {
      N_depositHistory(data, token)
        .then((data) => {
          if (data.status === 200) {
            setDepositHistory(data?.result);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
    if (activeTab == "withdrawal_history") {
      N_withdrawHistory(data, token)
        .then((data) => {
          if (data.status === 200) {
            setWithdrawHistory(data.result);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }

    // fiat
    if (activeTab == "fiat_deposit_history") {
      const depositData = { ...data, type: "Deposit" };
      N_FiatHistory(depositData, token)
        .then((data) => {
          if (data.status === 200) {
            setFiatDepositHistory(data?.result);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
    if (activeTab == "fiat_withdrawal_history") {
      const WithdrawalData = { ...data, type: "withdrawal" };
      N_FiatHistory(WithdrawalData, token)
        .then((data) => {
          if (data.status === 200) {
            setFiatWithdrawHistory(data?.result);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [activeTab, start_date, endDate, symbol]);

  const coinData =
    coins &&
    Object.values(coins).map((item) => {
      return <option value={item.symbol}>{item.symbol}</option>;
    });


    const [csvData, setCsvData] = useState([]);

  // Function to handle CSV download
  const handleCsvDownload = async () => {
    const token = user?.params ? user.params.token : user.token;
    let apiFunction;
    let data;

    switch (activeTab) {
      case "deposit_history":
        apiFunction = N_depositHistory;
        data = { start_date, endDate, symbol };
        break;
      case "withdrawal_history":
        apiFunction = N_withdrawHistory;
        data = { start_date, endDate, symbol };
        break;
      case "fiat_deposit_history":
      case "fiat_withdrawal_history":
        apiFunction = N_FiatHistory;
        data = { ...data, type: activeTab.includes("deposit") ? "deposit" : "withdrawal" };
        break;
      default:
        return;
    }

    try {
      const response = await apiFunction(data, token);
      if (response.status === 200) {
        let csvData = response?.result.map((item)=>{
            let temp = {
              amount:item.amount,
              blockchain:item.blockchain,
              status:item.status,
              symbol:item.symbol,
              to_address:item.to_address,
              type:item.type
            }
            return temp;
        })
        setCsvData(csvData);
        downloadCsv(csvData);
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  // Function to download CSV file
  const downloadCsv = (data) => {
    const csvContent = "data:text/csv;charset=utf-8," +
      data.map(row => Object.values(row).join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "assets_history.csv");
    document.body.appendChild(link);
    link.click();
  };

  return (
    <>
      <Header />

      <div className="s-layout">
        <div class="s-layout__sidebar">
          <AssetSidebar />
        </div>

        <main className="s-layout__content">
          <div className="marginTop">
            <div className="account_page">
              <div className="">
                <div className="page-content-title">
                  <h3 className="">Assets History</h3>
                </div>
                <div className="">
                  <div className="row">
                    <div className="activity_panel mb-4">
                      <div className="tab_width">
                        <div className="custom_pills">
                          <div className="row">
                            <div
                              className="nav nav-pills"
                              id="nav-tab"
                              role="tablist"
                            >
                              <div
                                className={`nav-item nav-link ${
                                  activeTab === "deposit_history"
                                    ? "active"
                                    : ""
                                } fs-14`}
                                style={{ height: "35px" }}
                                onClick={() =>
                                  handleTabClick("deposit_history")
                                }
                              >
                                Deposit History
                              </div>
                              <div
                                className={`nav-item nav-link ${
                                  activeTab === "withdrawal_history"
                                    ? "active"
                                    : ""
                                } fs-14`}
                                style={{ height: "35px" }}
                                onClick={() =>
                                  handleTabClick("withdrawal_history")
                                }
                              >
                                Withdrawal History
                              </div>
                              <div
                                className={`nav-item nav-link ${
                                  activeTab === "fiat_deposit_history"
                                    ? "active"
                                    : ""
                                } fs-14`}
                                style={{ height: "35px" }}
                                onClick={() =>
                                  handleTabClick("fiat_deposit_history")
                                }
                              >
                                Fiat Deposit History
                              </div>
                              <div
                                className={`nav-item nav-link ${
                                  activeTab === "fiat_withdrawal_history"
                                    ? "active"
                                    : ""
                                } fs-14`}
                                style={{ height: "35px" }}
                                onClick={() =>
                                  handleTabClick("fiat_withdrawal_history")
                                }
                              >
                                Fiat Withdrawal History
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row cstm_form">
                  <div className="col-lg-3 mb-3">
                    <input
                      type="Date"
                      class="form-control"
                      onChange={(e) => {
                        setStartDate(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-lg-3 mb-3">
                    <input
                      type="Date"
                      class="form-control"
                      onChange={(e) => {
                        setEndDate(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-lg-3 mb-3">
                    {" "}
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      onChange={(e) => {
                        setSymbol(e.target.value);
                      }}
                    >
                      <option selected>All Coins</option>
                      {coinData}
                    </select>
                  </div>
                  <div className="col-lg-3 mb-3">
                  <button onClick={handleCsvDownload} className="btn btn-pink rounded-pill ">
                    CSV Download
                  </button>
                  </div>
                </div>

                <div className="tab-content">
                  <div
                    className={`tab-pane fade ${
                      activeTab === "deposit_history" ? "show active" : ""
                    }`}
                    id="deposit_history"
                  >
                    <div className="table_scroll_div">
                      <div className="overflow-auto mt-3">
                        <div className="table_scroll_div">
                          <table className="table global_table">
                            <thead>
                              <tr>
                                <th>Symbol</th>
                                <th>Amount</th>
                                <th>Address</th>
                                <th>Status</th>
                                <th class="text-end">Time</th>
                              </tr>
                            </thead>

                            <tbody>
                              {deposithistory && deposithistory.length > 0 ? (
                                deposithistory.map((item) => {
                                  return (
                                    <tr>
                                      <td>{item?.symbol}</td>
                                      <td class="">{item.amount}</td>
                                      <td class="">
                                        {item.to_address
                                          ? item.to_address
                                          : item.from_address}
                                      </td>
                                      <td class="">
                                        {item.status === true ? "success" : ""}
                                      </td>

                                      <td class="text-end">
                                        {new Date(
                                          item.createdAt
                                        ).toLocaleString()}
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan={6} className="text-center">
                                    <img
                                      src="/img/no-data.png"
                                      className="no-data-found"
                                    />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`tab-pane fade ${
                      activeTab === "withdrawal_history" ? "show active" : ""
                    }`}
                    id="withdrawal_history"
                  >
                    <div className="overflow-auto mt-3">
                      <div className="table_scroll_div">
                        <table className="table global_table">
                          <thead>
                            <tr>
                              <th>Symbol</th>
                              <th>Amount</th>
                              <th>Network</th>
                              <th>Withdrawal Address/Account </th>
                              <th>Status</th>
                              <th class="text-end">Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            {withdrawhistory && withdrawhistory.length > 0 ? (
                              withdrawhistory?.map((item) => {
                                return (
                                  <tr>
                                    <td>{item.symbol}</td>
                                    <td>{item.amount}</td>
                                    <td>{item.network?.toUpperCase()}</td>

                                    <td>
                                      <span className="address_item">
                                        {item.to_address}
                                        <span class="">
                                          <img
                                            src="/img/copy-icon.svg"
                                            alt="copy-address"
                                          />
                                        </span>
                                      </span>
                                    </td>
                                    <td>
                                      {item.status == 1
                                        ? "Completed"
                                        : item.status == -2
                                        ? "Rejected"
                                        : "Pending"}
                                    </td>

                                    <td class="text-end">
                                      {new Date(
                                        item.updatedAt
                                      ).toLocaleString()}
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={6} className="text-center">
                                  <img
                                    src="/img/no-data.png"
                                    className="no-data-found"
                                  />
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  {/* Fiat */}
                  <div
                    className={`tab-pane fade ${
                      activeTab === "fiat_deposit_history" ? "show active" : ""
                    }`}
                    id="fiat_deposit_history"
                  >
                    <div className="overflow-auto mt-3">
                      <div className="table_scroll_div">
                        <table className="table global_table">
                          <thead>
                            <tr>
                              <th>Symbol</th>
                              <th>Amount</th>
                              <th>Deposit Address/Account</th>
                              <th>Status</th>
                              <th class="text-end">Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            {fiatDeposithistory &&
                            fiatDeposithistory.length > 0 ? (
                              fiatDeposithistory.map((item) => {
                                return (
                                  <tr>
                                    <td>{item?.symbol}</td>
                                    <td class="">{item.amount}</td>
                                    <td class="">
                                      {item.to_address
                                        ? item.to_address
                                        : item.from_address}
                                    </td>
                                    <td class="">
                                      {item.status === true ? "success" : ""}
                                    </td>

                                    <td class="">
                                      {new Date(
                                        item.createdAt
                                      ).toLocaleString()}
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={6} className="text-center">
                                  <img
                                    src="/img/no-data.png"
                                    className="no-data-found"
                                  />
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`tab-pane fade ${
                      activeTab === "fiat_withdrawal_history"
                        ? "show active"
                        : ""
                    }`}
                    id="fiat_withdrawal_history"
                  >
                    <div className="overflow-auto mt-3">
                      <div className="table_scroll_div">
                        <table className="table global_table">
                          <thead>
                            <tr>
                              <th>Symbol</th>
                              <th>Amount</th>
                              <th>Fee</th>
                              <th>Transection Id</th>
                              <th>Status</th>
                              <th class="text-end">Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            {fiatwithdrawhistory &&
                            fiatwithdrawhistory.length > 0 ? (
                              fiatwithdrawhistory.map((item) => {
                                return (
                                  <tr>
                                    <td>{item.symbol}</td>
                                    <td>{item.amount}</td>
                                    <td>{item?.transaction_fee}</td>
                                    <td>{item?.transection_no}</td>
                                    <td>
                                      {item.status == 1
                                        ? "Completed"
                                        : item.status == -2
                                        ? "Rejected"
                                        : "Pending"}
                                    </td>

                                    <td class="text-end">
                                      {new Date(
                                        item.updatedAt
                                      ).toLocaleString()}
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={6} className="text-center">
                                  <img
                                    src="/img/no-data.png"
                                    className="no-data-found"
                                  />
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
