import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import CalltoAction from "./CalltoAction";
import FooterNav from "./FooterNav";

export default function TermsConditions(props) {
  useEffect(() => {
    document.title = "BITHAVEN Terms & Conditions";
  }, []);
  return (
    <>
      <Header {...props} />
      <div className="page-content">
        <div class="inner_page_banner">
          <div class="container">
            <div class="row align-items-center py-4">
              <div class="col-12 col-lg-8 mb-3">
                <div class="">
                  <h3 class="page_main_heading">
                     Terms &amp; Conditions                   
                  </h3>
                
                </div>
              </div>
            
            </div>
          </div>
        </div>
        <div className="container py-5">
          <div class="termsPrivacy-body">
            <p>
              <span>
                Please read these Terms and Conditions ("Terms", "Terms and
                Conditions") carefully before using the{" "}
              </span>
              <a href="#">
                <span>www.BITHAVEN.com</span>
              </a>
              <span>
                {" "}
                website or apps. Your access to and use of the Service is
                conditioned on your acceptance of and compliance with these
                Terms. These Terms apply to all visitors, users and others who
                access or use the Service. By accessing or using the Service you
                agree to be bound by these Terms. If you disagree with any part
                of the terms then you may not access the Service.
              </span>
            </p>
            <p>
              <span>
                BY ACCESSING OR USING THE SERVICES, YOU ARE ACCEPTING THIS
                AGREEMENT (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU
                REPRESENT), AND YOU REPRESENT AND WARRANT THAT YOU HAVE THE
                RIGHT, AUTHORITY, AND CAPACITY TO ENTER INTO THIS AGREEMENT (ON
                BEHALF OF YOURSELF OR THE ENTITY THAT YOU REPRESENT).&nbsp; YOU
                MAY NOT ACCESS OR USE THE SERVICES OR ACCEPT THIS AGREEMENT IF
                YOU ARE NOT AT LEAST 18 YEARS OLD. IF YOU DO NOT AGREE WITH ALL
                OF THE PROVISIONS OF THIS AGREEMENT, DO NOT ACCESS AND/OR USE
                THE SITE AND APP.
              </span>
            </p>
            <p>
              <span>
                We may amend this Agreement related to the Services from time to
                time. Amendments will be effective upon our posting of such
                updated Agreement at this location or the amended policies or
                supplemental terms on the applicable Service. Your continued
                access or use of the Services after such posting constitutes
                your consent to be bound by the Agreement, as amended.
              </span>
            </p>
            <p>
              <span>
                YOU ACKNOWLEDGE THAT TRADING IN CRYPTOCURRENCIES INVOLVES A HIGH
                DEGREE OF RISK. CRYPTOCURRENCIES ARE SUBJECT TO CONSTANT AND
                FREQUENT FLUCTUATIONS IN VALUE AND EXCHANGE RATES, AND THE VALUE
                OF YOUR CRYPTOCURRENCY ASSETS MAY INCREASE OR DECREASE AT ANY
                TIME. ACCORDINGLY, YOU MAY SUFFER A COMPLETE LOSS OF THE FUNDS
                HELD IN YOUR ACCOUNT. YOU ACKNOWLEDGE THAT YOU ARE SOLELY
                RESPONSIBLE FOR DETERMINING THE NATURE, SUITABILITY, AND
                APPROPRIATENESS OF THESE TRADING RISKS FOR YOU. YOU ACKNOWLEDGE
                AND AGREE THAT BITHAVEN BEARS NO RESPONSIBILITY OR LIABILITY TO
                YOU OR ANY OTHER PERSON WHATSOEVER FOR ANY LOSSES OR GAINS
                INCURRED IN CONNECTION WITH YOUR USE OF THE SERVICES. YOU ALSO
                ACKNOWLEDGE AND AGREE THAT BITHAVEN DOES NOT GIVE ADVICE OR
                RECOMMENDATIONS REGARDING THE TRADING OF CRYPTOCURRENCIES,
                INCLUDING THE SUITABILITY AND APPROPRIATENESS OF, AND INVESTMENT
                STRATEGIES FOR, CRYPTOCURRENCIES.
              </span>
            </p>
            <p>
              <span>
                YOU ALSO ACKNOWLEDGE AND AGREE THAT BITHAVEN AND YOUR ABILITY TO
                USE THE SERVICES MAY BE DETRIMENTALLY IMPACTED BY REGULATORY
                ACTION OR CHANGES IN REGULATIONS APPLICABLE TO CRYPTOCURRENCY.
                YOU AGREE THAT WE MAY DISCLOSE YOUR PERSONAL AND ACCOUNT
                INFORMATION IF WE BELIEVE THAT IT IS REASONABLY NECESSARY TO
                COMPLY WITH A LAW, REGULATION, LEGAL PROCESS, OR GOVERNMENTAL
                REQUEST.
              </span>
            </p>
            <p>
              <span>
                YOU HEREBY ACKNOWLEDGE AND AGREE THAT BITHAVEN IS NOT A FINANCIAL
                INSTITUTION, BANK, CREDIT UNION, TRUST, HEDGE FUND, BROKER OR
                INVESTMENT OR FINANCIAL ADVISOR, AND IS NOT SUBJECT TO THE SAME
                LAWS, REGULATIONS, DIRECTIVES OR REQUIREMENTS APPLICABLE TO SUCH
                PERSONS. YOU ACKNOWLEDGE AND AGREE THAT NO ORAL OR WRITTEN
                INFORMATION OR ADVICE PROVIDED BY BITHAVEN, ITS OFFICERS,
                DIRECTORS, EMPLOYEES, OR AGENTS, NOR ANY INFORMATION OBTAINED
                THROUGH THE SERVICES, WILL OR SHALL CONSTITUTE INVESTMENT, OR
                FINANCIAL ADVICE. YOU ARE SOLELY AND EXCLUSIVELY RESPONSIBLE FOR
                ALL TRADING DECISIONS MADE BY YOU WHILE USING THE SERVICES.
              </span>
            </p>
            <h2 className="fs-4">
              <strong>User Account</strong>
            </h2>
            <ul className="bullet_style">
              <li>
                <strong>
                  New Account Creation.&nbsp;{" "}
                </strong>
                <span>
                  To use certain features of the Site, you must register for an
                  account (“
                </span>
                <strong>Sign Up</strong>
                <span>
                  ”) and provide certain information about yourself as prompted
                  by the account registration form. You represent and warrant
                  that: (a) all required registration information you submit is
                  truthful and accurate (b) you will maintain the accuracy of
                  such information.&nbsp;
                </span>
              </li>
              <li>
                <strong>
                  User Identity Verification.
                </strong>
                <span>
                  &nbsp; You agree to provide us with the information we request
                  for identity verification and the detection of money
                  laundering, terrorist financing, fraud, or any other financial
                  crime and permit us to keep a record of such information. You
                  will need to complete certain verification procedures before
                  you are permitted to use the Services and your access to the
                  Services may be altered as a result of information collected
                  on an ongoing basis. The information we request may include
                  certain personal information, including, but not limited to,
                  your name, address, telephone number, e-mail address, date of
                  birth, taxpayer identification number, government
                  identification number, and information regarding your bank
                  account (such as the name of the bank, the account type,
                  routing number, and account number). In providing us with this
                  or any other information that may be required, you confirm
                  that the information is accurate and authentic. You agree to
                  update this information and keep it current at all times. You
                  authorize us to make the inquiries, whether directly or
                  through third parties, that we consider appropriate, in our
                  sole discretion, to verify your identity or protect you and/or
                  us against fraud or other financial crimes, and to take action
                  we reasonably deem necessary based on the results of such
                  inquiries.
                </span>
              </li>
              <li>
                <strong>
                  Certain Restrictions.{" "}
                </strong>
                <span>
                  By using the Services, you represent and warrant that: (i)
                  neither you nor any of your directors, officers, employees,
                  agents, affiliates or representatives is an individual or an
                  entity that is, or is owned or controlled by an individual or
                  entity that is (a) currently the subject of any Sanctions, or
                  (b) located, organized or resident in a Designated
                  Jurisdiction (ii) you have complied in all material respects
                  with all applicable laws relating to Sanctions,
                  anti-terrorism, anti-corruption and anti-money laundering and
                  (iii) you have instituted and maintained policies and
                  procedures designed to promote and achieve compliance with
                  such laws. For the purpose of this Agreement, “
                </span>
                <strong>
                  Designated Jurisdiction
                </strong>
                <span>
                  ” means any country or territory to the extent that such
                  country or territory itself is the subject of any Sanction “
                </span>
                <strong>Sanction(s)</strong>
                <span>
                  ” means any international economic sanction administered or
                  enforced by the United States Government (including OFAC), the
                  United Nations Security Council, the European Union or other
                  relevant sanctions authority.
                </span>
              </li>
              <li>
                <strong>
                  Account Responsibilities.
                </strong>
                <span>
                  &nbsp; You are responsible for maintaining the confidentiality
                  of your Account login information and are fully responsible
                  for all activities that occur under your Account. You agree to
                  immediately notify BITHAVEN of any unauthorized use, or
                  suspected unauthorized use of your Account or any other breach
                  of security. BITHAVEN cannot and will not be liable for any loss
                  or damage arising from your failure to comply with the above
                  requirements.
                </span>
              </li>
              <li>
                <strong>Compliance.</strong>
                <span>
                  &nbsp; You are solely responsible for ensuring that your use
                  of the Services complies with all laws, rules and regulations
                  applicable to you and the right to access the Services is
                  automatically revoked where use of the Services is prohibited
                  or to the extent that the offering, sale or provision of the
                  Services conflicts with any law, rule or regulation applicable
                  to you.
                </span>
              </li>
            </ul>
            <p>
              <span>
                <strong>
                  Available Only Where Permitted by Law
                </strong>
                . The Services are only available in jurisdictions where they
                may be legally offered for sale. The Services are not available
                to “U.S. Persons”, as such term is defined under Regulation S
                promulgated under the Securities Act of 1933 or any other
                country where it is banned. By accessing or using the Site, you
                represent and warrant that you are not a U.S. Person or a
                citizen of a country where it is illegal or banned.
              </span>
            </p>
             <h2 className="fs-4">
              <span>
                <strong>
                  Access to the Services
                </strong>
              </span>
            </h2>
            <ul className="bullet_style">
              <li>
                <strong>License. </strong>
                <span>
                  &nbsp;Subject to this Agreement, BITHAVEN grants you a
                  non-transferable, non-exclusive, revocable, limited license to
                  use and access the Services solely for your own personal or
                  internal business purposes, in accordance with the Account
                  type you are currently subscribing to.
                </span>
              </li>
              <li>
                <strong>Modification.</strong>
                <span>
                  &nbsp; BITHAVEN reserves the right, at any time, to modify,
                  suspend, or discontinue the Services (in whole or in part)
                  with or without notice to you. You agree that BITHAVEN will not
                  be liable to you or any third party for any modification,
                  suspension, or discontinuation of the Services or any part
                  thereof.
                </span>
              </li>
              <li>
                <strong>
                  No Support or Maintenance.
                </strong>
                <span>
                  &nbsp; You acknowledge and agree that BITHAVEN will have no
                  obligation to provide you with any support or maintenance in
                  connection with the Services.
                </span>
              </li>
              <li>
                <strong>Ownership.&nbsp; </strong>
                <span>
                  You acknowledge that all the intellectual property rights,
                  including copyrights, patents, trademarks, and trade secrets,
                  in the Services and its content are owned by BITHAVEN or its
                  suppliers. Neither this Agreement (nor your access to the
                  Services) transfers to you or any third party any rights,
                  title or interest in or to such intellectual property rights.
                  BITHAVEN and its suppliers reserve all rights not granted in
                  this Agreement. There are no implied licenses granted under
                  this Agreement.
                </span>
              </li>
            </ul>
            <p>
              <span>
                <strong>
                  Feedback.&nbsp; If you provide BITHAVEN with any feedback or
                  suggestions regarding the Site (“Feedback”), you hereby assign
                  to BITHAVEN all rights in such Feedback and agree that BITHAVEN
                  shall have the right to use and fully exploit such Feedback
                  and related information in any manner it deems appropriate.
                  BITHAVEN will treat any Feedback you provide to BITHAVEN as
                  non-confidential and non-proprietary. You agree that you will
                  not submit to BITHAVEN any information or ideas that you
                  consider to be confidential or proprietary.
                </strong>
              </span>
            </p>
             <h2 className="fs-4">
              <span>
                <strong>
                  Restricted Activities
                </strong>
              </span>
            </h2>
            <p>
              <strong>
                Restricted Activities.&nbsp;{" "}
              </strong>
            </p>
            <ul className="bullet_style">
              <li>
                <span>
                  In connection with your use of Services, you hereby agree that
                  you will not:
                </span>
              </li>
              <li>
                <span>
                  violate (or assist any other party in violating) any
                  applicable law, statute, ordinance, or regulation
                </span>
              </li>
              <li>
                <span>
                  intentionally try to defraud (or assist in the defrauding of)
                  BITHAVEN or any other User
                </span>
              </li>
              <li>
                <span>
                  provide false, inaccurate, or misleading information
                </span>
              </li>
              <li>
                <span>
                  trade taking advantage of any technical glitch, malfunction,
                  failure, delay, default, or security breach
                </span>
              </li>
              <li>
                <span>
                  take any action that interferes with, intercepts, or
                  expropriates any system, data, or information
                </span>
              </li>
              <li>
                <span>
                  partake in any transaction involving the proceeds of illegal
                  activity
                </span>
              </li>
              <li>
                <span>
                  attempt to gain unauthorized access to other BITHAVEN Accounts,
                  the Services, or any related networks or systems
                </span>
              </li>
              <li>
                <span>
                  use the Services on behalf of any third party or otherwise act
                  as an intermediary between BITHAVEN and any third parties
                </span>
              </li>
              <li>
                <span>
                  use the Services to engage in conduct that is detrimental to
                  BITHAVEN or any other User or any other third party
                </span>
              </li>
              <li>
                <span>
                  collect any user information from other Users, including,
                  without limitation, email addresses
                </span>
              </li>
              <li>
                <span>
                  defame, harass, or violate the privacy or intellectual
                  property rights of BITHAVEN or any other User or
                </span>
              </li>
              <li>
                <span>
                  upload, display or transmit any messages, photos, videos or
                  other media that contain illegal goods, pornographic, violent,
                  obscene or copyrighted images or materials for use as an
                  avatar, in connection with a payment or payment request, or
                  otherwise.
                </span>
              </li>
              <li>
                <span>
                  Besides, you agree not to: (i) upload, transmit, or distribute
                  to or through the Services any computer viruses, worms, or any
                  software intended to damage or alter a computer system or data
                  (ii) interfere with, disrupt, or create an undue burden on
                  servers or networks connected to the Services, or violate the
                  regulations, policies or procedures of such networks (iii)
                  attempt to reverse engineer, decompile, disable, interfere
                  with, disassemble, copy, or disrupt the integrity or the
                  performance of the Services, any third-party use of the
                  Services, or any third-party data contained therein (except to
                  the extent such restrictions are prohibited by applicable law
                  or (iv) access the Services to build a competitive product or
                  service or copy any ideas, features, functions, or graphics of
                  the Services.
                </span>
                <strong>Enforcement.&nbsp; </strong>
                <span>
                  We reserve the right (but have no obligation) to investigate
                  and/or take appropriate action against you in our sole
                  discretion if you engage in Prohibited Activities or violate
                  any other provision of this Agreement or otherwise create
                  liability for us or any other person. Such action may include,
                  in our sole and absolute discretion, terminating your Account,
                  reporting you to law enforcement authorities without providing
                  any notice of you about any such report and confiscating any
                  balance remaining in an Account which has been terminated
                </span>
              </li>
            </ul>
             <h2 className="fs-4">
              <span>
                <strong>
                  Third-Party Products and Services
                </strong>
              </span>
            </h2>
            <ul className="bullet_style">
              <li>
                <strong>
                  Third-Party Services.&nbsp;{" "}
                </strong>
                <span>
                  You acknowledge that the Service will enable or assist you to
                  access, interact with, and/or purchase services from several
                  supported platforms and other third parties via third-party
                  websites or applications (collectively, “
                </span>
                <strong>
                  Third-Party Services
                </strong>
                <span>
                  ”). Such Third-Party Services are not under the control of
                  BITHAVEN, BITHAVEN does not review, approve, monitor, endorse,
                  warrant, or make any representations with respect to
                  Third-Party Services and is not responsible for any
                  Third-Party Services. You use all Third-Party Services at your
                  own risk and should apply a suitable level of caution and
                  discretion in doing so. Any use of Third-Party Services is
                  governed solely by the terms and conditions of such
                  Third-Party Services and any contract entered into, or any
                  transaction completed via any Third-Party Services is between
                  you and the relevant third party, and not with BITHAVEN. You
                  shall comply in all respects with all applicable terms of the
                  Third-Party Services that you access or subscribe to in
                  connection with the Services. If at any time any Third-Party
                  Services cease to make their programs available to us on
                  reasonable terms, we may cease to provide such features to you
                  without entitling you to refund, credit, or other
                  compensation.
                </span>
              </li>
              <li>
                <strong>Other Users.&nbsp; </strong>
                <span>
                  We do not guarantee the identity of any User or other party or
                  ensure that a buyer or seller is authorized to complete a
                  transaction or will complete a transaction. Your interactions
                  with other Users are solely between you and such Users. You
                  agree that BITHAVEN will not be responsible for any loss or
                  damage incurred as the result of any such interactions. If
                  there is a dispute between you and any User, we are under no
                  obligation to become involved.
                </span>
              </li>
            </ul>
            <p>
              <span>
                <strong>
                  Release. &nbsp;You hereby release and forever discharge BITHAVEN
                  (and our officers, employees, agents, successors, and assigns)
                  from, and hereby waive and relinquish, every past, present and
                  future dispute, claim, controversy, demand, right, obligation,
                  liability, action and cause of action of every kind and nature
                  (including personal injuries, death, and property damage),
                  that has arisen or arises directly or indirectly out of, or
                  that relates directly or indirectly to, the Services
                  (including any interactions with, or act or omission of, other
                  Users or any Third-Party Products and Services).
                </strong>
              </span>
            </p>
             <h2 className="fs-4">
              <span>
                <strong>
                  The BITHAVEN wallet and Exchange.
                </strong>
              </span>
            </h2>
            <ul className="bullet_style">
              <li>
                <strong>
                  Supported Cryptocurrencies
                </strong>
                <span>
                  . The BITHAVEN wallet allows you to send, receive and store
                  cryptocurrency (together, “
                </span>
                <strong>Wallet Transactions</strong>
                <span>
                  ”). The BITHAVEN wallet is only available with the
                  cryptocurrencies that BITHAVEN, in its sole discretion, decides
                  to support. Information about the Supported Cryptocurrencies
                  can be found on the FAQ page on the BITHAVEN website. The
                  Supported Cryptocurrencies may change from time to time. Under
                  no circumstances should you attempt to carry out a BITHAVEN
                  wallet Transaction with a cryptocurrency other than a
                  Supported Cryptocurrency.
                </span>
              </li>
              <li>
                <strong>
                  Transaction instructions
                </strong>
                <span>
                  . BITHAVEN will process Wallet Transactions according to your
                  instructions. You accept and agree that BITHAVEN does not:
                </span>
              </li>
              <li>
                <span>
                  Guarantee the identity of any user, receiver, or other party
                  to a BITHAVEN Wallet Transaction. You are solely responsible for
                  ensuring all transaction details are correct, and you should
                  carefully verify all transaction information before submitting
                  transaction instructions to BITHAVEN or have any control over,
                  or any liability about, the delivery, quality or any other
                  aspect of any goods or services that you may buy from or sell
                  to any third party. BITHAVEN shall not be responsible for, and
                  will take no action about, ensuring that any buyer or seller
                  you transact with using your BITHAVEN wallet completes the
                  relevant transaction or has the requisite authority to do so.
                </span>
              </li>
              <li>
                <strong>
                  Receiving cryptocurrency
                </strong>
                <span>
                  . You may receive Supported Cryptocurrency into your BITHAVEN
                  wallet by providing the sender with a receive address
                  generated in your BITHAVEN wallet. Your BITHAVEN wallet will only
                  be credited with Supported Cryptocurrency sent to a receive
                  address generated through your BITHAVEN wallet and associated
                  with that Supported Cryptocurrency. For example, your BITHAVEN
                  wallet will be credited with BTC when it is sent to an BTC
                  receive address generated through your BITHAVEN wallet.
                </span>
              </li>
              <li>
                <strong>
                  Sharing User Information
                </strong>
                <span>
                  . In the course of processing a Wallet Transactions, BITHAVEN
                  may be required to share your user information with other
                  contractual third parties, or as required under applicable
                  laws or demanded upon a lawful request by any governmental
                  authority. You hereby irrevocably grant full permission and
                  authority for BITHAVEN to share this information with such
                  contractual third parties, or as required under applicable
                  laws or demanded upon a lawful request by any governmental
                  authority, and release BITHAVEN from any liability, error,
                  mistake, or negligence related thereto.
                </span>
              </li>
            </ul>
            <p>
              <span>
                <strong>
                  Trade Corrections. You acknowledge and agree that BITHAVEN may
                  correct, reverse or cancel, in its sole discretion, any order,
                  trade, transfer, or other transaction or activity with respect
                  to which BITHAVEN has discovered that there was an error or
                  abnormality, whether such error or abnormality was caused by
                  you, BITHAVEN or a third party or due to technical issues,
                  system issues, software failure or otherwise. BITHAVEN provides
                  no guarantee or warranty that any such attempt will be
                  successful and will have no responsibility or liability for
                  such error(s) or any correction attempt(s).
                </strong>
              </span>
            </p>
             <h2 className="fs-4">
              <span>
                <strong>Fees.</strong>
              </span>
            </h2>
            <ul className="bullet_style">
              <li>
                <strong>General</strong>
                <span>
                  . You agree to pay BITHAVEN the fees set forth at
                  https://BITHAVEN.com/fees which may be updated from time to time
                  in our sole discretion. Any such updated fees will apply
                  prospectively to any trades or other transactions that take
                  place following the effective date of such updated fees. You
                  authorize BITHAVEN to remove any amounts from your Account for
                  any applicable fees owed by you under this Agreement.
                </span>
              </li>
              <li>
                <strong>
                  Withdrawal / Send fees.&nbsp;{" "}
                </strong>
                <span>
                  You may be charged a fee to send or withdraw a Supported
                  Cryptocurrency from your BITHAVEN wallet. We currently do not
                  charge fees to deposit or receive Supported Cryptocurrency
                  into your BITHAVEN wallet.
                </span>
              </li>
              <li>
                <strong>Trade fees.&nbsp; </strong>
                <span>
                  BITHAVEN applies a flat fee structure for customers who trade
                  Supported Cryptocurrency using the Services. Please consult
                  the page set forth at https://BITHAVEN.com/fees for further
                  information on applicable fees. BITHAVEN will, at the time of
                  any transaction on the BITHAVEN Exchange, notify you of any fees
                  that will apply to the transaction. By proceeding with any
                  transaction, you accept and agree to the applicable fees. Such
                  fees will also be displayed in your transaction history upon
                  completion of the transaction.
                </span>
              </li>
              <li>
                <strong>Contests.&nbsp; </strong>
                <span>
                  We may from time to time offer you the ability to participate
                  in contests of skill (“
                </span>
                <strong>Contest</strong>
                <span>” or “</span>
                <strong>Contests</strong>
                <span>
                  ”). If you choose to participate in any Contests, you accept
                  and agree to be bound and abide by the applicable Contest
                  terms and conditions which will be published on the BITHAVEN
                  website from time to time and incorporated herein by reference
                  (the “
                </span>
                <strong>Contest Terms</strong>
                <span>
                  ”). BITHAVEN reserves the right to modify the Contest Terms or
                  discontinue any contests at any time for any reason, including
                  but not limited to product availability, pricing issues,
                  reduced demand. Prizes, discounts and special pricing may be
                  changed or substituted at any time without prior notice. Taxes
                  on any prize or award are the sole responsibility of the
                  recipient.
                </span>
              </li>
            </ul>
             <h2 className="fs-4">
              <span>
                <strong>
                  Suspension, Termination, And Cancellation.
                </strong>
              </span>
            </h2>
            <ul className="bullet_style">
              <ul className="bullet_style">
                <li>
                  <strong>General</strong>
                  <span>
                    . This Agreement will continue to apply to you until
                    terminated by either you or BITHAVEN. Any provision of this
                    Agreement which, either by its terms or to give effect to
                    its meaning, must survive, and such other provisions which
                    expressly, or by their nature, are intended to survive
                    termination shall survive the expiration or termination of
                    this Agreement.
                  </span>
                </li>
                <li>
                  <strong>
                    Suspension, Termination
                  </strong>
                  <span>
                    . We may terminate your Account or suspend your access to
                    the Services at any time and with immediate effect for any
                    reason or no reason, in our sole and absolute discretion. We
                    may decline to process any deposit or withdrawal without
                    prior notice and may limit or suspend your use of one or
                    more Services at any time, in our sole discretion. For
                    example, we may, in our sole discretion, delay deposits or
                    withdrawals if we believe the transaction is suspicious, may
                    involve fraud or misconduct, violates applicable laws, or
                    violates the terms of this Agreement. If you have a balance
                    remaining in an Account which has been suspended, we may
                    freeze such balance for so long as the Account is suspended.
                    If the Account is terminated due to fraud, violation of the
                    law, or violation of this Agreement, BITHAVEN may, in its
                    discretion, confiscate any balance remaining in the Account
                    and deliver it to any applicable government, law
                    enforcement, or other authorities where circumstances
                    warrant.
                  </span>
                </li>
              </ul>
            </ul>
            <ul className="bullet_style">
              <li>
                <strong>
                  Indemnification. &nbsp;{" "}
                </strong>
                <span>
                  You agree to indemnify and hold BITHAVEN (and its officers,
                  employees, and agents) harmless, including costs and
                  attorneys’ fees, from any claim or demand made by any third
                  party due to or arising out of (a) your use of the Services,
                  (b) your violation of this Agreement, (c) your violation of
                  applicable laws or regulations, or (d) Third-Party
                  Services.&nbsp; BITHAVEN reserves the right, at your expense, to
                  assume the exclusive defense and control of any matter for
                  which you are required to indemnify us, and you agree to
                  cooperate with our defense of these claims. You agree not to
                  settle any matter without the prior written consent of BITHAVEN.
                  BITHAVEN will use reasonable efforts to notify you of any such
                  claim, action or proceeding upon becoming aware of it.
                </span>
              </li>
              <li>
                <strong>
                  Limitation on Liability
                </strong>
              </li>
            </ul>
            <p>
              <strong>General</strong>
            </p>
            <ul className="bullet_style">
              <li>
                <strong>Dispute Resolution</strong>
                <span>
                  .Please read this Arbitration Agreement carefully. It is part
                  of your contract with BITHAVEN and affects your rights. It
                  contains procedures for MANDATORY BINDING ARBITRATION AND A
                  CLASS ACTION WAIVER.
                </span>
              </li>
              <li>
                <span>
                  Any dispute, controversy or claim arising out of or relating
                  to this contract, or the breach, termination or invalidity
                  thereof, shall be settled by arbitration in accordance with
                  the Indian Arbitration and Conciliation Act 1996. The number
                  of arbitrators shall be one. The place of arbitration shall be
                  Jaipur(Rajasthan), unless the Parties agree otherwise. The
                  language to be used in the arbitral proceedings shall be
                  English.
                </span>
              </li>
              <li>
                <strong>                  
                    Waiver of Class or Consolidated Actions                 
                </strong>
                <span>
                  ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION
                  AGREEMENT MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL
                  BASIS AND NOT ON A CLASS BASIS, AND CLAIMS OF MORE THAN ONE
                  CUSTOMER OR USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR
                  CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR
                  USER.&nbsp;&nbsp;
                </span>
              </li>
              <li>
                <strong>
                  Confidentiality
                </strong>
                <span>
                  . All aspects of the arbitration proceeding, including but not
                  limited to the award of the arbitrator and compliance
                  therewith, shall be strictly confidential. The parties agree
                  to maintain confidentiality unless otherwise required by law.
                  This paragraph shall not prevent a party from submitting to a
                  court of law any information necessary to enforce this
                  Agreement, to enforce an arbitration award, or to seek
                  injunctive or equitable relief.
                </span>
              </li>
              <li>
                <strong>
                  Severability
                </strong>
                <span>
                  If any part or parts of this Arbitration Agreement are found
                  under the law to be invalid or unenforceable by a court of
                  competent jurisdiction, then such specific part or parts shall
                  be of no force and effect and shall be severed and the
                  remainder of the Agreement shall continue in full force and
                  effect.
                </span>
              </li>
              <li>
                <strong>
                 Right to Waive
                </strong>
                <span>
                  &nbsp; Any or all of the rights and limitations set forth in
                  this Arbitration Agreement may be waived by the party against
                  whom the claim is asserted. Such waiver shall not waive or
                  affect any other portion of this Arbitration Agreement.
                </span>
              </li>
              <li>
                <strong>
                 Survival of Agreement
                </strong>
                <span>
                  This Arbitration Agreement will survive the termination of
                  your relationship with BITHAVEN.&nbsp;&nbsp;
                </span>
              </li>
              <li>
                <strong>                 
                    Emergency Equitable Relief                  
                </strong>
                <span>
                  . Notwithstanding the foregoing, either party may seek
                  emergency equitable relief before a court of competent
                  jurisdiction in order to maintain the status quo pending
                  arbitration. A request for interim measures shall not be
                  deemed a waiver of any other rights or obligations under this
                  Arbitration Agreement.
                </span>
              </li>
              <li>
                <strong>                
                    Claims Not Subject to Arbitration.               
                </strong>
                <span>
                  &nbsp;Notwithstanding the foregoing, claims of defamation and
                  infringement or misappropriation of the other party’s patent,
                  copyright, trademark or trade secrets shall not be subject to
                  this Arbitration Agreement.
                </span>
              </li>
              <li>
                <strong>Applicable Law. </strong>
                <span>
                  The laws of the India, excluding its choice of law provisions,
                  will govern these Terms and any dispute that arises between
                  you and BITHAVEN.
                </span>
              </li>
              <li>
                <strong>Force Majeure.</strong>
                <span>
                  {" "}
                  BITHAVEN is not responsible for damages caused by delay or
                  failure to perform undertakings under this Agreement when the
                  delay or failure is due to fires, strikes, floods, power
                  outages or failures, acts of God or the state’s enemies,
                  lawful acts of public authorities, any and all market
                  movements, shifts, or volatility, computer, server, or
                  Internet malfunctions, security breaches or cyberattacks,
                  criminal acts, delays or defaults caused by common carriers,
                  acts or omissions of third parties, or, any other delays,
                  defaults, failures or interruptions that cannot reasonably be
                  foreseen or provided against. In the event of force majeure,
                  BITHAVEN is excused from any and all performance obligations and
                  this Agreement shall be fully and conclusively at an end.
                </span>
              </li>
              <li>
                <strong>
                  Electronic Communications.
                </strong>
                <span>
                  &nbsp; The communications between you and BITHAVEN use
                  electronic means, whether you use the Site or send us emails,
                  or whether BITHAVEN posts notices on the Site or communicates
                  with you via email. For contractual purposes, you (a) consent
                  to receive communications from BITHAVEN in an electronic form,
                  and (b) agree that all terms and conditions, agreements,
                  notices, disclosures, and other communications that BITHAVEN
                  provides to you electronically satisfy any legal requirement
                  that such communications would satisfy if it were be in a
                  hardcopy writing. The foregoing does not affect your
                  non-waivable rights.
                </span>
              </li>
            </ul>
            <p>
              <strong>
                Entire Agreement.
                <span>
                  {" "}
                  This Agreement, together with BITHAVEN’s Privacy Policy,
                  constitute the entire agreement between you and us regarding
                  the use of the Services. Our failure to exercise or enforce
                  any right or provision of this Agreement shall not operate as
                  a waiver of such right or provision. The section titles in
                  this Agreement are for convenience only and have no legal or
                  contractual effect. The word “including” means “including
                  without limitation”. If any provision of this Agreement is,
                  for any reason, held to be invalid or unenforceable, the other
                  provisions of this Agreement will be unimpaired and the
                  invalid or unenforceable provision will be deemed modified so
                  that it is valid and enforceable to the maximum extent
                  permitted by law. Your relationship to BITHAVEN is that of an
                  independent contractor, and neither party is an agent or
                  partner of the other. This Agreement, and your rights and
                  obligations herein, may not be assigned, subcontracted,
                  delegated, or otherwise transferred by you without BITHAVEN’s
                  prior written consent, and any attempted assignment,
                  subcontract, delegation, or transfer in violation of the
                  foregoing will be null and void. BITHAVEN may freely assign this
                  Agreement. The terms and conditions set forth in this
                  Agreement shall be binding upon assignees.
                </span>
              </strong>
            </p>
          </div>
        </div>
      </div>
      <div className="spacer-sm"></div>

      <CalltoAction />
      <FooterNav />
      <Footer />
    </>
  );
}
