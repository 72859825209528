import React from "react";
import Loader from "./components/Loader";
import { useSelector } from "react-redux";
import { round } from "./redux/helpers/Math";
import { PiFolderNotchOpenThin } from "react-icons/pi";
import { formatNumber } from "./redux/helpers/helper_functions";
export default function TradeTab(props) {
  const { coins, trade_history, trade_loading } = useSelector(
    (state) => state.coinDBReducer
  );
  const coin = props?.match?.params?.id?.split("-");
  const coinname = coin[0] + coin[1];
  // let l = trade_history ? [coinname]?.length - 1 : 0;
  let data = Object.values(coins).find((item)=>item.symbol==coin[0].toUpperCase());
  let spread = coin[1]?.toUpperCase()=="BTC"?data.spread_btc:coin[1]?.toUpperCase()=="USDT"?data.spread_usdt:coin[1]?.toUpperCase()=="INR"?data.spread_inr:data.spread_vrx;
  let harr = [];
  function reverseArr(input) {
    let ret = new Array();
    for (var i = input.length - 1; i >= 0; i--) {
      ret.push(input[i]);
    }
    const ret1 = ret.sort((a, b) => b.timestamp - a.timestamp);
    return ret1;
  }
  if (
    trade_history &&
    trade_history[coinname] &&
    trade_history[coinname].length > 0
  )
    harr = reverseArr(trade_history[coinname]);
  // console.log(harr);
  return (
    <div className="">
      {/* <div className="card-header">Trade History</div> */}
      <div className="card-body p-0">
        <div className="trade_height custom_scrollbar">
          <table className="exchange_tbl order-book-table ">
            <thead className="sticky_thead">
              <tr>
                <th>
                  <h6>Price</h6>
                </th>
                <th className="">
                  <h6>Volume</h6>
                </th>

                <th className="text-end">
                  <h6>Total</h6>
                </th>
              </tr>
            </thead>
            <tbody className="sell">
              {trade_loading ? (
                <Loader />
              ) : harr?.length != 0 ? (
                harr ? (
                  harr?.map((d, index, arr) => (
                    <TradeRow
                      isSell={
                        index === 9
                          ? false
                          : parseFloat(d.raw_price) >=
                            parseFloat(harr ? arr[index + 1]?.raw_price : 0)
                            ? false
                            : true
                      }
                      price={(d.raw_price).toFixed(spread)}
                      volume={Number(d.volume).toFixed(spread)}
                      timestamp={d.timestamp}
                      spread={spread}
                      key={index}
                    />
                  ))
                ) : (
                  <td className="text-center py-5 text-muted" colspan="3">
                    <div>
                    <PiFolderNotchOpenThin className="fs-3"/>
                    </div>
                    <div className="fs-12">No Trade History Found !</div>
                  </td>
                )
              ) : (
                <td className="text-center py-5 text-muted" colspan="3">
                  <div>
                  <PiFolderNotchOpenThin className="fs-3" />
                  </div>
                  <div className="fs-12">No Trade History Found !</div>
                </td>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function TradeRow(props) {
  const total = round(props.price) * round(props.volume).toFixed(props.spread);
  return (
    <tr
      className=""
    // style={
    //   props.isSell
    //     ? {
    //         background: "rgba(241, 67, 47, 0.1)",
    //         color: "#f00000",
    //         padding: "7.79px 0px",
    //         fontSize: "12px"
    //       }
    //     : {
    //         background: "rgba(35, 172, 80, 0.1)",
    //         color: "#2bcd76",
    //         padding: "7.79px 0px",
    //         fontSize: "12px"
    //       }
    // }
    >
      <td>
        {/* <i
          className={`fas ${props.isSell ? "fa-arrow-down text-green me-1" : "fa-arrow-up text-red me-1"}`}
        ></i> */}
        <span className={` ${props.isSell ? "text-green" : "text-red"}`}>
          {props.price}
        </span>
      </td>
      <td>
        {formatNumber(props.volume)}
      </td>
      {/* <td className="text-end">{round(props.volume) * round(props.price)}</td> */}
      <td className="text-end">
        {formatNumber(total)}

        {/* {new Date(props.timestamp).getHours() +
          ":" +
          new Date(props.timestamp).getMinutes() +
          ":" +
          new Date(props.timestamp).getSeconds()}
        {moment(props.time).format("LTS")}
        {props.time.raw_price} */}
      </td>
    </tr>
  );
}
