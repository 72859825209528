import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import FooterNav from "./FooterNav";

export default function Privacy(props) {
  useEffect(() => {
    document.title = "BITHAVEN Privacy policy";
  }, []);

  return (
    <>
      <Header {...props} />
      
      <div className="page-content">
      <div class="inner_page_banner">
          <div class="container">
            <div class="row align-items-center py-4">
              <div class="col-12 col-lg-8 mb-3">
                <div class="">
                  <h3 class="page_main_heading">
                    Privacy Policy                
                  </h3>                
                </div>
              </div>            
            </div>
          </div>
        </div>
        <div className="container my-5">
          <div class="col-md-12 col-xl-12">
            <div class="">             
              <div class="">
                <h3 className="fs-4">
                  <strong>1. INTRODUCTION</strong>
                </h3>
                <p>
                  <span>
                    We, <strong>“BITHAVEN”</strong>, own and
                    operate the{" "}
                    <span>
                    </span>{" "}
                    platform. We respect your privacy and know that you care
                    about how your personal information is used and shared, and
                    we take your privacy seriously. This Privacy Policy (
                  </span>
                  <strong>“Privacy Policy” </strong>
                  <span>
                    ) describes the Company's policies and procedures on the
                    collection, use and disclosure of your information pursuant
                    to your use of the Company’s website (available at{" "}
                    <a href="https://bithaven.exchange/">
                      <span>www.bithaven.exchange</span>
                    </a>
                    ) and related mobile and software applications, whether
                    existing now or in future (collectively, the “Online
                    Platforms”). The Company is committed to ensuring that any
                    use or sharing of your information with anyone shall be-
                    only in accordance with the procedures described in this
                    Privacy Policy.
                  </span>
                </p>
                <p>
                  <span>
                    In this policy, we are referring to BITHAVEN on each point.
                    For more information about us and how to contact us, over
                    support{" "}
                  </span>
                  <a href="#">
                    <span>BITHAVEN Support</span>
                  </a>
                  <span>.</span>
                </p>
                <p>
                  <span>
                    We respect your privacy and are committed to protecting it
                    through our compliance with this Policy.
                  </span>
                </p>
                <p>
                  <span>This privacy policy (</span>
                  <strong>Policy</strong>
                  <span>
                    ) applies when we are acting as a data controller for the
                    personal data of our users. This Policy describes how we
                    collect, use and share personal data of consumer users
                    across our websites, including{" "}
                  </span>
                  <a href="#">
                    <span>www.bithaven.exchange</span>
                  </a>
                  <span> (the </span>
                  <strong>Website</strong>
                  <span>), bithavens mobile and desktop application (the </span>
                  <strong>App</strong>
                  <span>
                    ) and services offered to users (collectively with the
                    Website and the App, the{" "}
                  </span>
                  <strong>Services</strong>
                  <span>
                    ), and from our partners and other third parties. When using
                    any of our Services you consent to the collection, transfer,
                    storage, disclosure, and use of your data as described in
                    this Policy. This Policy does not apply to anonymised data,
                    as it cannot be used to identify you.
                  </span>
                </p>
                <p>
                  <span>
                    Please read this Policy carefully to understand our policies
                    and practices regarding your data and how we will treat it.
                    By accessing or using the Services, you agree to this
                    Policy. Our Services also incorporate privacy controls which
                    affect how we will process your data. Please refer to
                    Section 5 for a list of rights about your data and how to
                    exercise them.
                  </span>
                </p>
                <p>
                  <span>
                    This Policy may change from time to time. Your continued use
                    of the Services after we make changes is deemed to be
                    acceptance of those changes, so please check the Policy
                    periodically for updates.
                  </span>
                </p>
                <h3 className="fs-4">
                  <strong>
                    2. COMPLETE USER DATA WE COLLECT AND HOW WE COLLECT IT
                  </strong>
                </h3>
                <p>
                  <span>
                    There are three general categories of personal data we
                    collect.
                  </span>
                </p>
                <h4 className="fs-5">
                  <strong>2.1 INFORMATION YOU GIVE TO US.</strong>
                </h4>
                <p>
                  <span>
                    (a)We collect your account data, which may include personal
                    identification information such as your name, date of birth,
                    age, nationality, gender, signature, utility bills,
                    photographs, phone number, home address, and/or email
                    address, and formal identification information such as Tax
                    ID number, passport number, drivers license details,
                    national identity card details, photograph identification
                    cards, and/or visa information (collectively,
                  </span>
                  <strong>Account Data</strong>
                  <span>
                    ). The Account Data may be processed for the purposes of
                    providing to you our Services, satisfying anti-money
                    laundering and know-your-customer obligations, and ensuring
                    the security of the Services, maintaining back-ups of our
                    databases and communicating with you. This information is
                    necessary for the adequate performance of the contract
                    between you and us and to allow us to comply with our legal
                    obligations. Without it, we may not be able to provide you
                    with all the requested Services.
                  </span>
                </p>
                <p>
                  <span>
                    (b)We process financial information such as bank account
                    information when you order Services in order to facilitate
                    the processing of payments (
                  </span>
                  <strong>Payment Information</strong>
                  <span>
                    ). The legal basis for this processing is the performance of
                    a contract between you and us and/or taking steps, at your
                    request, to enter into such a contract and our legitimate
                    interests, namely our interest in the proper administration
                    of our website and business.
                  </span>
                </p>
                <p>
                  <span>
                    (c)We may process information contained in or relating to
                    any communication that you send to us (
                  </span>
                  <strong>Correspondence Data</strong>
                  <span>
                    ). The Correspondence Data may include the communication
                    content and metadata associated with the communication. The
                    correspondence data may be processed for the purposes of
                    communicating with you and record-keeping. The legal basis
                    for this processing is our legitimate interests, namely the
                    proper administration of our website and business and
                    communications with users.
                  </span>
                </p>
                <p>
                  <span>
                    (d)We may process information included in your personal
                    profile, which may include your location, time zone and
                    website (
                  </span>
                  <strong>Profile Data</strong>
                  <span>
                    ). The Profile Data may be processed for the purposes of
                    providing you a better user experience when using the
                    Services. The legal basis for this processing is your
                    consent.
                  </span>
                </p>
                <h4 className="fs-5">
                  <strong>
                    2.2 INFORMATION WE AUTOMATICALLY COLLECT FROM YOUR USE OF
                    THE SERVICES.{" "}
                  </strong>
                </h4>
                <p>
                  <span>
                    When you use the Services, we may automatically process
                    information about your computer and internet connection
                    (including your IP address, operating system and browser
                    type), your mobile carrier, device information (including
                    device and application IDs), search terms, cookie
                    information, as well as information about the timing,
                    frequency and pattern of your service use, and information
                    about to the transactions you make on our Services, such as
                    the name of the recipient, your name, the amount and type of
                    cryptocurrency and timestamp (
                  </span>
                  <strong>Service Data</strong>
                  <span>
                    ). The Service Data is processed to provide our Services.
                    The legal basis for this processing is the adequate
                    performance of the contract between you and us, to enable us
                    to comply with legal obligations and our legitimate interest
                    in being able to provide and improve the functionalities of
                    the Services.
                  </span>
                </p>
                <h4 className="fs-5">
                  <strong>
                    2.3 INFORMATION WE COLLECT FROM THIRD PARTIES.{" "}
                  </strong>
                </h4>
                <p>
                  <span>
                    From time to time, we may obtain information about you from
                    third party sources as required or permitted by applicable
                    law, such as public databases, credit bureaus, ID
                    verification partners, resellers and channel partners, joint
                    marketing partners, and social media platforms. ID
                    verification partners use a combination of government
                    records and publicly available information about you to
                    verify your identity. Such information includes your name,
                    address, job role, public employment profile, credit
                    history, status on any sanctions lists maintained by public
                    authorities, and other relevant data. We obtain such
                    information to comply with our legal obligations, such as
                    anti-money laundering laws. The legal basis for the
                    processing of such data is compliance with legal
                    obligations.
                  </span>
                </p>
                <h3 className="fs-4">
                  <strong>3. DISCLOSURE OF PERSONAL DATA</strong>
                </h3>
                <p>
                  <span>
                    3.1We may disclose your personal data to any member of our
                    group of companies (this means our subsidiaries, our
                    ultimate holding company and all its subsidiaries) insofar
                    as reasonably necessary for the purposes, and on the legal
                    bases, set out in this policy.
                  </span>
                </p>
                <p>
                  <span>
                    3.2We may disclose Service Data to a variety of third-party
                    service providers as reasonably necessary to improve the
                    functionalities of the Services. For example, we may
                    disclose Service Data to obtain useful analytics, provide
                    in-app support to mobile app users, determine location data
                    and provide search engine functionality to our users.
                  </span>
                </p>
                <p>
                  <span>
                    3.3We may share aggregated data (information about our users
                    that we combine together so that it no longer identifies or
                    references an individual user) and other anonymized
                    information for regulatory compliance, industry and market
                    analysis, demographic profiling, marketing and advertising,
                    and other business purposes.
                  </span>
                </p>
                <p>
                  <span>
                    3.4In addition to the specific disclosures of personal data
                    set out in this Section 3, we may disclose your personal
                    data if we believe that it is reasonably necessary to comply
                    with a law, regulation, legal process, or governmental
                    request or in order to protect your vital interests or the
                    vital interests of another natural person to protect the
                    safety or integrity of the Services, or to explain why we
                    have removed content or accounts from the Services or to
                    address fraud, security, or technical issues or to protect
                    our rights or property or the rights or property of those
                    who use the Services.
                  </span>
                </p>
                <h3 className="fs-4">
                  <strong>
                    4. INTERNATIONAL TRANSFERS OF YOUR PERSONAL DATA
                  </strong>
                </h3>
                <p>
                  <span>
                    In Section 4, we provide information about the circumstances
                    in which your personal data may be transferred to countries
                    outside the European Economic Area (EEA). We and our other
                    group companies have offices in India. To facilitate our
                    operations we may transfer, store, and process your
                    information within any country or with service providers
                    based in Europe, India, Asia Pacific and North America. Laws
                    in these countries may differ from the laws applicable to
                    your Country of Residence. For example, information
                    collected within the EEA may be transferred, stored, and
                    processed outside of the EEA for the purposes described in
                    this Privacy Policy. Where we transfer store and process
                    your personal information outside of the EEA we have ensured
                    that appropriate safeguards are in place to ensure an
                    adequate level of data protection.
                  </span>
                </p>
                <p>
                  <span>
                    Transfers to our affiliated entities, to our service
                    providers and other third parties, will be protected by
                    appropriate safeguards, namely the use of standard data
                    protection clauses adopted or approved by the European
                    Commission or applicable certification schemes.
                  </span>
                </p>
                <h3 className="fs-4">
                  <strong>5. RETAINING AND DELETING PERSONAL DATA</strong>
                </h3>
                <p>
                  <span>
                    5.1 Section 5 sets out our data retention policies and
                    procedure, which are designed to help ensure that we comply
                    with our legal obligations about the retention and deletion
                    of personal data.
                  </span>
                </p>
                <p>
                  <span>
                    5.2 Personal data that we process for any purpose or
                    purposes shall not be kept for longer than is necessary for
                    that purpose or those purposes.
                  </span>
                </p>
                <p>
                  <span>
                    5.3 We generally retain your personal information for as
                    long as is necessary for the performance of the contract
                    between you and us and to comply with our legal obligations.
                    If you no longer want us to use your information to provide
                    the Services to you, you can request that we erase your
                    personal information and close your account.
                  </span>
                </p>
                <p>
                  <span>
                    5.4 Notwithstanding the other provisions of this Section 5,
                    we may retain your personal data where such retention is
                    necessary for compliance with a legal obligation to which we
                    are subject, or in order to protect your vital interests or
                    the vital interests of another natural person.
                  </span>
                </p>
                <h3 className="fs-4">
                  <strong>6. COOKIES</strong>
                </h3>
                <p>
                  <span>6.1 About cookies</span>
                </p>
                <p>
                  <span>
                    (a)A cookie is a file containing an identifier (a string of
                    letters and numbers) that is sent by a web server to a web
                    browser and is stored by the browser. The identifier is then
                    sent back to the server each time the browser requests a
                    page from the server.
                  </span>
                </p>
                <p>
                  <span>
                    (b)Cookies may be either persistent cookies or session
                    cookies: a persistent cookie will be stored by a web browser
                    and will remain valid until its set expiry date unless
                    deleted by the user before the expiry date a session cookie,
                    on the other hand, will expire at the end of the user
                    session, when the web browser is closed.
                  </span>
                </p>
                <p>
                  <span>
                    (c)Cookies do not typically contain any information that
                    personally identifies a user, but personal information that
                    we store about you may be linked to the information stored
                    in and obtained from cookies.
                  </span>
                </p>
                <p>
                  <span>
                    (d)We also use other technologies with similar functionality
                    to cookies, such as web beacons, web storage, and unique
                    advertising identifiers, to collect information about your
                    activity, browser, and device.
                  </span>
                </p>
                <p>
                  <span>
                    6.2 We use these technologies for the following purposes:
                  </span>
                </p>
                <p>
                  <span>(a)to identify you and log you into the Services</span>
                </p>
                <p>
                  <span>
                    (b)to store information about your preferences and to
                    personalise the Services for you
                  </span>
                </p>
                <p>
                  <span>
                    (c)as an element of the security measures used to protect
                    user accounts, including preventing fraudulent use of login
                    credentials, and to protect our website and services
                    generally
                  </span>
                </p>
                <p>
                  <span>
                    (d)to help us display content that will be relevant to you
                  </span>
                </p>
                <p>
                  <span>
                    (e)to help us analyse the use and performance of the
                    Services and
                  </span>
                </p>
                <p>
                  <span>
                    (f)to store your preferences in relation to the use of
                    cookies more generally.
                  </span>
                </p>
                <p>
                  <span>6.3 Managing cookies</span>
                </p>
                <p>
                  <span>
                    (a)Most browsers allow you to refuse to accept cookies and
                    to delete cookies. The methods for doing so vary from
                    browser to browser, and from version to version.
                  </span>
                </p>
                <p>
                  <span>
                    (b)Your mobile device may allow you to control cookies
                    through its settings function. Refer to your device
                    manufacturers instructions for more information.
                  </span>
                </p>
                <p>
                  <span>
                    (c)If you choose to decline cookies, some parts of the
                    Services may not work as intended or may not work at all.
                  </span>
                </p>
                <h3 className="fs-4">
                  <strong>7. DATA SECURITY</strong>
                </h3>
                <p>
                  <span>
                    7.1 We have implemented measures designed to secure your
                    personal data from accidental loss and from unauthorized
                    access, use, alteration and disclosure. The safety and
                    security of your information also depend on you. Where we
                    have given you (or where you have chosen) a password for
                    access to certain parts of the Services, you are responsible
                    for keeping this password confidential. We ask you not to
                    share your password with anyone.
                  </span>
                </p>
                <p>
                  <span>7.2 Steps taken to ensure data security:</span>
                </p>
                <p>
                  <span>
                    (a)All the user information can only be accessed by
                    authorized users
                  </span>
                </p>
                <p>
                  <span>
                    (b)Users need to authenticate themselves with a
                    username-password combination and
                  </span>
                </p>
                <p>
                  <span>(c)All data is hosted on Amazon AWS servers.</span>
                </p>
                <p>
                  <span>
                    7.3 Unfortunately, the transmission of information via
                    public networks such as the internet is not completely
                    secure. Although we do our best to protect your personal
                    data, we cannot guarantee the security of your personal data
                    transmitted through the Services. Any transmission of
                    personal data is at your own risk. We are not responsible
                    for the circumvention of any privacy settings or security
                    measures contained on the Services.
                  </span>
                </p>
                <h3 className="fs-4">
                  <strong>8. CHANGES TO OUR PRIVACY POLICY</strong>
                </h3>
                <p>
                  <span>
                    It is our policy to post any changes we make to our Policy
                    on this page. If we make material changes to how we treat
                    our users' personal data, we will notify you by e-mail to
                    the primary e-mail address specified in your account. The
                    date the Policy was last revised is identified at the top of
                    the page. You are responsible for ensuring we have an
                    up-to-date active and deliverable e-mail address for you,
                    and for periodically visiting our Website and this Policy to
                    check for any changes.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterNav />
      <Footer />
    </>
  );
}
