import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom'

export default function CalltoAction() {
  return (
    <div className="cta_block">
    <div className="container ">
      <div className="row align-items-center">
        <div className="col-lg-9">
          <div className="cta_content">
            <h3>24X7 Support </h3>
            <p>
              Ready to dive into the exciting world of cryptocurrency
              trading?
              <br />
              Join our trusted exchange platform and gain access to a
              diverse range of digital assets.
            </p>
            <p>
              Trade with confidence knowing that your transactions are
              processed swiftly and cost-effectively, <br /> allowing you to
              maximize your profits and minimize expenses.
            </p>
            <div class="col-lg-12">
              <a href="" target="_blank" class="button-20 me-3">
                Contact Us
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-3 cta_img">
          <img src="/img/mockup2.png" className="img-fluid" />
        </div>
      </div>
    </div>
  </div>
  )
}
