import io from "socket.io-client";
const SOCKET_URI = "ws://localhost:5007"
const PROD_SOCKET_URI = "wss://socket.bithaven.exchange"
function createSocketClient(access_token) {
  const socket = io(PROD_SOCKET_URI, {
    auth: {
      token: access_token, //kujgwvfq-a-ghosttown-z-1fhhup0p6
    },
  });
  return socket;
}
export default createSocketClient;
